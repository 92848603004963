import {FC} from 'react';
import styled from 'styled-components';

import {Colors} from '@src/components/theme_base';

interface TileContentProps {
  number?: number;
  label: string;
}

export const TileContent: FC<TileContentProps> = ({number, label}) => {
  return (
    <Container>
      <Number>{number?.toLocaleString() ?? '...'}</Number>
      <Label>{label}</Label>
    </Container>
  );
};
TileContent.displayName = 'TileContent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const Number = styled.div`
  font-size: 40px;
  line-height: 40px;
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: ${Colors.Gray};
  margin-top: 10px;
`;
