import {useMemo} from 'react';

import {OnescaleApi} from '@shared/api/definitions/public_api/www_api';
import {
  DatalakeElementItem,
  DatalakeElementItemId,
  DatalakeElementTypeGroup,
  DataLakeId,
} from '@shared/dynamo_model';
import {DatalakeElementItems} from '@shared/model/datalake';

import {createDataStore} from '@shared-frontend/lib/data_store';
import {notifyError} from '@shared-frontend/lib/notification';

import {apiCall} from '@src/lib/network';

interface DatalakeElementItemsHistory {
  id: DataLakeId;
  snapshots: DatalakeElementItems[];
  currentIndex: number;
  manualTrigger: boolean;
}

const elementsHistoryStore = createDataStore<DatalakeElementItemsHistory>({
  id: '' as DataLakeId,
  snapshots: [],
  currentIndex: 0,
  manualTrigger: false,
});

export const useElementsHistory = elementsHistoryStore.useData;
export const setElementsHistory = elementsHistoryStore.setData;

export function useElements(): DatalakeElementItems {
  const history = useElementsHistory();
  return useMemo(() => history.snapshots[history.currentIndex], [history]) ?? {};
}

export function getElements(): {items: DatalakeElementItems; id: DataLakeId} {
  const {id, currentIndex, snapshots} = elementsHistoryStore.getData();
  return {id, items: snapshots[currentIndex] ?? {}};
}

export function useDatalakeId(): DataLakeId {
  const history = useElementsHistory();
  return useMemo(() => history.id, [history]);
}

const MAX_HISTORY_SIZE = 50;

interface UpdateDatalakeOptions {
  replaceHistory?: boolean;
  throttleUpdates?: boolean;
}

export async function pushElementsSnaphsot(
  elements: DatalakeElementItems,
  options?: UpdateDatalakeOptions
): Promise<void> {
  const {replaceHistory, throttleUpdates} = options ?? {};
  const {id, snapshots, currentIndex} = elementsHistoryStore.getData();

  await persistDatalake(id, elements, throttleUpdates);

  if (replaceHistory) {
    snapshots.splice(currentIndex, 1, elements);
    elementsHistoryStore.setData({
      id,
      snapshots,
      currentIndex,
      manualTrigger: false,
    });
    return;
  }

  const newIndex = Math.min(MAX_HISTORY_SIZE - 1, currentIndex + 1);

  elementsHistoryStore.setData({
    id,
    snapshots: [...snapshots.slice(0, currentIndex + 1).slice(-MAX_HISTORY_SIZE + 1), elements],
    currentIndex: newIndex,
    manualTrigger: false,
  });
}

const DELAY_BEFORE_DATALAKE_PERSISTENCE_MS = 1000;
let persistTimeout: number | undefined;
async function persistDatalake(
  id: DataLakeId,
  items: DatalakeElementItems,
  throttleUpdates?: boolean
): Promise<void> {
  if (persistTimeout !== undefined) {
    clearTimeout(persistTimeout);
  }
  if (throttleUpdates) {
    persistTimeout = window.setTimeout(() => {
      persistTimeout = undefined;
      apiCall(OnescaleApi, '/update-data-lake', {id, items}).catch(notifyError);
    }, DELAY_BEFORE_DATALAKE_PERSISTENCE_MS);
  } else {
    await apiCall(OnescaleApi, '/update-data-lake', {id, items});
  }
}

export async function refreshDatalake(): Promise<void> {
  const [datalake] = await apiCall(OnescaleApi, '/get-data-lakes', {});
  if (!datalake) {
    return;
  }
  const {id, items} = datalake;
  const {selectedItem} = getDataLakeSettings();
  if (selectedItem !== undefined && !(selectedItem in items)) {
    setSelectedElement(undefined);
  }
  setElementsHistory({
    id,
    snapshots: [items],
    currentIndex: 0,
    manualTrigger: false,
  });
}

export function goBackElementsSnapshot(): void {
  const current = elementsHistoryStore.getData();
  elementsHistoryStore.setData({
    ...current,
    currentIndex: Math.max(0, current.currentIndex - 1),
    manualTrigger: true,
  });
}

export function goForwardElementsSnapshot(): void {
  const current = elementsHistoryStore.getData();
  elementsHistoryStore.setData({
    ...current,
    currentIndex: Math.min(current.currentIndex + 1, current.snapshots.length - 1),
    manualTrigger: true,
  });
}

//

const INITIAL_ZOOM = 1;

export interface DataLakeSettings {
  selectedItem?: DatalakeElementItemId;
  scale: number;
  dragEnabled: boolean;
  shown: Record<DatalakeElementTypeGroup, boolean>;
}
const dataLakeSettingsStore = createDataStore<DataLakeSettings>({
  selectedItem: undefined,
  scale: INITIAL_ZOOM,
  dragEnabled: true,
  shown: {
    [DatalakeElementTypeGroup.DataSource]: true,
    [DatalakeElementTypeGroup.Transformation]: true,
    [DatalakeElementTypeGroup.Aggregation]: true,
    [DatalakeElementTypeGroup.Output]: true,
  },
});
export const getDataLakeSettings = dataLakeSettingsStore.getData;
export const setDataLakeSettings = dataLakeSettingsStore.setData;
export const useDataLakeSettings = dataLakeSettingsStore.useData;

export function setScale(newScale: number): void {
  setDataLakeSettings({...getDataLakeSettings(), scale: newScale});
}

export function setSelectedElement(id: DatalakeElementItemId | undefined): void {
  const href =
    window.location.pathname + window.location.search + (id === undefined ? '' : `#${id}`);
  window.history.pushState(undefined, document.title, href);
  setDataLakeSettings({...getDataLakeSettings(), selectedItem: id});
}

export function refreshSelectedElementFromUrl(): void {
  const {hash} = document.location;
  const selectedItem = hash.startsWith('#') ? (hash.slice(1) as DatalakeElementItemId) : undefined;
  setDataLakeSettings({...getDataLakeSettings(), selectedItem});
}

export function useSelectedElement(): DatalakeElementItem | undefined {
  const {selectedItem} = useDataLakeSettings();
  if (selectedItem === undefined) {
    return undefined;
  }
  return getElements().items[selectedItem];
}
