import {useCallback, useEffect, useState} from 'react';

import {
  DatalakeElementItemId,
  DatalakeElementType,
  DatalakeElementTypeGroup,
  DatalakeOutputColumnType,
  IpGeolocTransformationDatalakeElementItem,
  IpGeolocTransformationDatalakeElementPayload,
} from '@shared/dynamo_model';
import {generateLayoutForNonDataSource} from '@shared/frontends/datalake_element_layout';
import {uidUnsafe} from '@shared/lib/rand';

import {Button} from '@shared-frontend/components/core/button';
import {CenteredLine} from '@shared-frontend/components/core/fragments';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {notifyError} from '@shared-frontend/lib/notification';
import {Custom} from '@shared-frontend/lib/react';

import {
  getElements,
  pushElementsSnaphsot,
  useDatalakeId,
} from '@src/components/demo/datalake/demo_store';
import {Form, FormPropsBase} from '@src/components/demo/datalake/toolbox/forms/common/form';
import {
  columnIs,
  TableAndFieldSelector,
  TableFieldChangeHandler,
} from '@src/components/demo/datalake/toolbox/forms/common/table_and_field_selector';
import {useDeleteElementHandler} from '@src/components/demo/datalake/toolbox/forms/use_delete_element_handler';
import {useOnescaleSession} from '@src/lib/stores';

interface IpGeolocTransformationFormProps extends FormPropsBase {
  update?: IpGeolocTransformationDatalakeElementItem;
}

export const IpGeolocTransformationForm: Custom<IpGeolocTransformationFormProps, 'div'> = props => {
  const {update, onDone, ...rest} = props;
  const datalakeId = useDatalakeId();
  const session = useOnescaleSession();
  const [{tableId, tableFieldIndex}, setSource] = useState<{
    tableId?: DatalakeElementItemId;
    tableFieldIndex?: number;
  }>({
    tableId: update?.params.source,
    tableFieldIndex: update?.params.sourceColumn,
  });

  useEffect(() => {
    setSource({
      tableId: update?.params.source,
      tableFieldIndex: update?.params.sourceColumn,
    });
  }, [update]);

  const handleChange = useCallback<TableFieldChangeHandler>(
    (tableId, [tableFieldIndex]) => setSource({tableId, tableFieldIndex}),
    []
  );

  const handleClick = useCallback(() => {
    if (
      update !== undefined ||
      tableId === undefined ||
      tableFieldIndex === undefined ||
      session === undefined
    ) {
      return;
    }
    const {items} = getElements();
    const table = items[tableId];
    if (table === undefined) {
      return;
    }
    const newElementId = uidUnsafe() as DatalakeElementItemId;
    const params: IpGeolocTransformationDatalakeElementPayload = {
      group: DatalakeElementTypeGroup.Transformation,
      source: tableId,
      sourceColumn: tableFieldIndex,
    };
    const ipTransformation: IpGeolocTransformationDatalakeElementItem = {
      elementId: newElementId,
      datalakeId,
      accountId: session.accountId,
      type: DatalakeElementType.IpGeolocTransformation,
      params,
      outputs: {
        columns: [
          ...table.outputs.columns,
          {name: 'country', type: DatalakeOutputColumnType.String},
        ],
      },
      layout: generateLayoutForNonDataSource(params, items),
    };
    pushElementsSnaphsot({...items, [newElementId]: ipTransformation})
      .catch(notifyError)
      .finally(onDone);
  }, [datalakeId, onDone, session, tableFieldIndex, tableId, update]);

  const handleDelete = useDeleteElementHandler(update?.elementId);

  return (
    <Form {...rest}>
      <TableAndFieldSelector
        tableId={tableId}
        tableLabel="Table à sélectionner"
        fields={[{label: 'Champ à convertir', index: tableFieldIndex}]}
        filterColumn={columnIs(DatalakeOutputColumnType.String)}
        onChange={handleChange}
      />
      <Spacing height={8} />
      <CenteredLine>
        {update === undefined ? (
          <Button onClick={handleClick}>VALIDER</Button>
        ) : (
          <Button onClickAsync={handleDelete}>DELETE</Button>
        )}
      </CenteredLine>
    </Form>
  );
};
IpGeolocTransformationForm.displayName = 'IpGeolocTransformationForm';
