import {
  cloneElement,
  JSX,
  MouseEventHandler,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';

import {randomStringUnsafe} from '@shared/lib/rand';

import {CustomWithStyle} from '@shared-frontend/lib/react';

import {getDataLakeSettings, useDataLakeSettings} from '@src/components/demo/datalake/demo_store';

interface DraggableProps {
  itemId: string;
  initialX: number;
  initialY: number;
  reset: boolean;
  onPosChange: (x: number, y: number, itemId: string, initial: boolean) => void;
  onItemClick: (itemId: string) => void;
  children: JSX.Element;
}

export const Draggable: CustomWithStyle<DraggableProps, 'div'> = props => {
  const {
    itemId,
    initialX: x,
    initialY: y,
    reset,
    onPosChange,
    onClick,
    onItemClick,
    style,
    children,
    ...rest
  } = props;
  const dataLakeSettings = useDataLakeSettings();

  // Maintain a ref to the cloned element manually since we can't directly use one with cloneElement
  const wrapperRef = useRef<HTMLElement | undefined>();
  const id = useRef(randomStringUnsafe(10));
  useLayoutEffect(() => {
    if (wrapperRef.current) {
      return;
    }
    const element = document.getElementById(id.current);
    if (element) {
      wrapperRef.current = element;
    }
  });

  const dragInfo = useRef<
    | {
        dragStartX: number;
        dragStartY: number;
      }
    | undefined
  >(undefined);
  const lastPos = useRef<{x: number; y: number}>({x, y});
  const currentPos = useRef<{x: number; y: number}>({x, y});
  const isDragging = useRef(false);

  const handleMouseDown = useCallback<MouseEventHandler>(
    evt => {
      if (evt.button !== 0 || !dataLakeSettings.dragEnabled) {
        return;
      }
      isDragging.current = false;
      evt.stopPropagation();
      dragInfo.current = {dragStartX: evt.clientX, dragStartY: evt.clientY};
      if (wrapperRef.current) {
        wrapperRef.current.style.cursor = 'grabbing';
      }
    },
    [dataLakeSettings.dragEnabled]
  );

  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    evt => {
      evt.stopPropagation();
      evt.preventDefault();
      if (!isDragging.current) {
        onClick?.(evt);
        onItemClick(itemId);
      }
    },
    [itemId, onClick, onItemClick]
  );

  useEffect(() => {
    if (!dataLakeSettings.dragEnabled) {
      return;
    }
    const mouseUpHandler = (): void => {
      lastPos.current = currentPos.current;
      dragInfo.current = undefined;
      if (wrapperRef.current) {
        wrapperRef.current.style.cursor = 'grab';
      }
    };
    const mouseMoveHandler = (evt: MouseEvent): void => {
      if (dragInfo.current === undefined || !wrapperRef.current) {
        return;
      }
      const isInitial = !isDragging.current;
      isDragging.current = true;
      const {scale} = getDataLakeSettings();
      const currentX = lastPos.current.x + (evt.clientX - dragInfo.current.dragStartX) / scale;
      const currentY = lastPos.current.y + (evt.clientY - dragInfo.current.dragStartY) / scale;
      currentPos.current = {x: currentX, y: currentY};
      wrapperRef.current.style.transform = `translate(${currentPos.current.x}px, ${currentPos.current.y}px)`;
      onPosChange(currentX, currentY, itemId, isInitial);
    };
    window.addEventListener('mouseup', mouseUpHandler);
    window.addEventListener('mousemove', mouseMoveHandler);
    return () => {
      window.removeEventListener('mouseup', mouseUpHandler);
      window.removeEventListener('mousemove', mouseMoveHandler);
    };
  }, [dataLakeSettings.dragEnabled, itemId, onPosChange]);

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }
    if (reset) {
      dragInfo.current = undefined; //{x, y};
      lastPos.current = {x, y};
      currentPos.current = {x, y};
      wrapperRef.current.style.transform = `translate(${x}px, ${y}px)`;
    }
  }, [reset, x, y]);

  return cloneElement(children, {
    id: id.current,
    onClick: handleClick,
    onMouseDown: handleMouseDown,
    style: {
      position: 'absolute',
      transformOrigin: '0 0',
      transform: `translate(${currentPos.current.x}px, ${currentPos.current.y}px)`,
      ...style,
    },
    ...rest,
  });
};

Draggable.displayName = 'Draggable';
