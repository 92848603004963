import {useCallback} from 'react';

import {OnescaleApi} from '@shared/api/definitions/public_api/www_api';
import {DatalakeElementItemId} from '@shared/dynamo_model';
import {getSources} from '@shared/model/datalake';

import {getElements, refreshDatalake} from '@src/components/demo/datalake/demo_store';
import {elementItemToString} from '@src/components/demo/datalake/toolbox/element_type';
import {apiCall} from '@src/lib/network';

export function useDeleteElementHandler(
  elementId: DatalakeElementItemId | undefined
): () => Promise<void> {
  return useCallback(async () => {
    if (elementId === undefined) {
      return;
    }
    const {id, items} = getElements();
    const dependencies = Object.values(items).filter(el => getSources(el).includes(elementId));
    if (dependencies.length > 0) {
      alert(
        `Supprimez d'abord les éléments dépendants:\n${dependencies
          .map(e => elementItemToString(e))
          .join('\n')}`
      );
      return;
    }

    await apiCall(OnescaleApi, '/delete-data-lake-element', {
      id,
      elementId,
    });
    await refreshDatalake();
  }, [elementId]);
}
