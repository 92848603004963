import {FC} from 'react';
import styled from 'styled-components';

import {Colors} from '@src/components/theme_base';

export const Status: FC = () => {
  return (
    <Container>
      <Dot />
      <StatusName>LIVE</StatusName>
    </Container>
  );
};

Status.displayName = 'Status';

const Container = styled.div`
  position: absolute;
  left: 20px;
  top: 10px;
  display: flex;
  flex-direction: row;
  font-weight: 500;
  z-index: 1;
`;

const Dot = styled.div`
  background-color: ${Colors.SuccessNormal};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: auto;
`;

const StatusName = styled.div`
  color: ${Colors.SuccessNormal};
  margin-left: 5px;
  font-size: 12px;
  line-height: 18px;
`;
