import {FC} from 'react';
import styled from 'styled-components';

import {Image} from '@shared-frontend/components/core/image';

import {HomePageColors} from '@src/components/theme_base';

interface HomeOurServiceCardProps {
  title: string;
  content: string;
  icon: ImageSrcData;
}

export const HomeOurServiceCard: FC<HomeOurServiceCardProps> = props => {
  const {icon, title, content} = props;
  return (
    <Wrapper>
      <Image srcAndSizes={{srcData: icon, width: 100}} width={100} alt={title} lazyLoading />
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Wrapper>
  );
};

HomeOurServiceCard.displayName = 'HomeOurServiceCard';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  width: 475px;
  padding: 32px;
  background-color: ${HomePageColors.DarkBlue};
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -3px;
    border-radius: inherit;
    background: linear-gradient(to right, ${HomePageColors.Purple}, ${HomePageColors.Pink});
  }
`;

const Title = styled.div`
  margin-top: 26px;
  font-weight: 900;
  font-size: 36px;
  color: ${HomePageColors.Blue};
`;

const Content = styled.div`
  margin-top: 10px;
  font-size: 22px;
  color: #ffffff;
  line-height: 36px;
`;
