import {FC} from 'react';
import styled from 'styled-components';

import {SvgIcon, SvgIconName} from '@shared-frontend/components/core/svg_icon';

import {Colors} from '@src/components/theme_base';

interface MetricProps {
  value: string;
  title: string;
  color: string;
  icon: SvgIconName;
}

export const Metric: FC<MetricProps> = ({value, title, color, icon}) => {
  const iconSize = 24;

  return (
    <Container>
      <Icon $color={color} $size={iconSize * 2}>
        <SvgIcon name={icon} color={color} size={iconSize} />
      </Icon>
      <Text>
        <Value>{value}</Value>
        <Title>{title}</Title>
      </Text>
    </Container>
  );
};

Metric.displayName = 'Metric';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 170px;
`;

const paddingToSizeRatio = 4;
const Icon = styled.div<{$color: string; $size: number}>`
  background-color: ${p => p.$color};
  width: ${p => p.$size}px;
  height: ${p => p.$size}px;
  padding: ${p => p.$size / paddingToSizeRatio};
  border-radius: 50%;
  text-align: 'center';
  margin-right: 15px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const Value = styled.div`
  font-size: 24px;
  line-height: 24px;
  color: ${Colors.White};
`;

const Title = styled.div`
  font-size: 16px;
  color: ${Colors.Gray};
`;
