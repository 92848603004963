import {FC} from 'react';
import styled from 'styled-components';

import {NOT_FOUND} from '@shared/api/status_codes';
import {useSsrContext} from '@shared/frontends/use_ssr_context';

import {Main} from '@src/components/styles';
import {Colors} from '@src/components/theme_base';

export const NotFound: FC = () => {
  useSsrContext().setStatusCode(NOT_FOUND);

  return (
    <Wrapper>
      <Main>
        You are lost in another dimension.
        <br />
        Please, <Link href="/">come back home!</Link>
      </Main>
    </Wrapper>
  );
};
NotFound.displayName = 'NotFound';

const Link = styled.a`
  color: ${Colors.White};
  text-decoration: underline;
`;

const Wrapper = styled.div``;
