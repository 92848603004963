import {FC, ReactNode} from 'react';
import {useLocation} from 'react-router';
import styled from 'styled-components';

import {Image} from '@shared-frontend/components/core/image';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {Navigation} from '@src/components/navigation';
import {HomePageColors, Sizes} from '@src/components/theme_base';
import hero_image from '@src/images/hero_image.jpg';
import {HomeFooterSection} from '@src/views/home_footer_section';

interface PageWrapperProps {
  children: ReactNode;
}

export const PageWrapper: FC<PageWrapperProps> = ({children}) => {
  const location = useLocation();
  const isHome = location.pathname === '/';
  const homeImage = isHome ? (
    <HeroImage>
      <Image
        srcAndSizes={{srcData: hero_image, width: Sizes.Content}}
        width={Sizes.Content}
        cover
        lazyLoading
        decorative
        alt=""
      />
    </HeroImage>
  ) : (
    EmptyFragment
  );

  const homeFooter = isHome ? <HomeFooterSection /> : EmptyFragment;

  return (
    <Wrapper>
      {homeImage}
      <Navigation />
      {children}
      {homeFooter}
    </Wrapper>
  );
};
PageWrapper.displayName = 'PageWrapper';

export const Wrapper = styled.div`
  width: ${Sizes.Content}px;
  height: 100%;
`;

const HeroImage = styled.div`
  position: absolute;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      transparent 0%,
      transparent 23.33%,
      ${HomePageColors.DarkBlue} 104.16%
    );
  }
`;
