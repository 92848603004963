import {FC, Fragment} from 'react';
import {Route, Routes} from 'react-router-dom';

import {FrontendName} from '@shared/frontends/frontend_constant';

import {FrontendUserDataOnescaleForm} from '@shared-frontend/components/auth/frontend_user_data_onescale_form';
import {useAuthedRoutes} from '@shared-frontend/components/auth/use_authed_routes';
import {useRoute} from '@shared-frontend/components/core/use_route';
import {useSharedRoutes} from '@shared-frontend/components/shared_routes';

import {GlobalStyle} from '@src/components/global_styles';
import {RoutesTransition} from '@src/components/routes_transition';
import {Demo} from '@src/views/demo';
import {Home} from '@src/views/home';
import {NotFound} from '@src/views/not_found';

export const App: FC = () => {
  const {privateRoute} = useAuthedRoutes();

  const seo = {
    title: 'OneScale | Créez votre propre Data Lake',
    description: 'Créez votre propre Data Lake. Simple. Sécurisé. Performant. Sans engagement.',
  };

  return (
    <Fragment>
      <GlobalStyle />
      <RoutesTransition>
        <Routes>
          {useSharedRoutes<FrontendName.Onescale>({
            seo,
            userDataForm: FrontendUserDataOnescaleForm,
          })}
          <Route path="/" element={useRoute(Home, seo)} />
          <Route path="/demo">
            {privateRoute({
              index: true,
              element: useRoute(Demo, seo),
            })}
            {privateRoute({
              path: ':page',
              element: useRoute(Demo, seo),
            })}
          </Route>
          <Route path="*" element={useRoute(NotFound, seo)} />
        </Routes>
      </RoutesTransition>
    </Fragment>
  );
};
App.displayName = 'App';
