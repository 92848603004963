import styled from 'styled-components';

import {DatalakeElementType, QontoDataSourceDatalakeElementItem} from '@shared/dynamo_model';

import {Custom} from '@shared-frontend/lib/react';

import {useDataLakeSettings} from '@src/components/demo/datalake/demo_store';
import {ElementTile} from '@src/components/demo/datalake/tiles/common/element_tile';
import {SecuredText} from '@src/components/secured_text';

export const QontoDataSourceTile: Custom<
  {
    element: QontoDataSourceDatalakeElementItem;
  },
  'div'
> = props => {
  const {element, ...rest} = props;
  const {elementId, params} = element;
  const selected = useDataLakeSettings().selectedItem === elementId;

  return (
    <ElementTile
      type={DatalakeElementType.QontoDataSource}
      selected={selected}
      body={
        <span>
          <List>
            <Line title={params.iban}>
              IBAN: <SecuredText value={params.iban} />
            </Line>
          </List>
        </span>
      }
      {...rest}
    />
  );
};
QontoDataSourceTile.displayName = 'QontoDataSourceTile';

const Line = styled.li`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const List = styled.ul`
  margin-left: 16px;
`;
