import {Margin} from '@shared-frontend/charts/models';

export const GRID_COLOR = '#ccc';
export const Y_TICKS = 5;
export const MARGIN: Margin = {left: 50, top: 6, bottom: 28, right: 50};
export const TEXT_HEIGHT = 14;
export const FONT = `${TEXT_HEIGHT}px Arial`;
export const LEGEND_HEIGHT = 26;
export const LEGEND_SQUARE_WIDTH = 20;
export const LEGEND_MARGIN = 5;
