import {FC, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {Dashboard} from '@src/components/demo/dashboard/dashboard';
import {DataLake} from '@src/components/demo/dashboard/datalake';
import {Footer} from '@src/components/footer';
import {defaultPage, PAGES} from '@src/components/navigation';
import {Colors} from '@src/components/theme_base';

export const Demo: FC = () => {
  const location = useLocation();
  const currentPage =
    useMemo(
      () => Object.values(PAGES).find(p => p.path === location.pathname),
      [location.pathname]
    ) ?? defaultPage;
  return (
    <Wrapper>
      <Content>
        {currentPage.path === '/demo/home' ? (
          <DataLake />
        ) : currentPage.path === '/demo/graphs' ? (
          <Dashboard />
        ) : (
          ''
        )}
      </Content>
      <Footer left />
    </Wrapper>
  );
};

Demo.displayName = 'Démo';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background-color: ${Colors.DarkBlue};
`;

const Content = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
`;
