import {useState} from 'react';

import {Input, InputProps} from '@shared-frontend/components/core/input';
import {Custom} from '@shared-frontend/lib/react';

export const SecuredInput: Custom<InputProps<string>, 'input'> = props => {
  const [secured, setSecured] = useState(true);
  const {value, onClick, ...rest} = props;

  return (
    <Input
      value={secured ? '••••••••••' : value}
      // eslint-disable-next-line react/jsx-no-bind
      onFocus={() => setSecured(false)}
      // eslint-disable-next-line react/jsx-no-bind
      onBlur={() => setSecured(true)}
      {...rest}
    />
  );
};
SecuredInput.displayName = 'SecuredInput';
