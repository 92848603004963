import {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import {
  DatalakeElementItemId,
  DatalakeElementType,
  DatalakeElementTypeGroup,
  DatalakeOutputColumnType,
  ExtractStringTransformationDatalakeElementItem,
  ExtractStringTransformationDatalakeElementPayload,
} from '@shared/dynamo_model';
import {generateLayoutForNonDataSource} from '@shared/frontends/datalake_element_layout';
import {uidUnsafe} from '@shared/lib/rand';

import {Button} from '@shared-frontend/components/core/button';
import {CenteredLine, Column, Line} from '@shared-frontend/components/core/fragments';
import {Input} from '@shared-frontend/components/core/input';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {notifyError} from '@shared-frontend/lib/notification';
import {Custom} from '@shared-frontend/lib/react';

import {
  getElements,
  pushElementsSnaphsot,
  useDatalakeId,
} from '@src/components/demo/datalake/demo_store';
import {Form, FormPropsBase, Label} from '@src/components/demo/datalake/toolbox/forms/common/form';
import {
  columnIs,
  TableAndFieldSelector,
  TableFieldChangeHandler,
} from '@src/components/demo/datalake/toolbox/forms/common/table_and_field_selector';
import {useDeleteElementHandler} from '@src/components/demo/datalake/toolbox/forms/use_delete_element_handler';
import {Colors} from '@src/components/theme_base';
import {useOnescaleSession} from '@src/lib/stores';

interface ExtractStringTransformationFormProps extends FormPropsBase {
  update?: ExtractStringTransformationDatalakeElementItem;
}

export const ExtractStringTransformationForm: Custom<
  ExtractStringTransformationFormProps,
  'div'
> = props => {
  const {update, onDone, ...rest} = props;
  const datalakeId = useDatalakeId();
  const session = useOnescaleSession();

  const [startsWith, setStartsWith] = useState(update?.params.startsWith ?? '');
  const [endsWith, setEndsWith] = useState(update?.params.endsWith ?? '');
  const [outputName, setOutputName] = useState(update?.params.outputName ?? '');
  const [{tableId, columnIndex}, setSource] = useState<{
    tableId?: DatalakeElementItemId;
    columnIndex?: number;
  }>({
    tableId: update?.params.source,
    columnIndex: update?.params.sourceColumn,
  });

  useEffect(() => {
    setStartsWith(update?.params.startsWith ?? '');
    setEndsWith(update?.params.endsWith ?? '');
    setOutputName(update?.params.outputName ?? '');
    setSource({
      tableId: update?.params.source,
      columnIndex: update?.params.sourceColumn,
    });
  }, [update]);

  const handleChange = useCallback<TableFieldChangeHandler>(
    (tableId, [columnIndex]) => setSource({tableId, columnIndex}),
    []
  );

  const handleClick = useCallback(() => {
    if (
      update !== undefined ||
      (startsWith === '' && endsWith === '') ||
      outputName === '' ||
      tableId === undefined ||
      columnIndex === undefined ||
      session === undefined
    ) {
      return;
    }
    const {items} = getElements();
    const table = items[tableId];
    if (table === undefined) {
      return;
    }
    if (table.outputs.columns.find(o => o.name === outputName) !== undefined) {
      return; // Column with this name already exists
    }
    const newElementId = uidUnsafe() as DatalakeElementItemId;
    const params: ExtractStringTransformationDatalakeElementPayload = {
      group: DatalakeElementTypeGroup.Transformation,
      outputName,
      startsWith,
      endsWith,
      source: tableId,
      sourceColumn: columnIndex,
    };
    const extractStringTransformation: ExtractStringTransformationDatalakeElementItem = {
      elementId: newElementId,
      datalakeId,
      accountId: session.accountId,
      type: DatalakeElementType.ExtractStringTransformation,
      params,
      outputs: {
        columns: [
          ...table.outputs.columns,
          {name: outputName, type: DatalakeOutputColumnType.String},
        ],
      },
      layout: generateLayoutForNonDataSource(params, items),
    };
    pushElementsSnaphsot({...items, [newElementId]: extractStringTransformation})
      .catch(notifyError)
      .finally(onDone);
  }, [update, startsWith, endsWith, outputName, tableId, columnIndex, session, datalakeId, onDone]);

  const handleDelete = useDeleteElementHandler(update?.elementId);

  return (
    <Form {...rest}>
      <TableAndFieldSelector
        tableId={tableId}
        tableLabel="Table à sélectionner"
        fields={[{label: 'Champ à extraire', index: columnIndex}]}
        filterColumn={columnIs(DatalakeOutputColumnType.String)}
        onChange={handleChange}
      />
      <Spacing height={8} />
      <Label>Extraction</Label>
      <Line>
        <Column>
          <InputLabel>Texte avant :</InputLabel>
          <Input theme="demo" width={80} value={startsWith} syncState={setStartsWith} />
        </Column>
        <TextToExtract>Texte à extraire</TextToExtract>
        <Column>
          <InputLabel>Texte après :</InputLabel>
          <Input theme="demo" width={80} value={endsWith} syncState={setEndsWith} />
        </Column>
      </Line>
      <Spacing height={8} />
      <Label>Champs de résultat</Label>
      <Input theme="demo" value={outputName} syncState={setOutputName} />
      <Spacing height={8} />
      <CenteredLine>
        {update === undefined ? (
          <Button onClick={handleClick}>VALIDER</Button>
        ) : (
          <Button onClickAsync={handleDelete}>DELETE</Button>
        )}
      </CenteredLine>
    </Form>
  );
};
ExtractStringTransformationForm.displayName = 'ExtractStringTransformationForm';

const InputLabel = styled.div`
  font-size: 10px;
  font-weight: 300;
  margin-bottom: 2px;
  color: ${Colors.Gray};
`;

const TextToExtract = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${Colors.Gray};
`;
