import {useMemo} from 'react';

import {DatalakeElementType, WebEventsDataSourceDatalakeElementItem} from '@shared/dynamo_model';

import {Custom} from '@shared-frontend/lib/react';

import {useDataLakeSettings} from '@src/components/demo/datalake/demo_store';
import {ElementTile} from '@src/components/demo/datalake/tiles/common/element_tile';
import {TileContent} from '@src/components/demo/datalake/tiles/common/tile_content';
import {useAnalyticsRealtime} from '@src/components/demo/use_analytics_realtime';

export const WebEventsDataSourceTile: Custom<
  {
    element: WebEventsDataSourceDatalakeElementItem;
  },
  'div'
> = props => {
  const {element, ...rest} = props;
  const {elementId, params} = element;
  const selected = useDataLakeSettings().selectedItem === elementId;

  const realtimeData = useAnalyticsRealtime(elementId);
  const activeUsers = useMemo(() => {
    const activeUsers = realtimeData?.activeUsers;
    return activeUsers === undefined ? undefined : activeUsers.desktop + activeUsers.mobile;
  }, [realtimeData]);

  return (
    <ElementTile
      type={DatalakeElementType.WebEventsDataSource}
      subtitle={params.domainName}
      selected={selected}
      body={<TileContent number={activeUsers} label={'Visiteurs sur le site'} />}
      {...rest}
    />
  );
};
WebEventsDataSourceTile.displayName = 'WebEventsDataSourceTile';
