import {FC} from 'react';
import styled from 'styled-components';

import {Image} from '@shared-frontend/components/core/image';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';

import {FilledNavLinkPink} from '@src/components/theme';
import {HomePageColors} from '@src/components/theme_base';
import wavyBackgroundJpg from '@src/images/wavy_background.jpg';

interface HomeAwsSectionProps {}

export const HomeAwsSection: FC<HomeAwsSectionProps> = () => {
  return (
    <Wrapper>
      <WaveImageWrapper>
        <WaveImage
          srcAndSizes={{srcData: wavyBackgroundJpg, width: '100%'}}
          lazyLoading
          width={'100%'}
          alt="AWS Partners"
        />
        <Content>
          <SvgIcon name="AwsPartner" size={400} noColor />
          <ContentRight>
            <Title>
              <div>
                <TitleSpan $color={HomePageColors.Blue}>AWS</TitleSpan>{' '}
                <TitleSpan $color={'#ffffff'}>Partner</TitleSpan>
              </div>
              <div>
                <TitleSpan $color={'#ffffff'}>Services</TitleSpan>
              </div>
            </Title>
            <Description>
              Transform your company today by joining the AWS
              <br />
              (Amazon Web Services) Cloud Journey with OneScale.
            </Description>
            <Buttons>
              <FilledNavLinkPink to="#contact">
                CONTACT US
                <SvgIcon name="ArrowRight" color="#ffffff" width={25} />
              </FilledNavLinkPink>
            </Buttons>
          </ContentRight>
        </Content>
      </WaveImageWrapper>
    </Wrapper>
  );
};

HomeAwsSection.displayName = 'HomeAwsSection';

const Wrapper = styled.div`
  margin-top: 128px;
  display: flex;
`;

const WaveImageWrapper = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(
    90deg,
    ${HomePageColors.Blue} 0%,
    ${HomePageColors.DarkBlue} 50%,
    ${HomePageColors.DarkBlue} 100%
  );
  border-radius: 50px;
  overflow: hidden;
`;

const WaveImage = styled(Image)`
  opacity: 0.3;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 300px;
`;

const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 96px;
  line-height: 96px;
`;
const TitleSpan = styled.span<{$color: string}>`
  color: ${p => p.$color};
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  margin-top: 31px;
  width: max-content;
`;
const Buttons = styled.div`
  display: flex;
  margin-top: 47px;
  gap: 48px;
`;
