import {FC, Fragment} from 'react';

import {DatalakeElementType} from '@shared/dynamo_model';

import {EmptyFragment} from '@shared-frontend/lib/react';

import {CalculationsTransformationForm} from '@src/components/demo/datalake/toolbox/forms/calculations_transformation_form';
import {ExtractStringTransformationForm} from '@src/components/demo/datalake/toolbox/forms/extract_string_transformation_form';
import {FileDropDataSourceForm} from '@src/components/demo/datalake/toolbox/forms/file_drop_data_source_form';
import {GraphOutputForm} from '@src/components/demo/datalake/toolbox/forms/graph_output_form';
import {IpGeolocTransformationForm} from '@src/components/demo/datalake/toolbox/forms/ip_geoloc_transformation_form';
import {LabelTransformationForm} from '@src/components/demo/datalake/toolbox/forms/label_transformation_form';
import {QontoDataSourceForm} from '@src/components/demo/datalake/toolbox/forms/qonto_data_source_form';
import {RestApiOutputForm} from '@src/components/demo/datalake/toolbox/forms/rest_api_output_form';
import {SumsAggregationForm} from '@src/components/demo/datalake/toolbox/forms/sums_aggregation_form';
import {WebEventsDataSourceForm} from '@src/components/demo/datalake/toolbox/forms/web_events_data_source_form';
import {ToolboxTitle} from '@src/components/demo/datalake/toolbox/toolbox_title';

interface NewElementFormProps {
  selectedElement: DatalakeElementType;
  onDone: () => void;
}

export const NewElementForm: FC<NewElementFormProps> = props => {
  const {selectedElement, onDone: handleDone} = props;

  return (
    <Fragment>
      <ToolboxTitle label="Configuration">Configuration</ToolboxTitle>
      {selectedElement === DatalakeElementType.WebEventsDataSource ? (
        <WebEventsDataSourceForm onDone={handleDone} />
      ) : selectedElement === DatalakeElementType.FileDropDataSource ? (
        <FileDropDataSourceForm onDone={handleDone} />
      ) : selectedElement === DatalakeElementType.QontoDataSource ? (
        <QontoDataSourceForm onDone={handleDone} />
      ) : selectedElement === DatalakeElementType.IpGeolocTransformation ? (
        <IpGeolocTransformationForm onDone={handleDone} />
      ) : selectedElement === DatalakeElementType.CalculationsTransformation ? (
        <CalculationsTransformationForm onDone={handleDone} />
      ) : selectedElement === DatalakeElementType.ExtractStringTransformation ? (
        <ExtractStringTransformationForm onDone={handleDone} />
      ) : selectedElement === DatalakeElementType.LabelTransformation ? (
        <LabelTransformationForm onDone={handleDone} />
      ) : selectedElement === DatalakeElementType.SumsAggregation ? (
        <SumsAggregationForm onDone={handleDone} />
      ) : selectedElement === DatalakeElementType.GraphOutput ? (
        <GraphOutputForm onDone={handleDone} />
      ) : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      selectedElement === DatalakeElementType.RestApiOutput ? (
        <RestApiOutputForm onDone={handleDone} />
      ) : (
        EmptyFragment
      )}
    </Fragment>
  );
};
NewElementForm.displayName = 'NewElementForm';
