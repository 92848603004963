import {useMemo} from 'react';
import styled from 'styled-components';

import {AnalyticsWebsiteRealtime} from '@shared/model/dashoard';

import {Chart} from '@shared-frontend/charts/chart';
import {Custom} from '@shared-frontend/lib/react';

import {Legend} from '@src/components/demo/dashboard/legend';
import {Proportion} from '@src/components/demo/dashboard/proportion';
import {useAnalyticsRealtime} from '@src/components/demo/use_analytics_realtime';
import {Colors, Theme} from '@src/components/theme_base';

const REALTIME_DURATION = 30;

interface Point {
  x: number;
  y: number;
}

function minMaxValue(points: Point[]): [number, number] {
  let min = Infinity;
  let max = 0;
  for (const {y} of points) {
    if (y > max) {
      max = y;
    }
    if (y < min) {
      min = y;
    }
  }
  return [min === Infinity ? 0 : min, max];
}

interface AnalyticsWebsiteRealtimeDashboardElementProps {
  element: AnalyticsWebsiteRealtime;
}

export const AnalyticsWebsiteRealtimeDashboardElement: Custom<
  AnalyticsWebsiteRealtimeDashboardElementProps,
  'div'
> = props => {
  const {
    element: {datalakeElementId: websiteId, chartMode},
    ...rest
  } = props;

  const realtimeData = useAnalyticsRealtime(websiteId);

  const realtimeInfo = useMemo(() => {
    if (realtimeData === undefined) {
      return undefined;
    }
    const timestampSeconds = Math.floor(Date.now() / 1000);
    const timestampMinutes = timestampSeconds - (timestampSeconds % 60);
    const pageviewsPerMinute: Point[] = [];
    for (let i = timestampMinutes - 60 * (REALTIME_DURATION - 1); i <= timestampMinutes; i += 60) {
      pageviewsPerMinute.push({x: i, y: realtimeData.pageviewsPerMinute[i] ?? 0});
    }
    return {
      timestampSeconds: realtimeData.timestampSeconds,
      activeUsers: {
        desktop: realtimeData.activeUsers.desktop,
        mobile: realtimeData.activeUsers.mobile,
        total: realtimeData.activeUsers.desktop + realtimeData.activeUsers.mobile,
      },
      pageviewsPerMinute,
      topActivePages: realtimeData.topActivePages,
      topReferers: realtimeData.topReferers,
    };
  }, [realtimeData]);

  const lineLabel = 'PAGES VISITÉES CHAQUE MINUTE';
  const chartStyle =
    chartMode === 'bar'
      ? {
          bars: {
            label: lineLabel,
            topColor: Colors.DarkPink,
            bottomColor: Colors.Blue2,
          },
        }
      : {
          fillArea: {
            label: lineLabel,
            topColor: Colors.DarkBlue3,
            bottomColor: Colors.DarkBlue,
          },
        };

  if (realtimeInfo === undefined) {
    return <LoadingWrapper>Loading...</LoadingWrapper>;
  }

  const {activeUsers, pageviewsPerMinute, timestampSeconds} = realtimeInfo;

  const [min, max] = minMaxValue(pageviewsPerMinute);
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const amplitude = (max - min) * 0.3;

  return (
    <Wrapper {...rest}>
      <Title>VISITES TEMPS RÉEL SUR LE SITE</Title>
      <Row>
        <Child>
          <Proportion
            left={activeUsers.desktop}
            right={activeUsers.mobile}
            title="UTILISATEURS ACTIFS"
          />
          <Legend desktop={activeUsers.desktop} mobile={activeUsers.mobile} />
        </Child>
        <ShiftedLeftChild>
          <Chart
            options={{
              yAxis: {
                left: {
                  lines: {
                    [lineLabel]: {
                      points: [...pageviewsPerMinute.values()].sort((a, b) => a.x - b.x),
                      color: Colors.Blue2,
                    },
                  },
                  tooltip: p => `${p.point?.y ?? 0} pages visitées`,
                  min: Math.max(0, min - amplitude),
                  max: max + amplitude,
                  ...chartStyle,
                },
                right: {
                  lines: {},
                  tooltip: () => '',
                },
              },
              start: new Date(
                (timestampSeconds - (timestampSeconds % 60) - 60 * REALTIME_DURATION) * 1000
              ),
              end: new Date((timestampSeconds - (timestampSeconds % 60)) * 1000),
              utc: false,
            }}
            width={650}
            height={250}
            backgroundColor={Colors.DarkBlue}
            color={`${Colors.Gray}45`}
            font={`12px ${Theme.base.fontFamily}`}
            textColor={`${Colors.Gray}90`}
            drawTickLines={false}
            drawTickGroups={false}
          />
        </ShiftedLeftChild>
      </Row>
    </Wrapper>
  );
};

AnalyticsWebsiteRealtimeDashboardElement.displayName = 'AnalyticsWebsiteRealtimeDashboardElement';

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Child = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

const ShiftedLeftChild = styled(Child)`
  margin-left: -50px;
`;

const Title = styled.div`
  color: ${Colors.White};
  margin-bottom: 50px;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
`;
