import {DatalakeElementItemId} from '@shared/dynamo_model';

export enum DashboardElementType {
  AnalyticsWebsiteRealtime = 'AnalyticsWebsiteRealtime',
  AnalyticsWebsiteEventOrigins = 'AnalyticsWebsiteEventOrigins',
}

interface DatalakeElementItemBase {
  type: DashboardElementType;
}

export interface AnalyticsWebsiteRealtime extends DatalakeElementItemBase {
  type: DashboardElementType.AnalyticsWebsiteRealtime;
  datalakeElementId: DatalakeElementItemId;
  chartMode: 'line' | 'bar';
}

export interface AnalyticsWebsiteEventOrigins {
  type: DashboardElementType.AnalyticsWebsiteEventOrigins;
  datalakeElementId: DatalakeElementItemId;
}

export type DashboardElementItem = AnalyticsWebsiteRealtime | AnalyticsWebsiteEventOrigins;

interface DashboardItemLayout {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface DashboardTileItem {
  layout: DashboardItemLayout;
  data: DashboardElementItem;
}
