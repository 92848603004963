import styled from 'styled-components';

import {DatalakeElementType, GraphOutputDatalakeElementItem} from '@shared/dynamo_model';

import {Custom} from '@shared-frontend/lib/react';

import {useDataLakeSettings} from '@src/components/demo/datalake/demo_store';
import {ElementTile} from '@src/components/demo/datalake/tiles/common/element_tile';

export const GraphOutputTile: Custom<
  {
    element: GraphOutputDatalakeElementItem;
  },
  'div'
> = props => {
  const {element, ...rest} = props;
  const {elementId, params} = element;
  const selected = useDataLakeSettings().selectedItem === elementId;

  return (
    <ElementTile
      type={DatalakeElementType.GraphOutput}
      selected={selected}
      body={
        <span>
          <List>
            <li>{`Table name: ${params.source}`}</li>
            <li>{`X Axis index: ${params.xAxisColumnIndex}`}</li>
            <li>{`Y Axis index: ${params.yAxisColumnIndex}`}</li>
          </List>
        </span>
      }
      {...rest}
    />
  );
};
GraphOutputTile.displayName = 'GraphOutputTile';

const List = styled.ul`
  margin-left: 16px;
`;
