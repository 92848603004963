import {MutableRefObject} from 'react';

import {MouseInfo} from '@shared-frontend/chart_v2/canvas';
import {LinearTimeScale} from '@shared-frontend/chart_v2/scale';
import {TimeAxis, TimeAxisConfig} from '@shared-frontend/chart_v2/time_axis/time_axis';
import {AllTimePeriodGroups} from '@shared-frontend/chart_v2/time_axis/time_period_group';
import {
  FONT,
  GRID_COLOR,
  LEGEND_HEIGHT,
  LEGEND_MARGIN,
  LEGEND_SQUARE_WIDTH,
  MARGIN,
  TEXT_HEIGHT,
  Y_TICKS,
} from '@shared-frontend/charts/constants';
import {
  Axis,
  ChartLine,
  FillArea,
  MinMax,
  NormalizedChartPoint,
} from '@shared-frontend/charts/models';
import {normalizePoint} from '@shared-frontend/charts/utils';
import {Palette} from '@shared-frontend/ui';

export function drawFillArea(
  context: CanvasRenderingContext2D,
  points: NormalizedChartPoint[],
  fillArea: FillArea,
  xMinMax: MinMax,
  yMinMax: MinMax,
  xMinMaxWindow: MinMax,
  yMinMaxWindow: MinMax
): void {
  context.save();
  context.beginPath();
  let startX: number | undefined;
  let endX: number | undefined;
  let maxNormY: number | undefined;
  for (const [i, point] of points.entries()) {
    if (point.yNorm === undefined) {
      continue;
    }
    if (i < points.length - 1) {
      const nextPoint = points[i + 1];
      if (nextPoint?.yNorm !== undefined) {
        if (i === 0) {
          context.moveTo(point.xNorm, point.yNorm);
          if (maxNormY === undefined || point.yNorm > maxNormY) {
            maxNormY = point.yNorm;
          }
        }
        context.lineTo(nextPoint.xNorm, nextPoint.yNorm);
        if (maxNormY === undefined || nextPoint.yNorm > maxNormY) {
          maxNormY = nextPoint.yNorm;
        }
        if (startX === undefined) {
          startX = point.x;
        }
      }
    } else if (endX === undefined) {
      endX = point.x;
    }
  }
  if (startX !== undefined && endX !== undefined) {
    const normalizedEnd = normalizePoint(
      {x: endX, y: yMinMax.min},
      xMinMax,
      yMinMax,
      xMinMaxWindow,
      yMinMaxWindow
    );
    context.lineTo(normalizedEnd.xNorm, normalizedEnd.yNorm ?? 0);
    const normalizedStart = normalizePoint(
      {x: startX, y: yMinMax.min},
      xMinMax,
      yMinMax,
      xMinMaxWindow,
      yMinMaxWindow
    );
    context.lineTo(normalizedStart.xNorm, normalizedStart.yNorm ?? 0);
    const gradient = context.createLinearGradient(0, 0, 0, maxNormY ?? 0);
    gradient.addColorStop(0, fillArea.topColor);
    gradient.addColorStop(1, fillArea.bottomColor);
    context.fillStyle = gradient;
    context.fill();
  }
  context.restore();
}

export function drawLine(
  context: CanvasRenderingContext2D,
  points: NormalizedChartPoint[],
  color: string,
  normalizedMouseInfo: NormalizedChartPoint | undefined
): NormalizedChartPoint | undefined {
  let selectedPoint: NormalizedChartPoint | undefined;
  let sumPixelsBetweenPoints = 0;
  let sumPoints = 0;
  context.save();
  context.beginPath();
  context.lineWidth = 2;
  context.strokeStyle = color;
  for (const [i, point] of points.entries()) {
    if (point.yNorm === undefined) {
      continue;
    }
    // Find closest point
    if (
      normalizedMouseInfo !== undefined &&
      (selectedPoint === undefined ||
        Math.abs(point.x - normalizedMouseInfo.xNorm) <
          Math.abs(selectedPoint.x - normalizedMouseInfo.xNorm))
    ) {
      selectedPoint = point;
    }
    if (i < points.length - 1) {
      const nextPoint = points[i + 1];
      if (nextPoint?.yNorm !== undefined) {
        if (i === 0) {
          context.moveTo(point.xNorm, point.yNorm);
        }
        context.lineTo(nextPoint.xNorm, nextPoint.yNorm);
        sumPixelsBetweenPoints += Math.abs(nextPoint.xNorm - point.xNorm);
        sumPoints += 1;
      }
    }
  }
  // Hidding selected points that are too far from mouse
  if (sumPoints > 0 && selectedPoint !== undefined && normalizedMouseInfo !== undefined) {
    const averagePixels = sumPixelsBetweenPoints / sumPoints;
    if (Math.abs(selectedPoint.xNorm - normalizedMouseInfo.x) > averagePixels / 2) {
      selectedPoint = undefined;
    }
  }
  context.stroke();
  context.restore();
  // Drawing selected point
  if (selectedPoint?.yNorm !== undefined) {
    context.save();
    context.beginPath();
    const radius = 3;
    context.arc(selectedPoint.xNorm, selectedPoint.yNorm, radius, 0, 2 * Math.PI);
    context.fillStyle = color;
    context.fill();
    context.restore();
  }
  return selectedPoint;
}

export function drawLines(
  context: CanvasRenderingContext2D,
  lines: Record<string, NormalizedChartPoint[]>,
  axis: Axis,
  normalizedMouseInfo: NormalizedChartPoint | undefined
): Record<string, NormalizedChartPoint> {
  // Returning list of selected points
  const res: Record<string, NormalizedChartPoint> = {};
  for (const [label, line] of Object.entries(lines)) {
    const axisLine = axis.lines[label];
    if (!axisLine) {
      continue;
    }
    const point = drawLine(context, line, axisLine.color, normalizedMouseInfo);
    if (point !== undefined) {
      res[label] = point;
    }
  }
  return res;
}

export function drawOverlay(
  context: CanvasRenderingContext2D,
  width: number,
  height: number,
  mouseInfo: MouseInfo | undefined,
  color: string | undefined
): void {
  if (mouseInfo === undefined) {
    return;
  }
  context.save();
  context.beginPath();
  context.lineWidth = 1;
  context.strokeStyle = color ?? GRID_COLOR;
  context.setLineDash([2, 2]);
  context.moveTo(MARGIN.left, mouseInfo.y);
  context.lineTo(width - MARGIN.right, mouseInfo.y);
  context.moveTo(mouseInfo.x, MARGIN.top + LEGEND_HEIGHT);
  context.lineTo(mouseInfo.x, height - MARGIN.bottom);
  context.stroke();
  context.restore();
}

export function drawHorizontalGrid(
  context: CanvasRenderingContext2D,
  width: number,
  height: number,
  yMinMaxLeft: MinMax,
  yMinMaxRight: MinMax,
  normalizedMouseInfoLeft: NormalizedChartPoint | undefined,
  normalizedMouseInfoRight: NormalizedChartPoint | undefined,
  shouldDrawRightAxis: boolean,
  font: string | undefined,
  textColor: string | undefined,
  color: string | undefined,
  backgroundColor: string | undefined
): void {
  context.save();
  context.beginPath();
  context.font = font ?? FONT;
  context.lineWidth = 1;
  context.strokeStyle = color ?? GRID_COLOR;

  // Drawing horizontal lines
  const smallMargin = MARGIN.top;
  const verticalSpace = height - MARGIN.top - LEGEND_HEIGHT - MARGIN.bottom;
  const tickSpaceY = verticalSpace / (Y_TICKS - 1);
  const textSpaceX = 4;
  const textHeightFactor = 3;

  for (let i = 0; i < Y_TICKS; i++) {
    context.fillStyle = textColor ?? Palette.BackText.Normal;
    const y = MARGIN.top + LEGEND_HEIGHT + tickSpaceY * i;
    context.moveTo(MARGIN.left - smallMargin, y);
    context.lineTo(width - MARGIN.right + smallMargin, y);
    // Adding left Y labels
    const textLeft = (
      yMinMaxLeft.max -
      (i * (yMinMaxLeft.max - yMinMaxLeft.min)) / (Y_TICKS - 1)
    ).toFixed(1);
    const textLeftWidth = context.measureText(textLeft).width;
    context.fillText(
      textLeft,
      MARGIN.left - smallMargin - textLeftWidth - textSpaceX,
      y + TEXT_HEIGHT / textHeightFactor
    );
    // Adding right Y labels
    if (shouldDrawRightAxis) {
      const textRight = (
        yMinMaxRight.max -
        (i * (yMinMaxRight.max - yMinMaxRight.min)) / (Y_TICKS - 1)
      ).toFixed(1);
      context.fillText(
        textRight,
        width - MARGIN.right + smallMargin + textSpaceX,
        y + TEXT_HEIGHT / textHeightFactor
      );
    }
  }

  // Adding left mouse value
  if (normalizedMouseInfoLeft?.y !== undefined && normalizedMouseInfoLeft.yNorm !== undefined) {
    const text = normalizedMouseInfoLeft.yNorm.toFixed(1);
    const textWidth = context.measureText(text).width;
    const x = MARGIN.left - smallMargin - textWidth - textSpaceX;
    const y = normalizedMouseInfoLeft.y + TEXT_HEIGHT / textHeightFactor;
    context.fillStyle = backgroundColor ?? Palette.Back.Normal;
    const horizontalPadding = 4;
    const verticalPadding = 4;
    context.fillRect(
      x - horizontalPadding,
      y - TEXT_HEIGHT,
      textWidth + horizontalPadding * 2,
      TEXT_HEIGHT + verticalPadding
    );
    context.strokeRect(
      x - horizontalPadding,
      y - TEXT_HEIGHT,
      textWidth + horizontalPadding * 2,
      TEXT_HEIGHT + verticalPadding
    );
    context.fillStyle = textColor ?? Palette.BackText.Normal;
    context.fillText(text, x, y);
  }

  // Adding right mouse value
  if (
    shouldDrawRightAxis &&
    normalizedMouseInfoRight?.y !== undefined &&
    normalizedMouseInfoRight.yNorm !== undefined
  ) {
    const text = normalizedMouseInfoRight.yNorm.toFixed(1);
    const textWidth = context.measureText(text).width;
    const x = width - MARGIN.right + smallMargin + textSpaceX;
    const y = normalizedMouseInfoRight.y + TEXT_HEIGHT / textHeightFactor;
    context.fillStyle = Palette.Back.Normal;
    const horizontalPadding = 4;
    const verticalPadding = 4;
    context.fillRect(
      x - horizontalPadding,
      y - TEXT_HEIGHT,
      textWidth + horizontalPadding * 2,
      TEXT_HEIGHT + verticalPadding
    );
    context.strokeRect(
      x - horizontalPadding,
      y - TEXT_HEIGHT,
      textWidth + horizontalPadding * 2,
      TEXT_HEIGHT + verticalPadding
    );
    context.fillStyle = Palette.BackText.Normal;
    context.fillText(text, x, y);
  }

  context.stroke();
  context.restore();
}

export function drawVerticalGrid(
  context: CanvasRenderingContext2D,
  width: number,
  height: number,
  start: Date | undefined,
  end: Date | undefined,
  offset: number,
  zoom: number,
  timeAxisRef: MutableRefObject<TimeAxis | undefined>,
  utc: boolean,
  font: string | undefined,
  textColor: string | undefined,
  color: string | undefined,
  drawTickLines: boolean | undefined,
  drawTickGroups: boolean | undefined
): void {
  if (start !== undefined && end !== undefined) {
    const scale = new LinearTimeScale();
    const scaledSize = scale.getX(end) - scale.getX(start); // Total size on the scale
    const startOnScale = scale.getX(start) + offset * scaledSize;
    const endOnScale = startOnScale + scaledSize / zoom;
    const startDate = scale.fromX(startOnScale);
    const endDate = scale.fromX(endOnScale);

    let timeAxis: TimeAxis | undefined;
    if (!timeAxisRef.current) {
      const config: TimeAxisConfig = {
        scale,
        timePeriodGroups: AllTimePeriodGroups,
        options: {
          utc,
          group: {text: {}, gridLine: {}, line: {}},
          ticks: {text: {}, gridLine: {}, line: {}},
        },
      };
      if (
        font !== undefined &&
        config.options?.group?.text !== undefined &&
        config.options.ticks?.text !== undefined
      ) {
        config.options.group.text.font = font;
        config.options.ticks.text.font = font;
      }
      if (
        textColor !== undefined &&
        config.options?.group?.text !== undefined &&
        config.options.ticks?.text !== undefined
      ) {
        config.options.group.text.fillStyle = textColor;
        config.options.ticks.text.fillStyle = textColor;
      }
      if (
        color !== undefined &&
        config.options?.group?.gridLine !== undefined &&
        config.options.group.line !== undefined &&
        config.options.ticks?.gridLine !== undefined &&
        config.options.ticks.line !== undefined
      ) {
        config.options.group.gridLine.strokeStyle = color;
        config.options.group.line.strokeStyle = color;
        config.options.ticks.gridLine.strokeStyle = color;
        config.options.ticks.line.strokeStyle = color;
      }
      timeAxis = new TimeAxis(config);
      timeAxisRef.current = timeAxis;
    } else {
      timeAxis = timeAxisRef.current;
    }

    try {
      timeAxis.drawTimeAxis(
        context,
        {
          x: MARGIN.left,
          y: LEGEND_HEIGHT,
          width: width - MARGIN.left - MARGIN.right,
          height: height - LEGEND_HEIGHT,
        },
        startDate,
        endDate,
        drawTickLines,
        drawTickGroups
      );
    } catch {
      // silently fail to render the axis
    }
  }
}

export function drawLegend(
  context: CanvasRenderingContext2D,
  lines: Record<string, ChartLine>,
  font: string | undefined,
  color: string | undefined
): void {
  context.save();
  context.beginPath();
  context.font = font ?? FONT;
  context.lineWidth = 1;

  let currentPosition = 0;
  for (const [label, line] of Object.entries(lines)) {
    // Draw colored rect
    context.fillStyle = line.color;
    context.fillRect(MARGIN.left + currentPosition, 0, LEGEND_SQUARE_WIDTH, LEGEND_HEIGHT / 2);
    currentPosition += LEGEND_SQUARE_WIDTH + LEGEND_MARGIN;
    // Draw text
    const textWidth = context.measureText(label).width;
    context.fillStyle = color ?? Palette.BackText.Normal;
    context.fillText(label, MARGIN.left + currentPosition, TEXT_HEIGHT - 2);
    currentPosition += textWidth + LEGEND_MARGIN;
  }

  context.stroke();
  context.restore();
}

export function drawBars(
  context: CanvasRenderingContext2D,
  points: NormalizedChartPoint[],
  bars: FillArea,
  xMinMax: MinMax,
  yMinMax: MinMax,
  xMinMaxWindow: MinMax,
  yMinMaxWindow: MinMax,
  normalizedMouseInfo: NormalizedChartPoint | undefined
): NormalizedChartPoint | undefined {
  const [firstPoint, secondPoint] = points;
  const barWidth =
    (firstPoint !== undefined && secondPoint !== undefined
      ? secondPoint.xNorm - firstPoint.xNorm
      : xMinMaxWindow.max - xMinMaxWindow.min) / 2;
  const normalizedMinY = normalizePoint(
    {x: yMinMax.max, y: yMinMax.min},
    xMinMax,
    yMinMax,
    xMinMaxWindow,
    yMinMaxWindow
  );
  let selectedPoint: NormalizedChartPoint | undefined;

  context.save();
  for (const point of points) {
    if (point.yNorm === undefined) {
      continue;
    }
    // Find closest point
    if (
      normalizedMouseInfo !== undefined &&
      (selectedPoint === undefined ||
        Math.abs(point.x - normalizedMouseInfo.xNorm) <
          Math.abs(selectedPoint.x - normalizedMouseInfo.xNorm))
    ) {
      selectedPoint = point;
    }
    context.beginPath();
    context.moveTo(point.xNorm - barWidth / 2, normalizedMinY.yNorm ?? 0);
    context.lineTo(point.xNorm - barWidth / 2, point.yNorm);
    context.lineTo(point.xNorm + barWidth / 2, point.yNorm);
    context.lineTo(point.xNorm + barWidth / 2, normalizedMinY.yNorm ?? 0);
    const gradient = context.createLinearGradient(0, 0, 0, normalizedMinY.yNorm ?? 0);
    gradient.addColorStop(0, bars.topColor);
    gradient.addColorStop(1, bars.bottomColor);
    context.fillStyle = gradient;
    context.fill();
  }
  context.restore();
  // Drawing selected point
  if (selectedPoint?.yNorm !== undefined) {
    const growthFactor = 1.3;
    context.save();
    context.beginPath();
    context.moveTo(selectedPoint.xNorm - (growthFactor * barWidth) / 2, normalizedMinY.yNorm ?? 0);
    context.lineTo(selectedPoint.xNorm - (growthFactor * barWidth) / 2, selectedPoint.yNorm);
    context.lineTo(selectedPoint.xNorm + (growthFactor * barWidth) / 2, selectedPoint.yNorm);
    context.lineTo(selectedPoint.xNorm + (growthFactor * barWidth) / 2, normalizedMinY.yNorm ?? 0);
    const gradient = context.createLinearGradient(0, 0, 0, normalizedMinY.yNorm ?? 0);
    gradient.addColorStop(0, bars.topColor);
    gradient.addColorStop(1, bars.bottomColor);
    context.fillStyle = gradient;
    context.fill();
    context.restore();
  }
  return selectedPoint;
}
