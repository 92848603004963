import {FC, Fragment, useCallback, useState} from 'react';
import styled from 'styled-components';

import {DatalakeElementTypeGroup} from '@shared/dynamo_model';
import {iterNumberEnum} from '@shared/lib/type_utils';
import {dataLakeElementTypeGroupToString} from '@shared/model/datalake';

import {Column} from '@shared-frontend/components/core/fragments';

import {setDataLakeSettings, useDataLakeSettings} from '@src/components/demo/datalake/demo_store';
import {
  IconCheckbox,
  IconCheckboxToggleHandler,
} from '@src/components/demo/datalake/toolbox/icon_checkbox';
import {ToolboxTitle} from '@src/components/demo/datalake/toolbox/toolbox_title';

export const DataLakeSettingsForm: FC = () => {
  const [dataLakeOpen, setDataLakeOpen] = useState(true);
  const handleToggle = useCallback(() => setDataLakeOpen(open => !open), []);

  const dataLakeSettings = useDataLakeSettings();

  const handleDragEnabledToggle = useCallback(
    (newValue: boolean) => setDataLakeSettings({...dataLakeSettings, dragEnabled: !newValue}),
    [dataLakeSettings]
  );
  const handleShownToggle = useCallback<IconCheckboxToggleHandler>(
    (newValue, event) => {
      const group = parseFloat(
        event.currentTarget.getAttribute('data-group') ?? ''
      ) as DatalakeElementTypeGroup;
      setDataLakeSettings({
        ...dataLakeSettings,
        shown: {...dataLakeSettings.shown, [group]: newValue},
      });
    },
    [dataLakeSettings]
  );

  return (
    <Fragment>
      <ToolboxTitle
        label="DATA LAKE"
        iconName={dataLakeOpen ? 'ChevronDown' : 'ChevronRight'}
        iconSize={9}
        onClick={handleToggle}
      />
      <Settings hidden={!dataLakeOpen}>
        <IconCheckbox
          icon="Lock"
          label="Verrouiller les éléments"
          selected={!dataLakeSettings.dragEnabled}
          onToggle={handleDragEnabledToggle}
        />
        {iterNumberEnum(DatalakeElementTypeGroup).map(group => (
          <IconCheckbox
            key={group}
            icon="Eye"
            label={`Afficher les ${dataLakeElementTypeGroupToString(group)}`}
            selected={dataLakeSettings.shown[group]}
            data-group={group}
            onToggle={handleShownToggle}
          />
        ))}
      </Settings>
    </Fragment>
  );
};
DataLakeSettingsForm.displayName = 'DataLakeSettingsForm';

const Settings = styled(Column)`
  padding: 8px 0;
`;
