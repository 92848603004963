import {FC, Fragment, JSX} from 'react';
import styled from 'styled-components';

import {hexToRgb, mixColors, rgbToHex} from '@shared-frontend/colors';

import {Colors} from '@src/components/theme_base';

interface ProportionProps {
  left: number;
  right: number;
  title: string;
}

function angleToRad(angle: number): number {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return 2 * Math.PI * ((1 + (1 - angle - 0.25)) % 1);
}

function coordForAnglesAtRadius(
  startAngle: number,
  endAngle: number,
  radius: number,
  fullSize: number,
  width: number,
  height: number
): {x1: number; y1: number; x2: number; y2: number} {
  return {
    x1: fullSize + (width / 2 - fullSize) + radius * Math.cos(startAngle),
    y1: fullSize + (height / 2 - fullSize) - radius * Math.sin(startAngle),
    x2: fullSize + (width / 2 - fullSize) + radius * Math.cos(endAngle),
    y2: fullSize + (height / 2 - fullSize) - radius * Math.sin(endAngle),
  };
}

// Angle is between 0 and 1 starting at "6 oclock" going clockwise.
// (Example: 0 => 6AM, 0.25 => 9AM, 0.5 => 12PM, 0.75 => 3PM, 1 => 6PM)
function renderPart(
  keyPrefix: string,
  startAngle: number,
  startColor: string,
  endAngle: number,
  endColor: string,
  radius: number,
  stroke: number,
  width: number,
  height: number
): JSX.Element {
  const startAngleRad = angleToRad(startAngle);
  const endAngleRad = angleToRad(endAngle);
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const longPathFlag = endAngle - startAngle > 0.5 ? '1' : '0';

  const outterRadius = radius;
  const innerRadius = radius - stroke;

  const outter = coordForAnglesAtRadius(
    startAngleRad,
    endAngleRad,
    outterRadius,
    radius,
    width,
    height
  );
  const inner = coordForAnglesAtRadius(
    startAngleRad,
    endAngleRad,
    innerRadius,
    radius,
    width,
    height
  );

  // Gradient computation
  const middle = coordForAnglesAtRadius(
    startAngleRad,
    endAngleRad,
    radius - stroke / 2,
    radius,
    width,
    height
  );
  const minX = Math.min(outter.x1, outter.x2, inner.x1, inner.x2);
  const maxX = Math.max(outter.x1, outter.x2, inner.x1, inner.x2);
  const minY = Math.min(outter.y1, outter.y2, inner.y1, inner.y2);
  const maxY = Math.max(outter.y1, outter.y2, inner.y1, inner.y2);
  const x1 = (middle.x1 - minX) / (maxX - minX);
  const x2 = (middle.x2 - minX) / (maxX - minX);
  const y1 = (middle.y1 - minY) / (maxY - minY);
  const y2 = (middle.y2 - minY) / (maxY - minY);

  const path = [
    `M ${outter.x1} ${outter.y1}`,
    `A ${outterRadius} ${outterRadius} 0 ${longPathFlag} 1 ${outter.x2} ${outter.y2}`,
    `L ${inner.x2} ${inner.y2}`,
    `A ${innerRadius} ${innerRadius} 0 ${longPathFlag} 0 ${inner.x1} ${inner.y1}`,
    `Z`,
  ].join(' ');
  const id = `start-${startAngle}`;

  return (
    <Fragment key={`${keyPrefix}-${startAngle}`}>
      <linearGradient
        // eslint-disable-next-line react/forbid-dom-props
        id={id}
        {...{x1, x2, y1, y2}}
      >
        <stop offset="0%" stopColor={startColor}></stop>
        <stop offset="100%" stopColor={endColor}></stop>
      </linearGradient>
      <path d={path} fill={`url(#${id})`} />
    </Fragment>
  );
}

const BACK_COLOR = Colors.DarkBlue2;
const HIGHLIGH_COLOR = Colors.DarkPink;

const BACK_COLOR_RGB = hexToRgb(BACK_COLOR);
const HIGHLIGH_COLOR_RGB = hexToRgb(HIGHLIGH_COLOR);

function getColor(ratio: number): string {
  return `#${rgbToHex(mixColors(HIGHLIGH_COLOR_RGB, BACK_COLOR_RGB, ratio))}`;
}

export const Proportion: FC<ProportionProps> = ({left, right, title}) => {
  const total = left + right;
  const radius = 100;
  const stroke = 36;
  const width = radius * 2;
  const height = radius * 2;

  const startAngle = 0.11;
  const maxAngle = 1 - startAngle;
  const endAngle = startAngle + (left / total) * (maxAngle - startAngle);

  const targetAngleIncrement = 0.002;
  const angleRange = endAngle - startAngle;
  const angleIncrement = angleRange / Math.ceil(angleRange / targetAngleIncrement);

  // GRADIENTS
  const EPSILON = 0.0008; // Small value added to the end angle to prevent gap between gradient slices
  const gradient: JSX.Element[] = [];
  for (let angle = startAngle; angle < endAngle; angle += angleIncrement) {
    const start = angle;
    const end = Math.min(angle + angleIncrement, endAngle);
    const color1 = getColor((start - startAngle) / angleRange);
    const color2 = getColor((end - startAngle) / angleRange);
    gradient.push(
      renderPart('gradient', start, color1, end + EPSILON, color2, radius, stroke, width, height)
    );
  }

  // DECORATIVE DOTS
  const decorativeDotsCount = 37;
  const decorativeDotsRadius = 1.75;
  const decorativeDotsColor = Colors.Blue2;
  const decorativeDotsDistanceFromRing = 6;
  const decorativeDots: JSX.Element[] = [];
  for (let dotIndex = 0; dotIndex < decorativeDotsCount; dotIndex++) {
    const dotAlpha = 1 - Math.abs(decorativeDotsCount / 2 - dotIndex) / (decorativeDotsCount / 2);
    const dotAngle = startAngle + (dotIndex * (maxAngle - startAngle)) / (decorativeDotsCount - 1);
    const dotAngleRad = angleToRad(dotAngle);
    const r = radius - stroke - decorativeDotsDistanceFromRing;
    const dotX = width + (width / 2 - width) + r * Math.cos(dotAngleRad);
    const dotY = height + (height / 2 - height) - r * Math.sin(dotAngleRad);
    decorativeDots.push(
      <circle
        key={`${dotX}-${dotY}`}
        cx={dotX}
        cy={dotY}
        r={decorativeDotsRadius}
        fill={decorativeDotsColor}
        opacity={dotAlpha}
      />
    );
  }

  const cursorColor = Colors.Pink;
  const cursorAngleWidth = 0.015;

  const sugarColor = Colors.DarkBlue3;
  const sugarAngleWidth = 0.004;

  return (
    <Container>
      <svg height={height} width={width}>
        {renderPart(
          'back',
          endAngle,
          BACK_COLOR,
          maxAngle,
          BACK_COLOR,
          radius,
          stroke,
          width,
          height
        )}
        {renderPart(
          'sugar-start',
          startAngle - sugarAngleWidth,
          sugarColor,
          startAngle,
          sugarColor,
          radius,
          stroke,
          width,
          height
        )}
        {renderPart(
          'sugar-end',
          maxAngle,
          sugarColor,
          maxAngle + sugarAngleWidth,
          sugarColor,
          radius,
          stroke,
          width,
          height
        )}
        {gradient}
        {decorativeDots}
        {renderPart(
          'cursor',
          endAngle - cursorAngleWidth + EPSILON,
          cursorColor,
          endAngle + EPSILON,
          cursorColor,
          radius,
          stroke,
          width,
          height
        )}
      </svg>
      <Total
        // eslint-disable-next-line react/forbid-component-props
        style={{width: radius * 2, height: radius * 2, lineHeight: `${radius * 2}px`}}
      >
        {total}
      </Total>
      <Title>{title}</Title>
    </Container>
  );
};

Proportion.displayName = 'Proportion';

const Title = styled.div`
  color: ${Colors.Gray};
  text-align: center;
  position: relative;
  font-size: 14px;
`;

const Total = styled.div`
  color: ${Colors.White};
  font-size: 40px;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
`;

const Container = styled.div`
  position: relative;
  margin-top: 20px;
`;
