import {FC, MouseEventHandler, useCallback} from 'react';
import styled from 'styled-components';

import {Colors} from '@src/components/theme_base';

interface PeriodSelectorProps {
  periods: {label: string; period: number}[];
  selected?: number;
  onChange: (newSelected: number) => void;
}

export const PeriodSelector: FC<PeriodSelectorProps> = ({periods, selected, onChange}) => {
  const handleClick = useCallback<MouseEventHandler>(
    evt => {
      const period = parseFloat(evt.currentTarget.getAttribute('data-period') ?? '');
      if (!Number.isFinite(period) || period === selected) {
        return;
      }
      onChange(period);
    },
    [onChange, selected]
  );

  return (
    <Container>
      {periods.map(({label, period}) => {
        const SquareClass = period === selected ? SelectedSquare : NotSelectedSquare;
        return (
          <SquareClass key={period} data-period={period} onClick={handleClick}>
            {label}
          </SquareClass>
        );
      })}
    </Container>
  );
};

PeriodSelector.displayName = 'PeriodSelector';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Square = styled.div`
  color: ${Colors.Gray};
  padding: 4px 15px;
  font-size: 10px;
`;
const NotSelectedSquare = styled(Square)`
  cursor: pointer;
  &:hover {
    background-color: ${Colors.Pink}45;
  }
`;
const SelectedSquare = styled(Square)`
  cursor: default;
  background-color: ${Colors.Pink};
  color: ${Colors.White};
`;
