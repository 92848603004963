import {Fragment, useCallback, useEffect, useState} from 'react';

import {OnescaleApi} from '@shared/api/definitions/public_api/www_api';
import {QontoDataSourceDatalakeElementItem} from '@shared/dynamo_model';
import {generateLayoutForDataSource} from '@shared/frontends/datalake_element_layout';

import {Button} from '@shared-frontend/components/core/button';
import {CenteredLine} from '@shared-frontend/components/core/fragments';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {Custom} from '@shared-frontend/lib/react';

import {
  getElements,
  refreshDatalake,
  useDatalakeId,
} from '@src/components/demo/datalake/demo_store';
import {Form, FormPropsBase, Label} from '@src/components/demo/datalake/toolbox/forms/common/form';
import {useDeleteElementHandler} from '@src/components/demo/datalake/toolbox/forms/use_delete_element_handler';
import {SecuredInput} from '@src/components/secured_input';
import {apiCall} from '@src/lib/network';
import {useOnescaleSession} from '@src/lib/stores';

interface QontoDataSourceFormProps extends FormPropsBase {
  update?: QontoDataSourceDatalakeElementItem;
}

export const QontoDataSourceForm: Custom<QontoDataSourceFormProps, 'div'> = props => {
  const {update, onDone, ...rest} = props;
  const datalakeId = useDatalakeId();
  const session = useOnescaleSession();

  const [iban, setIban] = useState(update?.params.iban);
  const [login, setLogin] = useState(update?.params.login);
  const [secretKey, setSecretKey] = useState(update?.params.secretKey);

  useEffect(() => {
    setIban(update?.params.iban);
    setLogin(update?.params.login);
    setSecretKey(update?.params.secretKey);
  }, [update]);

  const handleClick = useCallback(async () => {
    if (
      iban === undefined ||
      iban === '' ||
      login === undefined ||
      login === '' ||
      secretKey === undefined ||
      secretKey === '' ||
      session === undefined
    ) {
      return;
    }
    if (update !== undefined) {
      // eslint-disable-next-line no-console
      console.log('Updating (not supported)', update);
      await new Promise(resolve => {
        setTimeout(resolve, 1000);
      });
    } else {
      const {items} = getElements();
      await apiCall(OnescaleApi, '/create-data-lake-qonto-data-source', {
        id: datalakeId,
        iban,
        login,
        secretKey,
        layout: generateLayoutForDataSource(items),
      });
      await refreshDatalake();
    }
    onDone();
  }, [datalakeId, iban, login, onDone, secretKey, session, update]);

  const handleDelete = useDeleteElementHandler(update?.elementId);

  const handleTrigger = useCallback(async () => {
    if (!update) {
      return;
    }
    await apiCall(OnescaleApi, '/trigger-data-lake-qonto-data-source', {
      elementId: update.elementId,
    });
  }, [update]);

  return (
    <Form {...rest}>
      <Label>IBAN</Label>
      <SecuredInput theme="demo" value={iban ?? ''} syncState={setIban} autoFocus />
      <Spacing height={8} />
      <Label>Login</Label>
      <SecuredInput theme="demo" value={login ?? ''} syncState={setLogin} />
      <Spacing height={8} />
      <Label>Secret Key</Label>
      <SecuredInput theme="demo" value={secretKey ?? ''} syncState={setSecretKey} />
      <Spacing height={16} />
      <CenteredLine>
        {update === undefined ? (
          <Button onClickAsync={handleClick}>VALIDER</Button>
        ) : (
          <Fragment>
            <Button onClickAsync={handleDelete}>SUPPRIMER</Button>
            <Spacing width={8} />
            <Button onClickAsync={handleTrigger}>DÉCLENCHER</Button>
          </Fragment>
        )}
      </CenteredLine>
    </Form>
  );
};
QontoDataSourceForm.displayName = 'QontoDataSourceForm';
