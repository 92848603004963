import {Fragment, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import {OnescaleApi} from '@shared/api/definitions/public_api/www_api';
import {
  DatalakeApiToken,
  DatalakeElementItemId,
  DatalakeElementType,
  DatalakeElementTypeGroup,
  DataLakeId,
  DatalakeRestApiFormat,
  DatalakeTimeBreakdown,
  RestApiOutputDatalakeElementItem,
  RestApiOutputDatalakeElementPayload,
} from '@shared/dynamo_model';
import {generateLayoutForNonDataSource} from '@shared/frontends/datalake_element_layout';
import {arrayJoin} from '@shared/lib/array_utils';
import {uidUnsafe} from '@shared/lib/rand';
import {iterNumberEnum} from '@shared/lib/type_utils';
import {DATALAKE_REST_API_FORMAT_TO_STRING} from '@shared/model/datalake';

import {Button} from '@shared-frontend/components/core/button';
import {CenteredLine, Column} from '@shared-frontend/components/core/fragments';
import {Select} from '@shared-frontend/components/core/select';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {notifyError} from '@shared-frontend/lib/notification';
import {Custom} from '@shared-frontend/lib/react';

import {ApiKey} from '@src/components/api_key';
import {
  getElements,
  pushElementsSnaphsot,
  useDatalakeId,
} from '@src/components/demo/datalake/demo_store';
import {Form, FormPropsBase, Label} from '@src/components/demo/datalake/toolbox/forms/common/form';
import {
  TableChangeHandler,
  TableSelector,
} from '@src/components/demo/datalake/toolbox/forms/common/table_selector';
import {useDeleteElementHandler} from '@src/components/demo/datalake/toolbox/forms/use_delete_element_handler';
import {LinkButton} from '@src/components/demo/datalake/toolbox/link_button';
import {Colors} from '@src/components/theme_base';
import {apiCall} from '@src/lib/network';
import {useOnescaleSession} from '@src/lib/stores';

interface RestApiOutputFormProps extends FormPropsBase {
  update?: RestApiOutputDatalakeElementItem;
}

export const RestApiOutputForm: Custom<RestApiOutputFormProps, 'div'> = props => {
  const {update, onDone, ...rest} = props;
  const datalakeId = useDatalakeId();
  const session = useOnescaleSession();

  const [{tableId}, setSource] = useState<{
    tableId?: DatalakeElementItemId;
  }>({
    tableId: update?.params.source,
  });

  useEffect(() => {
    setSource({
      tableId: update?.params.source,
    });
  }, [update]);

  const handleChange = useCallback<TableChangeHandler>(tableId => setSource({tableId}), []);

  const [format, setFormat] = useState(update?.params.format);
  const handleCreateClick = useCallback(() => {
    if (
      update !== undefined ||
      tableId === undefined ||
      format === undefined ||
      session === undefined
    ) {
      return;
    }
    const {items} = getElements();
    const table = items[tableId];
    if (table === undefined) {
      return;
    }
    const newElementId = uidUnsafe() as DatalakeElementItemId;
    const params: RestApiOutputDatalakeElementPayload = {
      group: DatalakeElementTypeGroup.Output,
      source: tableId,
      format,
      breakdown: DatalakeTimeBreakdown.Hourly,
    };
    const restApiOutput: RestApiOutputDatalakeElementItem = {
      elementId: newElementId,
      datalakeId,
      accountId: session.accountId,
      type: DatalakeElementType.RestApiOutput,
      params,
      outputs: {columns: [...table.outputs.columns]},
      layout: generateLayoutForNonDataSource(params, items),
    };
    pushElementsSnaphsot({...items, [newElementId]: restApiOutput})
      .catch(notifyError)
      .finally(onDone);
  }, [datalakeId, format, onDone, session, tableId, update]);

  const handleDelete = useDeleteElementHandler(update?.elementId);

  // API KEYS

  const [keys, setKeys] = useState<DatalakeApiToken[] | undefined>(undefined);

  const refreshApiKeys = useCallback((id: DataLakeId, elementId: DatalakeElementItemId) => {
    apiCall(OnescaleApi, '/list-datalake-rest-api-keys', {
      id,
      elementId,
    })
      .then(res => setKeys(res.keys))
      .catch(notifyError);
  }, []);

  useEffect(() => {
    if (update === undefined) {
      return;
    }
    setKeys(undefined);
    refreshApiKeys(getElements().id, update.elementId);
  }, [refreshApiKeys, update]);

  const handleAddApiKeyClick = useCallback(() => {
    if (update === undefined) {
      return;
    }
    const {id} = getElements();
    const {elementId} = update;
    apiCall(OnescaleApi, '/add-datalake-rest-api-key', {id, elementId})
      .then(() => refreshApiKeys(id, elementId))
      .catch(notifyError);
  }, [refreshApiKeys, update]);

  const handleDeleteApiKeyClick = useCallback(
    (token: DatalakeApiToken) => {
      if (update === undefined) {
        return;
      }
      const {id} = getElements();
      const {elementId} = update;
      apiCall(OnescaleApi, '/delete-datalake-rest-api-key', {id, token})
        .then(() => refreshApiKeys(id, elementId))
        .catch(notifyError);
    },
    [refreshApiKeys, update]
  );

  /////////////

  return (
    <Form {...rest}>
      <TableSelector tableLabel="Table à exposer" tableId={tableId} onChange={handleChange} />
      <Spacing height={8} />
      <Label>Format de l'API</Label>
      <Select
        placeholder="--- Sélectionnez le format ---"
        values={iterNumberEnum(DatalakeRestApiFormat).map(v => ({
          value: v,
          label: DATALAKE_REST_API_FORMAT_TO_STRING[v],
        }))}
        value={format}
        syncState={setFormat}
        fromString={parseFloat}
      />
      <Spacing height={8} />
      {update === undefined ? (
        <CenteredLine>
          <Button onClick={handleCreateClick}>VALIDER</Button>
        </CenteredLine>
      ) : (
        <Fragment>
          <Spacing height={8} />
          {keys === undefined ? (
            <span>Chargement...</span>
          ) : (
            <Fragment>
              <LabelAndButton>
                API keys <LinkButton onClick={handleAddApiKeyClick}>Ajouter</LinkButton>
              </LabelAndButton>
              <Column>
                {arrayJoin(
                  keys.map(k => (
                    <Line key={k}>
                      <ApiKey>{k}</ApiKey>
                      <TrashIcon
                        name="Trash"
                        color={Colors.White}
                        size={18}
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={() => handleDeleteApiKeyClick(k)}
                      />
                    </Line>
                  )),
                  i => (
                    <Spacing key={i} height={8} />
                  )
                )}
              </Column>
            </Fragment>
          )}
          <Spacing height={8} />
          <CenteredLine>
            <Button onClickAsync={handleDelete}>SUPPRIMER</Button>
          </CenteredLine>
        </Fragment>
      )}
    </Form>
  );
};
RestApiOutputForm.displayName = 'RestApiOutputForm';

const LabelAndButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Line = styled.div`
  display: flex;
  pre {
    width: 100%;
    flex-grow: 1;
  }
`;

const TrashIcon = styled(SvgIcon)`
  margin: 7;
`;
