import {DatalakeElementType, SumsAggregationDatalakeElementItem} from '@shared/dynamo_model';

import {Custom} from '@shared-frontend/lib/react';

import {useDataLakeSettings} from '@src/components/demo/datalake/demo_store';
import {ElementTile} from '@src/components/demo/datalake/tiles/common/element_tile';
import {TileContent} from '@src/components/demo/datalake/tiles/common/tile_content';

export const SumsAggregationTile: Custom<
  {
    element: SumsAggregationDatalakeElementItem;
  },
  'div'
> = props => {
  const {element, ...rest} = props;
  const {elementId} = element;
  const selected = useDataLakeSettings().selectedItem === elementId;

  return (
    <ElementTile
      type={DatalakeElementType.SumsAggregation}
      selected={selected}
      body={<TileContent number={0} label={'Problèmes'} />}
      {...rest}
    />
  );
};
SumsAggregationTile.displayName = 'SumsAggregationTile';
