// Months
const JANUARY = 0;
const APRIL = 3;
const JULY = 6;
const OCTOBER = 9;

export const yearHalves = [JANUARY, JULY];
export const yearQuarters = [JANUARY, APRIL, JULY, OCTOBER];

// Week day
const MONDAY = 1;

export const firstDayOfTheWeek = MONDAY;
export const daysInWeek = 7;

// Hours
const MIDNIGHT = 0;
const THREE_HOUR = 3;
const SIX_HOUR = 6;
const NINE_HOUR = 9;
const NOON = 12;
const SIXTEEN_HOUR = 15;
const EIGHTEEN_HOUR = 18;
const TWENTY_ONE_HOUR = 21;

export const hoursInDay = 24;

export const dayHalves = [MIDNIGHT, NOON];
export const dayQuarters = [MIDNIGHT, SIX_HOUR, NOON, EIGHTEEN_HOUR];
export const dayHeights = [
  MIDNIGHT,
  THREE_HOUR,
  SIX_HOUR,
  NINE_HOUR,
  NOON,
  SIXTEEN_HOUR,
  EIGHTEEN_HOUR,
  TWENTY_ONE_HOUR,
];

// Minutes
const ZERO_MINUTE = 0;
const FIVE_MINUTE = 5;
const TEN_MINUTE = 10;
const FIFTEEN_MINUTE = 15;
const TWENTY_MINUTE = 20;
const TWENTY_FIVE_MINUTE = 25;
const THIRTY_MINUTE = 30;
const THIRTY_FIVE_MINUTE = 35;
const FORTY_MINUTE = 40;
const FORTY_FIVE_MINUTE = 45;
const FIFTY_MINUTE = 50;
const FIFTY_FIVE_MINUTE = 55;

export const hourQuarters = [ZERO_MINUTE, FIFTEEN_MINUTE, THIRTY_MINUTE, FORTY_FIVE_MINUTE];
export const hourTwelves = [
  ZERO_MINUTE,
  FIVE_MINUTE,
  TEN_MINUTE,
  FIFTEEN_MINUTE,
  TWENTY_MINUTE,
  TWENTY_FIVE_MINUTE,
  THIRTY_MINUTE,
  THIRTY_FIVE_MINUTE,
  FORTY_MINUTE,
  FORTY_FIVE_MINUTE,
  FIFTY_MINUTE,
  FIFTY_FIVE_MINUTE,
];

// Seconds
const ZERO_SECOND = 0;
const FIVE_SECOND = 5;
const TEN_SECOND = 10;
const FIFTEEN_SECOND = 15;
const TWENTY_SECOND = 20;
const TWENTY_FIVE_SECOND = 25;
const THIRTY_SECOND = 30;
const THIRTY_FIVE_SECOND = 35;
const FORTY_SECOND = 40;
const FORTY_FIVE_SECOND = 45;
const FIFTY_SECOND = 50;
const FIFTY_FIVE_SECOND = 55;

export const minuteQuarters = [ZERO_SECOND, FIFTEEN_SECOND, THIRTY_SECOND, FORTY_FIVE_SECOND];
export const minuteTwelves = [
  ZERO_SECOND,
  FIVE_SECOND,
  TEN_SECOND,
  FIFTEEN_SECOND,
  TWENTY_SECOND,
  TWENTY_FIVE_SECOND,
  THIRTY_SECOND,
  THIRTY_FIVE_SECOND,
  FORTY_SECOND,
  FORTY_FIVE_SECOND,
  FIFTY_SECOND,
  FIFTY_FIVE_SECOND,
];

// Milliseconds
const ZERO_MILLISECOND = 0;
const ONE_HUNDRED_MILLISECOND = 100;
const TWO_HUNDRED_MILLISECOND = 200;
const THREE_HUNDRED_MILLISECOND = 300;
const FOUR_HUNDRED_MILLISECOND = 400;
const FIVE_HUNDRED_MILLISECOND = 500;
const SIX_HUNDRED_MILLISECOND = 600;
const SEVEN_HUNDRED_MILLISECOND = 700;
const EIGHT_HUNDRED_MILLISECOND = 800;
const NINE_HUNDRED_MILLISECOND = 900;

export const secondTenths = [
  ZERO_MILLISECOND,
  ONE_HUNDRED_MILLISECOND,
  TWO_HUNDRED_MILLISECOND,
  THREE_HUNDRED_MILLISECOND,
  FOUR_HUNDRED_MILLISECOND,
  FIVE_HUNDRED_MILLISECOND,
  SIX_HUNDRED_MILLISECOND,
  SEVEN_HUNDRED_MILLISECOND,
  EIGHT_HUNDRED_MILLISECOND,
  NINE_HUNDRED_MILLISECOND,
];
