import {FC} from 'react';
import styled from 'styled-components';

import {SvgIcon} from '@shared-frontend/components/core/svg_icon';

import {BorderedNavLinkPink, BorderedNavLinkPurple} from '@src/components/theme';
import {HomePageColors} from '@src/components/theme_base';

interface HomeHeroSectionProps {}

export const HomeHeroSection: FC<HomeHeroSectionProps> = () => {
  return (
    <Wrapper>
      <Title>
        <div>
          <TitleSpan $color={'#ffffff'}>Transform</TitleSpan>
        </div>
        <div>
          <TitleSpan $color={HomePageColors.Blue}>Raw Data</TitleSpan>{' '}
          <TitleSpan $color={'#ffffff'}>into</TitleSpan>
        </div>
        <div>
          <TitleSpan $color={HomePageColors.Pink}>Business Insights</TitleSpan>
        </div>
      </Title>
      <Description>
        Unlock the power of your data with OneScale, a leading software company
        <br />
        specializing in data analytics and other professional services. At OneScale,
        <br />
        we believe in transforming raw data into actionable insights, empowering
        <br />
        businesses to make informed decisions and drive success.
      </Description>
      <Buttons>
        <BorderedNavLinkPurple to="#services">
          OUR SERVICES
          <SvgIcon name="ArrowRight" color="#ffffff" width={25} />
        </BorderedNavLinkPurple>
        <BorderedNavLinkPink to="#contact">
          CONTACT US
          <SvgIcon name="ArrowRight" color="#ffffff" width={25} />
        </BorderedNavLinkPink>
      </Buttons>
    </Wrapper>
  );
};

HomeHeroSection.displayName = 'HomeHeroSection';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  padding-bottom: 64px;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 96px;
  line-height: 96px;
`;
const TitleSpan = styled.span<{$color: string}>`
  color: ${p => p.$color};
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  margin-top: 50px;
`;
const Buttons = styled.div`
  display: flex;
  margin-top: 65px;
  gap: 48px;
`;
