import {styled} from 'styled-components';

import {FrontendName} from '@shared/frontends/frontend_constant';
import {FrontendTheme} from '@shared/frontends/frontend_theme_model';
import {createTheme} from '@shared/frontends/theme_context';

import {createButton} from '@shared-frontend/components/core/button';
import {OnescaleLogo} from '@shared-frontend/components/onescale_logo';

import {baseTheme, HomePageColors} from '@src/components/theme_base';

const Logo = styled(OnescaleLogo)`
  height: 37px;
`;
export const theme = createTheme(FrontendName.Onescale, baseTheme, {
  logo: <Logo color1="#fff" color2="#fff" color3="#fff" color4="#fff" />,
});

export const LargeButton = createButton({
  type: 'button',
  theme: 'button',
  themeOverrides: {fontSize: 18, fontWeight: 600},
});

// HOME

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function createHomeButton(opts: {
  type: 'button' | 'a' | 'NavLink';
  color: string;
  colorDisabled: string;
  filled: boolean;
}) {
  const {type, color, colorDisabled, filled} = opts;
  return styled(
    createButton({
      type,
      theme: 'button',
      themeOverrides: {
        textColorActive: '#ffffff',
        textColorDisabled: '#ffffff',
        textColorHover: '#ffffff',
        textColorLoading: '#ffffff',
        backgroundActive: filled ? color : 'transparent',
        backgroundDisabled: filled ? colorDisabled : 'transparent',
        backgroundHover: color,
        backgroundLoading: filled ? colorDisabled : 'transparent',
        borderColorActive: color,
        borderColorDisabled: colorDisabled,
        borderColorHover: color,
        borderColorLoading: colorDisabled,
        borderWidth: 2,
        focusBorderColor: color,
        focusBorderWidth: 2,
        loaderColor: '#ffffff',
        loaderOpacity: 0.8,
        loaderSize: 24,
        paddingTop: 28,
        paddingRight: 28,
        paddingBottom: 28,
        paddingLeft: 28,
        borderRadius: 12,
        fontSize: 22,
        fontWeight: 700,
      },
    })
  )`
    width: 300px;
    justify-content: center;
    gap: 12px;
  `;
}

export const BorderedNavLinkPurple = createHomeButton({
  type: 'NavLink',
  color: HomePageColors.Purple,
  colorDisabled: HomePageColors.PurpleDisabled,
  filled: false,
});
export const BorderedNavLinkPink = createHomeButton({
  type: 'NavLink',
  color: HomePageColors.Pink,
  colorDisabled: HomePageColors.PinkDisabled,
  filled: false,
});
export const FilledNavLinkPink = createHomeButton({
  type: 'NavLink',
  color: HomePageColors.Pink,
  colorDisabled: HomePageColors.PinkDisabled,
  filled: true,
});
export const FilledButtonPink = createHomeButton({
  type: 'button',
  color: HomePageColors.Pink,
  colorDisabled: HomePageColors.PinkDisabled,
  filled: true,
});

export const gradientInputTheme: FrontendTheme['input'] = {
  textColor: '#ffffff',
  textColorDisabled: '#ffffff66',
  backgroundColor: '#141033',
  backgroundColorHover: '#201b46',
  backgroundColorFocus: '#141033',
  backgroundColorDisabled: '#201b46',
  borderColor: 'transparent',
  borderWidth: 3,
  hoverBorderColor: 'transparent',
  focusBorderColor: `linear-gradient(to right, ${HomePageColors.Pink}, ${HomePageColors.Purple})`,
  focusBorderWidth: 3,
  focusOutlineColor: 'transparent',
  focusOutlineWidth: 0,
  focusTextColor: '#ffffff',
  borderRadius: 12,
  fontSize: 24,
  fontWeight: 400,
  fontFamily: undefined,
  titleMarginBottom: 24,
  paddingRight: 32,
  paddingLeft: 32,
  height: 88,
};

export const gradientTextareaTheme: FrontendTheme['textarea'] = {
  ...gradientInputTheme,
  paddingTop: 32,
  paddingBottom: 32,
};
