import styled from 'styled-components';

import {Custom} from '@shared-frontend/lib/react';

import {Colors} from '@src/components/theme_base';

export const LinkButton: Custom<{}, 'button'> = props => {
  const {children, ...rest} = props;

  return <Wrapper {...rest}>{children}</Wrapper>;
};
LinkButton.displayName = 'LinkButton';

const Wrapper = styled.button`
  user-select: none;
  border: none;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  padding: 4px 8px;
  margin-right: -8px;
  border-radius: 4px;
  font-weight: 500;
  background-color: transparent;
  color: ${Colors.Pink};
  &:hover {
    background-color: ${Colors.Pink}22;
  }
`;
