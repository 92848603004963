import {Custom} from '@shared-frontend/lib/react';

interface OnescaleIconProps {
  color1?: string;
  color2?: string;
  color3?: string;
  color4?: string;
}

const PURLE_LIGHT = '#e90096';
const PURLE_DARK = '#9600e9';
const BLUE_LIGHT = '#05749a';
const BLUE_DARK = '#0d073e';

export const OnescaleIcon: Custom<OnescaleIconProps, 'svg'> = props => {
  const {
    color1 = PURLE_LIGHT,
    color2 = PURLE_DARK,
    color3 = BLUE_LIGHT,
    color4 = BLUE_DARK,
    ...rest
  } = props;
  const id1 = `${color1.slice(1)}${color2.slice(1)}`;
  const id2 = `${color3.slice(1)}${color4.slice(1)}`;

  /* eslint-disable react/forbid-dom-props */
  return (
    <svg viewBox="1554 1725 433 550" {...rest}>
      <defs>
        <linearGradient x1="0" y1="0" x2="1" y2="0" id={`${id1}middle`}>
          <stop stopColor={color1} offset="0" />
          <stop stopColor={color2} offset="1" />
        </linearGradient>
        <linearGradient
          x1="600"
          y1="0"
          x2="3000"
          y2="0"
          gradientUnits="userSpaceOnUse"
          id={`${id2}a`}
        >
          <stop stopColor={color3} offset="0" />
          <stop stopColor={color4} offset="1" />
        </linearGradient>
        <linearGradient x1="0" y1="0" x2="1" y2="0" id={`${id2}top`}>
          <stop stopColor={color4} offset="0" />
          <stop stopColor={color4} offset="0.5" />
          <stop stopColor={color3} offset="1" />
        </linearGradient>
        <linearGradient x1="0" y1="0" x2="1" y2="0" id={`${id2}bottom`}>
          <stop stopColor={color3} offset="0" />
          <stop stopColor={color4} offset="0.5" />
          <stop stopColor={color4} offset="1" />
        </linearGradient>
      </defs>
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,4000)">
        <path
          d="m 1274.411,1705.17 c -59.738,0 -108.178,-48.442 -108.178,-108.179 v 0 -10.354 h 47.427 c 5.721,0 10.354,4.633 10.354,10.354 v 0 c 0,13.903 5.648,26.501 14.771,35.624 v 0 c 9.125,9.125 21.723,14.772 35.626,14.772 v 0 h 106.368 c 27.805,0 50.396,-22.664 50.396,-50.396 v 0 -10.354 h 57.782 v 10.354 c 0,59.737 -48.44,108.179 -108.178,108.179 v 0 z"
          fill={`url(#${id2}top)`}
        />
        <path
          d="m 1166.215,1528.876 c 0,-31.911 25.868,-57.78 57.779,-57.78 v 0 h 264.97 c 0,31.911 -25.867,57.78 -57.779,57.78 v 0 z"
          fill={`url(#${id1}middle)`}
        />
        <path
          d="m 1441.458,1413.291 c -5.648,0 -10.283,-4.562 -10.283,-10.282 v 0 c 0,-13.904 -5.647,-26.503 -14.77,-35.626 v 0 c -9.124,-9.124 -21.723,-14.772 -35.626,-14.772 v 0 h -106.368 c -27.734,0 -50.397,22.665 -50.397,50.398 v 0 10.282 h -57.781 v -10.282 c 0,-59.738 48.44,-108.179 108.178,-108.179 v 0 h 106.368 c 59.738,0 108.178,48.441 108.178,108.179 v 0 10.282 z"
          fill={`url(#${id2}bottom)`}
        />
      </g>
    </svg>
  );
  /* eslint-enable react/forbid-dom-props */
};

OnescaleIcon.displayName = 'OnescaleIcon';
