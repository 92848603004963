import {FC} from 'react';
import styled from 'styled-components';

import {Colors} from '@src/components/theme_base';

interface PercentageListProps {
  values: Map<string, number>;
  color: string;
}

export const PercentageList: FC<PercentageListProps> = ({values, color}) => {
  let sum = 0;
  let max = 0;
  for (const [, value] of values.entries()) {
    sum += value;
    if (value > max) {
      max = value;
    }
  }
  const preparedData: {key: string; value: number}[] = [...values.entries()]
    .sort((a, b) => b[1] - a[1])
    .map(([key, value]) => ({key, value}));

  return (
    <Container>
      {preparedData.map(({key, value}) => (
        <Line key={key}>
          <Top>
            <Key>{key}</Key>
            <Value>{Math.round((100 * value) / sum)}%</Value>
          </Top>
          <Bottom>
            <BackgroundLine />
            <ForegroundLine
              $bg={color}
              // eslint-disable-next-line react/forbid-component-props
              style={{width: `${(100 * value) / max}%`}}
            />
          </Bottom>
        </Line>
      ))}
    </Container>
  );
};

PercentageList.displayName = 'PercentageList';

const Container = styled.div`
  width: 200px;
  color: ${Colors.Gray};
  display: flex;
  flex-direction: column;
`;

const Line = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
`;

const Key = styled.div`
  flex-grow: 1;
`;

const Value = styled.div`
  color: ${Colors.White};
`;

const Bottom = styled.div`
  position: relative;
`;

const BackgroundLine = styled.div`
  background-color: ${Colors.DarkBlue2};
  width: 100%;
  height: 5px;
  border-radius: 10px;
  top: 0;
  position: absolute;
`;

const ForegroundLine = styled.div<{$bg: string}>`
  width: 0%;
  height: 5px;
  border-radius: 10px;
  top: 0;
  position: absolute;
  background-color: ${p => p.$bg};
`;
