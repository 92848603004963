import {FC, Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';

import {OnescaleApi} from '@shared/api/definitions/public_api/www_api';
import {DatalakeElementItemId} from '@shared/dynamo_model';
import {DatalakeElementItems} from '@shared/model/datalake';

import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {Status} from '@src/components/demo/dashboard/status';
import {
  refreshSelectedElementFromUrl,
  setElementsHistory,
  setScale,
  useElements,
} from '@src/components/demo/datalake/demo_store';
import {MovableZoomableArea} from '@src/components/demo/datalake/movable_zoomable_area';
import {Toolbox} from '@src/components/demo/datalake/toolbox/toolbox';
import {apiCall} from '@src/lib/network';

export const DataLake: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const elements = useElements();

  useEffect(() => {
    refreshSelectedElementFromUrl();
    window.addEventListener('popstate', refreshSelectedElementFromUrl);
    return () => window.removeEventListener('popstate', refreshSelectedElementFromUrl);
  }, []);

  useEffect(() => {
    apiCall(OnescaleApi, '/get-data-lakes', {})
      .then(datalakes => {
        const [first] = datalakes;
        if (!first) {
          return;
        }
        const {id, items} = first;

        let lowestX: number | undefined;
        let lowestY: number | undefined;
        let maxWidth: number | undefined;
        let maxHeight: number | undefined;
        let offsetX = 0;
        let offsetY = 0;

        const toolbox = 250;
        const topbar = 63;
        const margins = 50;

        const tiles = Object.values(items);
        if (tiles.length > 0) {
          for (const {
            layout: {x, y, width, height},
          } of tiles) {
            if (lowestX === undefined || x < lowestX) {
              lowestX = x;
            }
            if (lowestY === undefined || y < lowestY) {
              lowestY = y;
            }
            if (maxWidth === undefined || x + width > maxWidth) {
              maxWidth = x + width;
            }
            if (maxHeight === undefined || y + height > maxHeight) {
              maxHeight = y + height;
            }
          }
          if (
            lowestX !== undefined &&
            lowestY !== undefined &&
            maxWidth !== undefined &&
            maxHeight !== undefined
          ) {
            const widthScale = (window.innerWidth - toolbox) / (maxWidth - lowestX + 2 * margins);
            const heightScale = (window.innerHeight - topbar) / (maxHeight - lowestY + 2 * margins);
            const scale = Math.min(widthScale, heightScale, 1);
            setScale(scale);
            offsetX =
              (window.innerWidth - toolbox - (maxWidth - lowestX + 2 * margins) * scale) /
              2 /
              scale;
            offsetY =
              (window.innerHeight - topbar - (maxHeight - lowestY + 2 * margins) * scale) /
              2 /
              scale;
          }
        }

        const newItems: DatalakeElementItems = {};
        for (const [id, tile] of Object.entries(items)) {
          newItems[id as DatalakeElementItemId] = {
            ...tile,
            layout: {
              ...tile.layout,
              x: tile.layout.x - (lowestX === undefined ? 0 : lowestX - margins) + offsetX,
              y: tile.layout.y - (lowestY === undefined ? 0 : lowestY - margins) + offsetY,
            },
          };
        }

        setElementsHistory({
          id,
          snapshots: [newItems],
          currentIndex: 0,
          manualTrigger: false,
        });
      })
      .catch(notifyError)
      .finally(() => setIsLoading(false));
  }, []);

  return isLoading ? (
    EmptyFragment
  ) : (
    <Fragment>
      <Status />
      {Object.entries(elements).length === 0 ? (
        <EmptyMessage>Your datalake is empty</EmptyMessage>
      ) : (
        <MovableZoomableArea elements={elements} />
      )}

      <Toolbox />
    </Fragment>
  );
};

DataLake.displayName = 'Data Lake';

const EmptyMessage = styled.div`
  color: white;
  position: relative;
  top: 50%;
  text-align: center;
`;
