import {FC, useState} from 'react';
import styled from 'styled-components';

interface SecuredTextProps {
  value: string;
}

export const SecuredText: FC<SecuredTextProps> = ({value}) => {
  const [secured, setSecured] = useState(true);

  return (
    <Content
      // eslint-disable-next-line react/jsx-no-bind
      onClick={() => setSecured(!secured)}
    >
      {secured ? '••••••••••' : value}
    </Content>
  );
};
SecuredText.displayName = 'SecuredText';

const Content = styled.span`
  cursor: pointer;
`;
