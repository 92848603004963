import styled from 'styled-components';

export const Line = styled.div`
  display: flex;
  align-items: center;
`;

export const CenteredLine = styled(Line)`
  justify-content: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
