import {FC} from 'react';
import styled from 'styled-components';

import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {optionalPx} from '@shared-frontend/lib/styled_utils';

import {FilledNavLinkPink} from '@src/components/theme';
import {HomePageColors} from '@src/components/theme_base';
import aiMlPng from '@src/images/icon_ai_ml.png';
import cloudComputingPng from '@src/images/icon_cloud_computing.png';
import cyberSecurityPng from '@src/images/icon_cyber_security.png';
import dataAnalyticsPng from '@src/images/icon_data_analytics.png';
import dataEngineeringPng from '@src/images/icon_data_engineering.png';
import dataGovernancePng from '@src/images/icon_data_governance.png';
import {HomeOurServiceCard} from '@src/views/home_our_services_card';

interface HomeOurServicesSectionProps {}

export const HomeOurServicesSection: FC<HomeOurServicesSectionProps> = () => {
  return (
    // eslint-disable-next-line react/forbid-component-props
    <Wrapper id="services">
      <Left>
        <Title>
          <div>
            <TitleSpan $color={'#ffffff'}>Our</TitleSpan>
          </div>
          <div>
            <TitleSpan $color={HomePageColors.Blue}>Services</TitleSpan>{' '}
          </div>
        </Title>
        <Description>
          Explore a world of possibilities with
          <br />
          OneScale &ndash; your trusted partner in navigating
          <br />
          the data landscape and unlocking the true
          <br />
          potential of your business.
        </Description>
        <Buttons>
          <FilledNavLinkPink to="#contact">
            CONTACT US
            <SvgIcon name="ArrowRight" color="#ffffff" width={25} />
          </FilledNavLinkPink>
        </Buttons>
      </Left>
      <Right>
        <RightColumn $marginTop={200}>
          <HomeOurServiceCard
            title="Data Analytics"
            icon={dataAnalyticsPng}
            content="At OneScale, we excel in the art of deciphering data. Our Data Analytics services go beyond mere numbers, transforming raw information into actionable insights. Whether it's predictive modeling, trend analysis, or customized reporting, our expert team ensures that your data becomes a strategic asset, driving informed decision-making and fostering business growth."
          />
          <HomeOurServiceCard
            title="Cyber Security"
            icon={cyberSecurityPng}
            content="Security is at the core of everything we do. OneScale's Cyber Security services are designed to safeguard your valuable data assets. From threat assessments to robust encryption protocols, we deploy cutting-edge measures to protect against cyber threats. Trust us to fortify your digital defenses, allowing you to focus on leveraging data for business innovation."
          />
          <HomeOurServiceCard
            title="AI / ML"
            icon={aiMlPng}
            content="Embrace the future with OneScale's AI/ML services. We bring the power of artificial intelligence and machine learning to your fingertips, enabling you to glean insights, predict trends, and automate processes. From recommendation systems to anomaly detection, our AI/ML solutions open new horizons for your business, making it smarter and more efficient."
          />
        </RightColumn>
        <RightColumn>
          <HomeOurServiceCard
            title="Data Governance"
            icon={dataGovernancePng}
            content="OneScale's Data Governance services establish and uphold the principles of data integrity, quality, and compliance. We guide you through the creation and implementation of effective governance policies, ensuring that your data not only speaks volumes but does so with accuracy, security, and regulatory compliance."
          />
          <HomeOurServiceCard
            title="Cloud Computing"
            icon={cloudComputingPng}
            content="Elevate your operations with OneScale's Cloud Computing services. Our experts seamlessly transition your infrastructure to the cloud, providing scalability, flexibility, and cost-efficiency. Whether it's migrating existing systems or developing cloud-native applications, we ensure that your business thrives in the digital era with our cutting-edge cloud solutions."
          />
          <HomeOurServiceCard
            title="Data Engineering"
            icon={dataEngineeringPng}
            content="Building a robust data foundation is key to unlocking analytics potential. OneScale's Data Engineering services specialize in seamless data integration, migration, and warehousing. Our team architects scalable and efficient solutions, ensuring your data infrastructure is not just a storage space but a dynamic engine fueling your business intelligence."
          />
        </RightColumn>
      </Right>
    </Wrapper>
  );
};
HomeOurServicesSection.displayName = 'HomeOurServicesSection';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 48px;
  gap: 96px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 220px;
  width: max-content;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 96px;
  line-height: 96px;
`;
const TitleSpan = styled.span<{$color: string}>`
  color: ${p => p.$color};
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  margin-top: 31px;
  width: max-content;
`;
const Buttons = styled.div`
  display: flex;
  margin-top: 47px;
  gap: 48px;
`;

const Right = styled.div`
  display: flex;
  gap: 96px;
  width: max-content;
`;

const RightColumn = styled.div<{$marginTop?: number}>`
  ${p => optionalPx('margin-top', p.$marginTop)}
  display: flex;
  flex-direction: column;
  gap: 96px;
`;
