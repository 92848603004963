import {removeUndefined} from '@shared/lib/type_utils';

import {LEGEND_HEIGHT, MARGIN} from '@shared-frontend/charts/constants';
import {Axis, ChartPoint, MinMax, NormalizedChartPoint} from '@shared-frontend/charts/models';

export function normalize(value: number, minMax: MinMax, window: MinMax): number {
  return (
    window.min + ((window.max - window.min) * (value - minMax.min)) / (minMax.max - minMax.min)
  );
}

export function windowMinMaxX(width: number): MinMax {
  return {min: MARGIN.left, max: width - MARGIN.right};
}

export function windowMinMaxY(height: number): MinMax {
  return {
    min: height - MARGIN.bottom,
    max: MARGIN.top + LEGEND_HEIGHT,
  };
}

export function normalizePoint(
  point: ChartPoint,
  xMinMax: MinMax,
  yMinMax: MinMax,
  xMinMaxWindow: MinMax,
  yMinMaxWindow: MinMax
): NormalizedChartPoint {
  return {
    x: point.x,
    y: point.y,
    xNorm: normalize(point.x, xMinMax, xMinMaxWindow),
    yNorm: point.y !== undefined ? normalize(point.y, yMinMax, yMinMaxWindow) : undefined,
  };
}

export function normalizePoints(
  points: ChartPoint[],
  xMinMax: MinMax,
  yMinMax: MinMax,
  xMinMaxWindow: MinMax,
  yMinMaxWindow: MinMax
): NormalizedChartPoint[] {
  const res: NormalizedChartPoint[] = [];
  for (const point of points) {
    res.push(normalizePoint(point, xMinMax, yMinMax, xMinMaxWindow, yMinMaxWindow));
  }
  res.sort((a, b) => a.xNorm - b.xNorm);
  return res;
}

export function findMinMaxX(points: ChartPoint[]): MinMax {
  const res: MinMax = {
    min: Number.MAX_SAFE_INTEGER,
    max: Number.MIN_SAFE_INTEGER,
  };
  for (const point of points) {
    if (point.x > res.max) {
      res.max = point.x;
    }
    if (point.x < res.min) {
      res.min = point.x;
    }
  }
  if (res.min === res.max) {
    res.min -= 1;
    res.max += 1;
  }
  return res;
}

export function findMinMaxY(points: ChartPoint[]): MinMax {
  const res: MinMax = {
    min: Number.MAX_SAFE_INTEGER,
    max: Number.MIN_SAFE_INTEGER,
  };
  for (const point of points) {
    if (point.y === undefined) {
      continue;
    }
    if (point.y > res.max) {
      res.max = point.y;
    }
    if (point.y < res.min) {
      res.min = point.y;
    }
  }
  if (res.min === res.max) {
    res.min -= 1;
    res.max += 1;
  }
  return res;
}

export function mergeMinMaxes(minMaxes: MinMax[]): MinMax {
  const res: MinMax = {
    min: Number.MAX_SAFE_INTEGER,
    max: Number.MIN_SAFE_INTEGER,
  };
  for (const minMax of minMaxes) {
    if (minMax.max > res.max) {
      res.max = minMax.max;
    }
    if (minMax.min < res.min) {
      res.min = minMax.min;
    }
  }
  return res;
}

export function normalizeLines(
  axis: Axis,
  width: number,
  height: number
): [Record<string, NormalizedChartPoint[]>, MinMax, MinMax, MinMax, MinMax] {
  const xMinMaxes = removeUndefined(Object.values(axis.lines)).map(line =>
    findMinMaxX(line.points)
  );
  const xMinMax = mergeMinMaxes(xMinMaxes);
  let yMinMaxNew: MinMax = {min: -1, max: 1};
  if (axis.min === undefined || axis.max === undefined) {
    const yMinMaxes = removeUndefined(Object.values(axis.lines)).map(line =>
      findMinMaxY(line.points)
    );
    yMinMaxNew = mergeMinMaxes(yMinMaxes);
  }
  const yMinMax: MinMax = {
    min: axis.min ?? yMinMaxNew.min,
    max: axis.max ?? yMinMaxNew.max,
  };
  const xMinMaxWindow = windowMinMaxX(width);
  const yMinMaxWindow = windowMinMaxY(height);
  const normalizedLines: Record<string, NormalizedChartPoint[]> = {};

  for (const [label, line] of Object.entries(axis.lines)) {
    normalizedLines[label] = normalizePoints(
      line.points,
      xMinMax,
      yMinMax,
      xMinMaxWindow,
      yMinMaxWindow
    );
  }
  return [normalizedLines, xMinMax, yMinMax, xMinMaxWindow, yMinMaxWindow];
}

export function pointInGrid(point: ChartPoint, width: number, height: number): boolean {
  if (point.y === undefined) {
    return false;
  }
  if (
    point.x < MARGIN.left ||
    point.x > width - MARGIN.right ||
    point.y < MARGIN.top + LEGEND_HEIGHT ||
    point.y > height - MARGIN.bottom
  ) {
    return false;
  }
  return true;
}
