import {Custom} from '@shared-frontend/lib/react';

interface OnescaleLogoProps {
  color1?: string;
  color2?: string;
  color3?: string;
  color4?: string;
}

const PURLE_LIGHT = '#e90096';
const PURLE_DARK = '#9600e9';
const BLUE_LIGHT = '#05749a';
const BLUE_DARK = '#0d073e';

export const OnescaleLogo: Custom<OnescaleLogoProps, 'svg'> = props => {
  const {
    color1 = PURLE_LIGHT,
    color2 = PURLE_DARK,
    color3 = BLUE_LIGHT,
    color4 = BLUE_DARK,
    ...rest
  } = props;
  const id1 = `${color1.slice(1)}${color2.slice(1)}`;
  const id2 = `${color3.slice(1)}${color4.slice(1)}`;

  /* eslint-disable react/forbid-dom-props */
  return (
    <svg viewBox="0 1725 4000 550" {...rest}>
      <defs>
        <linearGradient
          x1="0"
          y1="0"
          x2="1000"
          y2="0"
          gradientUnits="userSpaceOnUse"
          id={`${id1}a`}
        >
          <stop stopColor={color1} offset="0" />
          <stop stopColor={color2} offset="1" />
        </linearGradient>
        <linearGradient x1="0" y1="0" x2="1" y2="0" id={`${id1}b`}>
          <stop stopColor={color1} offset="0" />
          <stop stopColor={color2} offset="1" />
        </linearGradient>
        <linearGradient
          x1="600"
          y1="0"
          x2="3000"
          y2="0"
          gradientUnits="userSpaceOnUse"
          id={`${id2}a`}
        >
          <stop stopColor={color3} offset="0" />
          <stop stopColor={color4} offset="1" />
        </linearGradient>
        <linearGradient x1="0" y1="0" x2="1" y2="0" id={`${id2}b`}>
          <stop stopColor={color4} offset="0" />
          <stop stopColor={color4} offset="0.5" />
          <stop stopColor={color3} offset="1" />
        </linearGradient>
        <linearGradient x1="0" y1="0" x2="1" y2="0" id={`${id2}c`}>
          <stop stopColor={color3} offset="0" />
          <stop stopColor={color4} offset="0.5" />
          <stop stopColor={color4} offset="1" />
        </linearGradient>
      </defs>
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,4000)">
        <path
          d="M 47.309,1664.704 C 17.672,1637.74 2.085,1598.974 0.567,1548.447 v 0 C 0.202,1537.414 0,1521.099 0,1499.439 v 0 c 0,-21.296 0.202,-37.43 0.567,-48.443 v 0 c 1.518,-51.681 16.72,-90.618 45.607,-116.833 v 0 c 28.867,-26.215 69.334,-39.333 121.399,-39.333 v 0 c 51.661,0 92.046,13.118 121.095,39.333 v 0 c 29.069,26.215 44.373,65.152 45.892,116.833 v 0 c 0.749,22.024 1.133,38.179 1.133,48.443 v 0 c 0,10.627 -0.384,26.964 -1.133,49.008 v 0 c -1.519,50.527 -17.207,89.293 -47.025,116.257 v 0 c -29.839,26.984 -69.82,40.466 -119.962,40.466 v 0 c -50.547,0 -90.628,-13.482 -120.264,-40.466 m 59.555,-280.673 c -15,15.386 -22.895,38.645 -23.645,69.799 v 0 c -0.384,11.417 -0.567,26.801 -0.567,46.175 v 0 c 0,19.757 0.183,35.142 0.567,46.154 v 0 c 0.75,31.155 8.726,54.434 23.929,69.82 v 0 c 15.203,15.384 35.344,23.096 60.425,23.096 v 0 c 24.677,0 44.636,-7.712 59.839,-23.096 v 0 c 15.183,-15.386 23.361,-38.665 24.494,-69.82 v 0 c 0.77,-22.024 1.154,-37.429 1.154,-46.154 v 0 c 0,-7.976 -0.384,-23.361 -1.154,-46.175 v 0 c -1.133,-31.539 -9.109,-54.9 -23.927,-70.102 v 0 c -14.818,-15.173 -34.96,-22.784 -60.406,-22.784 v 0 c -25.466,0 -45.708,7.692 -60.709,23.087"
          fill={`url(#${id1}a)`}
        />
        <path
          d="m 667.156,1699.482 c -4.191,0 -7.511,-1.337 -9.981,-3.988 v 0 c -2.469,-2.672 -3.704,-6.094 -3.704,-10.264 v 0 -248.485 l -162.998,253.04 c -3.806,6.458 -9.312,9.697 -16.519,9.697 v 0 H 429.5 c -4.191,0 -7.511,-1.337 -9.98,-3.988 v 0 c -2.47,-2.672 -3.704,-6.094 -3.704,-10.264 v 0 -371.008 c 0,-3.805 1.336,-7.043 3.988,-9.695 v 0 c 2.652,-2.652 5.891,-3.988 9.696,-3.988 v 0 h 49.008 c 4.171,0 7.491,1.336 9.98,3.988 v 0 c 2.47,2.652 3.705,5.89 3.705,9.695 v 0 243.355 l 162.432,-247.342 c 3.785,-6.478 9.494,-9.696 17.085,-9.696 v 0 h 45.021 c 3.805,0 7.025,1.336 9.696,3.988 v 0 c 2.652,2.652 3.989,6.073 3.989,10.263 v 0 370.44 c 0,4.17 -1.337,7.592 -3.989,10.264 v 0 c -2.671,2.651 -6.072,3.988 -10.263,3.988 v 0 z"
          fill={`url(#${id1}a)`}
        />
        <path
          d="m 837.178,1699.482 c -4.19,0 -7.51,-1.337 -9.98,-3.988 v 0 c -2.469,-2.672 -3.704,-6.094 -3.704,-10.264 v 0 -371.008 c 0,-3.805 1.335,-7.043 3.988,-9.695 v 0 c 2.651,-2.652 5.89,-3.988 9.696,-3.988 v 0 h 255.895 c 4.169,0 7.59,1.234 10.262,3.704 v 0 c 2.652,2.47 3.989,5.789 3.989,9.979 v 0 38.747 c 0,4.17 -1.337,7.51 -3.989,9.979 v 0 c -2.672,2.47 -6.093,3.706 -10.262,3.706 v 0 H 901.571 v 103.148 h 174.396 c 4.19,0 7.611,1.215 10.263,3.705 v 0 c 2.652,2.47 3.989,5.789 3.989,9.959 v 0 35.912 c 0,4.17 -1.337,7.591 -3.989,10.263 v 0 c -2.652,2.652 -6.073,3.988 -10.263,3.988 v 0 H 901.571 v 99.738 h 186.947 c 4.169,0 7.591,1.235 10.242,3.705 v 0 c 2.673,2.47 4.009,5.789 4.009,9.979 v 0 38.179 c 0,4.17 -1.336,7.592 -4.009,10.264 v 0 c -2.651,2.651 -6.073,3.988 -10.242,3.988 v 0 z"
          fill={`url(#${id1}a)`}
        />
        <g>
          <path
            d="m 1274.411,1705.17 c -59.738,0 -108.178,-48.442 -108.178,-108.179 v 0 -10.354 h 47.427 c 5.721,0 10.354,4.633 10.354,10.354 v 0 c 0,13.903 5.648,26.501 14.771,35.624 v 0 c 9.125,9.125 21.723,14.772 35.626,14.772 v 0 h 106.368 c 27.805,0 50.396,-22.664 50.396,-50.396 v 0 -10.354 h 57.782 v 10.354 c 0,59.737 -48.44,108.179 -108.178,108.179 v 0 z"
            fill={`url(#${id2}b)`}
          />
          <path
            d="m 1166.215,1528.876 c 0,-31.911 25.868,-57.78 57.779,-57.78 v 0 h 264.97 c 0,31.911 -25.867,57.78 -57.779,57.78 v 0 z"
            fill={`url(#${id1}b)`}
          />
          <path
            d="m 1441.458,1413.291 c -5.648,0 -10.283,-4.562 -10.283,-10.282 v 0 c 0,-13.904 -5.647,-26.503 -14.77,-35.626 v 0 c -9.124,-9.124 -21.723,-14.772 -35.626,-14.772 v 0 h -106.368 c -27.734,0 -50.397,22.665 -50.397,50.398 v 0 10.282 h -57.781 v -10.282 c 0,-59.738 48.44,-108.179 108.178,-108.179 v 0 h 106.368 c 59.738,0 108.178,48.441 108.178,108.179 v 0 10.282 z"
            fill={`url(#${id2}c)`}
          />
        </g>
        <path
          d="m 1600.375,1664.52 c -28.812,-27.362 -44.36,-65.09 -46.224,-113.803 v 0 c -0.415,-10.571 -0.622,-27.777 -0.622,-51.2 v 0 c 0,-23.217 0.207,-40.008 0.622,-50.164 v 0 c 1.864,-49.543 17.206,-87.478 45.81,-114.425 v 0 c 28.813,-26.739 69.235,-40.007 121.887,-40.007 v 0 c 33.788,0 63.225,5.598 88.305,17.206 v 0 c 25.083,11.607 44.569,27.568 58.25,47.676 v 0 c 13.889,20.107 21.351,42.702 21.972,67.783 v 0 c 0.415,3.526 -0.621,6.219 -3.108,8.5 v 0 c -2.488,2.28 -5.39,3.523 -8.708,3.523 v 0 h -55.346 c -4.56,0 -8.084,-1.036 -10.365,-3.108 v 0 c -2.279,-2.075 -4.146,-5.804 -5.597,-11.194 v 0 c -5.803,-23.424 -15.753,-40.215 -29.85,-49.75 v 0 c -14.301,-9.742 -32.958,-14.51 -55.553,-14.51 v 0 c -54.518,0 -82.71,30.264 -84.988,91 v 0 c -0.416,11.195 -0.624,27.156 -0.624,48.506 v 0 c 0,21.351 0.208,37.104 0.624,47.262 v 0 c 2.278,60.944 30.47,91.208 84.988,91.208 v 0 c 23.011,0 41.666,-4.768 55.761,-14.51 v 0 c 14.095,-9.743 23.839,-26.326 29.642,-49.75 v 0 c 1.451,-5.39 3.318,-9.12 5.597,-11.194 v 0 c 2.281,-2.073 5.805,-3.109 10.365,-3.109 v 0 h 55.346 c 2.903,0 5.597,1.036 8.085,3.109 v 0 c 2.487,2.074 3.731,4.56 3.731,7.671 v 0 1.244 c -0.621,24.873 -8.083,47.675 -21.972,67.782 v 0 c -13.681,20.108 -33.167,35.863 -58.25,47.471 v 0 c -25.08,11.608 -54.517,17.411 -88.305,17.411 v 0 c -52.236,0 -92.659,-13.473 -121.473,-40.628"
          fill={`url(#${id2}a)`}
        />
        <path
          d="m 2088.13,1699.552 c -9.951,0 -16.789,-4.768 -20.521,-14.303 v 0 l -136.812,-367.527 -1.036,-5.182 c 0,-3.523 1.036,-6.218 3.316,-8.498 v 0 c 2.281,-2.28 5.182,-3.523 8.499,-3.523 v 0 h 50.787 c 8.083,0 13.473,3.523 16.583,10.777 v 0 l 26.119,69.029 h 168.733 l 26.326,-69.029 c 2.902,-7.254 8.499,-10.777 16.376,-10.777 v 0 h 50.786 c 3.11,0 5.805,1.243 8.292,3.523 v 0 c 2.487,2.28 3.731,4.975 3.731,8.498 v 0 l -1.244,5.182 -136.812,367.527 c -3.731,9.535 -10.571,14.303 -20.313,14.303 v 0 z m 31.301,-77.527 64.468,-175.575 h -128.728 z"
          fill={`url(#${id2}a)`}
        />
        <path
          d="m 2387.664,1699.552 c -3.731,0 -7.047,-1.245 -9.742,-3.732 v 0 c -2.486,-2.487 -3.938,-5.804 -3.938,-9.949 v 0 -371.672 c 0,-3.731 1.452,-7.049 3.938,-9.743 v 0 c 2.695,-2.487 6.011,-3.937 9.742,-3.937 v 0 h 247.92 c 4.146,0 7.67,1.45 10.365,3.937 v 0 c 2.487,2.694 3.94,6.012 3.94,9.743 v 0 41.043 c 0,3.732 -1.453,7.049 -3.94,9.743 v 0 c -2.695,2.695 -6.219,3.938 -10.365,3.938 v 0 h -180.55 v 316.948 c 0,4.145 -1.244,7.462 -3.731,9.949 v 0 c -2.488,2.487 -5.804,3.732 -10.157,3.732 v 0 z"
          fill={`url(#${id2}a)`}
        />
        <path
          d="m 2729.901,1699.552 c -4.147,0 -7.461,-1.451 -9.951,-4.146 v 0 c -2.485,-2.487 -3.729,-6.012 -3.729,-10.157 v 0 -371.05 c 0,-3.731 1.451,-7.049 3.937,-9.743 v 0 c 2.697,-2.487 6.012,-3.937 9.743,-3.937 v 0 h 256.004 c 4.146,0 7.463,1.243 10.157,3.73 v 0 c 2.696,2.488 3.938,5.804 3.938,9.95 v 0 38.763 c 0,4.146 -1.242,7.463 -3.938,9.95 v 0 c -2.694,2.487 -6.011,3.731 -10.157,3.731 v 0 h -191.536 v 103.231 h 174.33 c 4.148,0 7.672,1.244 10.364,3.731 v 0 c 2.49,2.281 3.94,5.598 3.94,9.95 v 0 35.862 c 0,4.145 -1.45,7.463 -3.94,10.157 v 0 c -2.692,2.695 -6.216,4.147 -10.364,4.147 v 0 h -174.33 v 99.706 h 186.976 c 4.145,0 7.46,1.243 10.158,3.731 v 0 c 2.693,2.487 3.937,5.804 3.937,9.949 v 0 38.142 c 0,4.145 -1.244,7.67 -3.937,10.157 v 0 c -2.698,2.695 -6.013,4.146 -10.158,4.146 v 0 z"
          fill={`url(#${id2}a)`}
        />
      </g>
    </svg>
  );
  /* eslint-enable react/forbid-dom-props */
};

OnescaleLogo.displayName = 'OnescaleLogo';
