import {JSX, useCallback} from 'react';
import {Navigate, Route, RouteProps} from 'react-router';

import {useTheme} from '@shared/frontends/theme_context';

import {useSession} from '@shared-frontend/lib/session_store';

export function useAuthedRoutes(): {
  publicRoute: (props: RouteProps) => JSX.Element;
  privateRoute: (props: RouteProps) => JSX.Element;
} {
  const session = useSession();
  const {
    auth: {postLoginPage},
  } = useTheme();

  const publicRoute = useCallback(
    (props: RouteProps): JSX.Element => {
      return session !== undefined ? (
        <Route {...props} element={<Navigate to={postLoginPage} replace />} />
      ) : (
        <Route {...props} />
      );
    },
    [session, postLoginPage]
  );

  const privateRoute = useCallback(
    (props: RouteProps): JSX.Element => {
      return session === undefined ? (
        <Route {...props} element={<Navigate to="/login" state={{from: props.path}} replace />} />
      ) : (
        <Route {...props} />
      );
    },
    [session]
  );

  return {publicRoute, privateRoute};
}
