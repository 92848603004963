import {JSX} from 'react';
import styled from 'styled-components';

import {Column} from '@shared-frontend/components/core/fragments';
import {SvgIcon, SvgIconName} from '@shared-frontend/components/core/svg_icon';
import {Custom, EmptyFragment} from '@shared-frontend/lib/react';

import {Colors} from '@src/components/theme_base';

interface TileProps {
  title: string;
  subtitle?: string;
  icon: SvgIconName;
  color: string;
  body: JSX.Element;
  selected: boolean;
}

export const Tile: Custom<TileProps, 'div'> = props => {
  const {title, subtitle, icon, color, body, selected, ...rest} = props;
  const iconSize = 32;

  return (
    <Wrapper $color={color} $selected={selected} {...rest}>
      <Header>
        <Column>
          <Title>{title}</Title>
          {subtitle !== undefined ? <Subtitle>{subtitle}</Subtitle> : EmptyFragment}
        </Column>
        <Icon $size={iconSize * 2}>
          <SvgIcon name={icon} size={iconSize} color={Colors.White} />
        </Icon>
      </Header>
      <Content>{body}</Content>
    </Wrapper>
  );
};
Tile.displayName = 'Tile';

const Wrapper = styled.div<{$color: string; $selected: boolean}>`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  color: ${Colors.White};
  background-color: ${p => p.$color};
  border: 2px solid ${p => (p.$selected ? Colors.Blue2 : 'transparent')};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  padding-bottom: 0;
`;
const Title = styled.div``;
const Subtitle = styled.div`
  font-size: 14px;
  color: ${Colors.Gray};
`;
const Icon = styled.div<{$size: number}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-align: 'center';
  background-color: ${Colors.DarkBlue}50;
  flex-shrink: 0;
  margin-left: 15px;
  width: ${p => p.$size}px;
  height: ${p => p.$size}px;
`;

const Content = styled.div`
  padding: 24px;
`;
