import {FC, FormEventHandler, useCallback, useState} from 'react';
import styled from 'styled-components';

import {OnescaleApi} from '@shared/api/definitions/public_api/www_api';

import {apiCall} from '@shared-frontend/api';
import {Checkbox} from '@shared-frontend/components/core/checkbox';
import {Input} from '@shared-frontend/components/core/input_v2';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {Textarea} from '@shared-frontend/components/core/textarea_v2';
import {OnescaleIcon} from '@shared-frontend/components/onescale_icon';
import {notifyError} from '@shared-frontend/lib/notification';

import {FilledButtonPink, gradientInputTheme, gradientTextareaTheme} from '@src/components/theme';
import {HomePageColors} from '@src/components/theme_base';

interface HomeContactUsSectionProps {}

export const HomeContactUsSection: FC<HomeContactUsSectionProps> = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [terms, setTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback<FormEventHandler<HTMLElement>>(
    evt => {
      evt.preventDefault();
      if (!terms) {
        return;
      }
      setIsLoading(true);
      apiCall(OnescaleApi, '/contact', {
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
        companyName,
        subject,
        message,
      })
        .then(() => showSuccess('Sent'))
        .catch(err => notifyError(err, {message: 'Something when wrong'}))
        .finally(() => setIsLoading(false));
    },
    [companyName, emailAddress, firstName, lastName, message, phoneNumber, subject, terms]
  );

  return (
    // eslint-disable-next-line react/forbid-component-props
    <Wrapper id="contact">
      <OnescaleIcon width={380} />
      <Form onSubmit={handleSubmit}>
        <Title>Contact Us Today!</Title>
        <Subtitle>
          Explore the full potential of your data with OneScale. Schedule a consultation
          <br />
          with us today and unlock valuable insights for your business.
        </Subtitle>
        <Inputs>
          <InputsLine>
            <Input
              overrides={gradientInputTheme}
              value={firstName}
              syncState={setFirstName}
              placeholder="First Name"
            />
            <Input
              overrides={gradientInputTheme}
              value={lastName}
              syncState={setLastName}
              placeholder="Last Name"
            />
          </InputsLine>
          <InputsLine>
            <Input
              overrides={gradientInputTheme}
              value={emailAddress}
              syncState={setEmailAddress}
              placeholder="Email Address"
            />
            <Input
              overrides={gradientInputTheme}
              value={phoneNumber}
              syncState={setPhoneNumber}
              placeholder="Phone Number"
            />
          </InputsLine>
          <InputsLine>
            <Input
              overrides={gradientInputTheme}
              value={companyName}
              syncState={setCompanyName}
              placeholder="Company Name"
            />
            <Input
              overrides={gradientInputTheme}
              value={subject}
              syncState={setSubject}
              placeholder="Subject"
            />
          </InputsLine>
          <InputsLine>
            <Textarea
              overrides={gradientTextareaTheme}
              value={message}
              syncState={setMessage}
              placeholder="Message"
              height={200}
            />
          </InputsLine>
          <Checkbox
            checked={terms}
            syncState={setTerms}
            overrides={{
              size: 32,
              marginRight: 32,
              borderRadius: 4,
              borderWidth: 4,
              backgroundColor: 'transparent',
              backgroundColorChecked: HomePageColors.Pink,
              borderColor: HomePageColors.Pink,
              borderColorChecked: HomePageColors.Pink,
            }}
          >
            <CheckboxText>
              I agree to allow OneScale to use my personal information to send me electronic
              messages and marketing communications as described in the Privacy Policy.
            </CheckboxText>
          </Checkbox>
        </Inputs>
        <Buttons>
          <FilledButtonPink type="submit" disabled={!terms} loading={isLoading}>
            SUBMIT
            <SvgIcon name="ArrowRight" color="#ffffff" width={25} />
          </FilledButtonPink>
        </Buttons>
      </Form>
      <OnescaleIcon width={380} />
    </Wrapper>
  );
};

HomeContactUsSection.displayName = 'HomeContactUsSection';

const Wrapper = styled.div`
  display: flex;
  gap: 190px;
  margin-top: 128px;
  margin-right: -190px;
  margin-left: -190px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 1280px;
`;

const Title = styled.div`
  text-align: center;
  font-size: 95px;
  font-weight: 900;
  color: #ffffff;
`;

const Subtitle = styled.div`
  text-align: center;
  font-size: 22px;
  color: #ffffff;
  line-height: 36px;
`;

const Inputs = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 46px;
`;

const InputsLine = styled.div`
  display: flex;
  gap: 46px;
  & > * {
    width: 0;
    flex-grow: 1;
    flex-basis: 1;
  }
`;

const CheckboxText = styled.div`
  font-size: 24px;
  color: #ffffff99;
`;

const Buttons = styled.div`
  margin-top: 74px;
  display: flex;
  justify-content: center;
`;
