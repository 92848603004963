import {FC, Fragment, useCallback, useRef, useState} from 'react';
import styled from 'styled-components';

import {Canvas, MouseInfo} from '@shared-frontend/chart_v2/canvas';
import {TimeAxis} from '@shared-frontend/chart_v2/time_axis/time_axis';
import {
  drawBars,
  drawFillArea,
  drawHorizontalGrid,
  drawLegend,
  drawLines,
  drawOverlay,
  drawVerticalGrid,
} from '@shared-frontend/charts/drawing';
import {
  ChartOptions,
  ChartStyle,
  NormalizedChartPoint,
  PointWithAxis,
} from '@shared-frontend/charts/models';
import {normalizeLines, normalizePoint, pointInGrid} from '@shared-frontend/charts/utils';
import {ZINDEX} from '@shared-frontend/lib/zindex';
import {Palette} from '@shared-frontend/ui';

export interface ChartProps extends ChartStyle {
  options: ChartOptions;
}

export interface TooltipInfo {
  selectedPoints: Record<string, {left: PointWithAxis; right: PointWithAxis}>;
  firstPoint: NormalizedChartPoint | undefined;
  canvasHeight: number;
  canvasWidth: number;
}

const tooltipMarginTop = 4;

export const Chart: FC<ChartProps> = ({
  options,
  width,
  height,
  backgroundColor,
  color,
  font,
  textColor,
  drawTickLines,
  drawTickGroups,
}) => {
  const timeAxisRef = useRef<TimeAxis | undefined>();
  const {start, end, yAxis, utc} = options;

  const offset = 0;
  const zoom = 1;
  // const unconstrainedOffset = true;
  // const [lastMouseDownPosition, setLastMouseDownPosition] = useState<number>();
  // const [offset, setOffset] = useState(0);

  // function handleMouseDown(event: MouseEvent<HTMLDivElement>): void {
  //   setLastMouseDownPosition(event.clientX);
  // }
  // function handleMouseUp(event: MouseEvent<HTMLDivElement>): void {
  //   setLastMouseDownPosition(undefined);
  // }
  // function handleMouseMove(event: MouseEvent<HTMLDivElement>): void {
  //   if (lastMouseDownPosition !== undefined) {
  //     const delta = event.clientX - lastMouseDownPosition;
  //     const {width} = event.currentTarget.getBoundingClientRect();
  //     const normalizedDelta = delta / width;
  //     setLastMouseDownPosition(event.clientX);
  //     let newOffset = offset - normalizedDelta / zoom;
  //     if (!unconstrainedOffset) {
  //       newOffset = Math.min(1 - 1 / zoom, Math.max(0, newOffset));
  //     }
  //     setOffset(newOffset);
  //   }
  // }

  const [tooltipInfo, setTooltipInfo] = useState<TooltipInfo>({
    selectedPoints: {},
    firstPoint: undefined,
    canvasHeight: 0,
    canvasWidth: 0,
  });

  const renderCanvas = useCallback(
    (
      ctx: CanvasRenderingContext2D,
      width: number,
      height: number,
      mouseInfo: MouseInfo | undefined
    ) => {
      const shouldDrawRightAxis = Object.keys(yAxis.right.lines).length > 0;
      const [normalizedLinesLeft, xMinMaxLeft, yMinMaxLeft, xMinMaxWindow, yMinMaxWindow] =
        normalizeLines(yAxis.left, width, height);
      const [normalizedLinesRight, xMinMaxRight, yMinMaxRight] = normalizeLines(
        yAxis.right,
        width,
        height
      );
      // Reverse normalized: pixel => value
      let normalizedMouseInfoLeft: NormalizedChartPoint | undefined;
      let normalizedMouseInfoRight: NormalizedChartPoint | undefined;
      if (mouseInfo !== undefined && pointInGrid(mouseInfo, width, height)) {
        normalizedMouseInfoLeft = normalizePoint(
          mouseInfo,
          xMinMaxWindow,
          yMinMaxWindow,
          xMinMaxLeft,
          yMinMaxLeft
        );
        normalizedMouseInfoRight = normalizePoint(
          mouseInfo,
          xMinMaxWindow,
          yMinMaxWindow,
          xMinMaxRight,
          yMinMaxRight
        );
      }
      // Draw fill area on one line only
      const {fillArea, bars, lines} = yAxis.left;
      let selectedBar: NormalizedChartPoint | undefined;
      if (fillArea !== undefined) {
        if (bars !== undefined) {
          throw new Error('Cannot have "fillArea" and "bars" properties!');
        }
        const points = normalizedLinesLeft[fillArea.label];
        if (points !== undefined) {
          drawFillArea(
            ctx,
            points,
            fillArea,
            xMinMaxLeft,
            yMinMaxLeft,
            xMinMaxWindow,
            yMinMaxWindow
          );
        }
      } else if (bars !== undefined) {
        const points = normalizedLinesLeft[bars.label];
        if (points !== undefined) {
          selectedBar = drawBars(
            ctx,
            points,
            bars,
            xMinMaxLeft,
            yMinMaxLeft,
            xMinMaxWindow,
            yMinMaxWindow,
            normalizedMouseInfoLeft
          );
        }
      }
      // Draw grids
      drawHorizontalGrid(
        ctx,
        width,
        height,
        yMinMaxLeft,
        yMinMaxRight,
        normalizedMouseInfoLeft,
        normalizedMouseInfoRight,
        shouldDrawRightAxis,
        font,
        textColor,
        color,
        backgroundColor
      );
      drawVerticalGrid(
        ctx,
        width,
        height,
        start,
        end,
        offset,
        zoom,
        timeAxisRef,
        utc,
        font,
        textColor,
        color,
        drawTickLines,
        drawTickGroups
      );
      drawLegend(ctx, lines, font, textColor);
      if (normalizedMouseInfoLeft !== undefined) {
        drawOverlay(ctx, width, height, mouseInfo, color);
      }
      const selectedPoints: Record<string, {left: PointWithAxis; right: PointWithAxis}> = {};
      if (bars === undefined) {
        const selectedPointsLeft = drawLines(
          ctx,
          normalizedLinesLeft,
          yAxis.left,
          normalizedMouseInfoLeft
        );
        const selectedPointsRight = drawLines(
          ctx,
          normalizedLinesRight,
          yAxis.right,
          normalizedMouseInfoLeft
        );
        for (const label in selectedPointsLeft) {
          if (selectedPointsLeft[label] !== undefined) {
            selectedPoints[label] = {
              left: {point: selectedPointsLeft[label], left: yAxis.left},
              right: {point: selectedPointsRight[label], right: yAxis.right},
            };
          }
        }
      } else if (selectedBar !== undefined) {
        selectedPoints[bars.label] = {left: {point: selectedBar, left: yAxis.left}, right: {}};
      }
      const [firstSelectedPoint] = Object.keys(selectedPoints);
      setTooltipInfo({
        selectedPoints,
        firstPoint:
          firstSelectedPoint === undefined
            ? undefined
            : (
                selectedPoints[firstSelectedPoint]?.left ??
                selectedPoints[firstSelectedPoint]?.right
              )?.point,
        canvasHeight: height,
        canvasWidth: width,
      });
    },
    [
      yAxis.right,
      yAxis.left,
      font,
      textColor,
      color,
      backgroundColor,
      start,
      end,
      utc,
      drawTickLines,
      drawTickGroups,
    ]
  );

  return (
    <ChartWrapper
      // eslint-disable-next-line react/forbid-component-props
      style={{width, height}}
      // onMouseDown={handleMouseDown}
      // onMouseUp={handleMouseUp}
      // onMouseMove={handleMouseMove}
    >
      <Canvas draw={renderCanvas} backgroundColor={backgroundColor ?? Palette.Back.Darker} />
      {tooltipInfo.firstPoint === undefined ? (
        ''
      ) : (
        <Tooltip
          // eslint-disable-next-line react/forbid-component-props
          style={{
            top:
              tooltipInfo.canvasHeight - (timeAxisRef.current?.getHeight() ?? 0) + tooltipMarginTop,
            right: tooltipInfo.canvasWidth - tooltipInfo.firstPoint.xNorm,
            backgroundColor: backgroundColor ?? Palette.Back.Normal,
            color: textColor,
            borderColor: textColor,
            font,
          }}
        >
          <TooltipTitle>{new Date(tooltipInfo.firstPoint.x * 1000).toLocaleString()}</TooltipTitle>
          <table>
            <tbody>
              {Object.keys(tooltipInfo.selectedPoints).map(label => {
                const selectedPoint = tooltipInfo.selectedPoints[label];
                if (selectedPoint?.left.point?.y === undefined) {
                  return <tr key={label} />;
                }
                return (
                  <Fragment key={label}>
                    <tr>
                      <td>
                        <Square
                          // eslint-disable-next-line react/forbid-component-props
                          style={{backgroundColor: selectedPoint.left.left?.lines[label]?.color}}
                        />
                      </td>
                      <td colSpan={2}>{label}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <BoldCell>
                        {selectedPoint.left.left?.tooltip(selectedPoint.left) ?? ''}
                      </BoldCell>
                      <BoldCell>
                        {selectedPoint.right.right?.tooltip(selectedPoint.right) ?? ''}
                      </BoldCell>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </Tooltip>
      )}
    </ChartWrapper>
  );
};
Chart.displayName = 'Chart';

const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
`;

const Tooltip = styled.div`
  position: absolute;
  padding: 5px;
  border: 2px solid ${Palette.BackText.Normal};
  border-radius: 4px;
  z-index: ${ZINDEX.ChartTooltip};
  white-space: nowrap;

  table {
    td {
      padding: 5px;
    }
  }
`;

const TooltipTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  white-space: nowrap;
`;

const Square = styled.div`
  width: 15px;
  height: 15px;
`;

const BoldCell = styled.td`
  font-weight: bold;
`;
