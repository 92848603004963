import {FrontendSharedApi} from '@shared/api/definitions/frontend_shared_api';
import {ApiMetadata} from '@shared/api/model';
import {
  DashboardId,
  DatalakeApiToken,
  DatalakeElementItemId,
  DatalakeElementLayout,
  DataLakeId,
  DomainName,
} from '@shared/dynamo_model';
import {AnalyticsRealtime} from '@shared/lib/analytics/analytics_realtime';
import {None} from '@shared/lib/type_utils';
import {
  AnalyticsBreakdown,
  AnalyticsWebsiteMetadata,
  AnalyticsWebsiteQueryResult,
} from '@shared/model/analytics';
import {DashboardTileItem} from '@shared/model/dashoard';
import {DatalakeElementItems} from '@shared/model/datalake';

export const OnescaleApi: ApiMetadata<
  {
    // LANDING PAGE
    '/contact': {
      req: {
        firstName: string;
        lastName: string;
        emailAddress: string;
        phoneNumber: string;
        companyName: string;
        subject: string;
        message: string;
      };
      res: {};
    };
    // ANALYTICS WEBSITE
    '/list-datalake-web-events-data-sources': {
      req: None;
      res: AnalyticsWebsiteMetadata[];
    };
    '/query-datalake-web-events-data-source': {
      req: {
        datalakeElementId: DatalakeElementItemId;
        breakdown: AnalyticsBreakdown;
        from: number;
        to: number;
      };
      res: AnalyticsWebsiteQueryResult;
    };
    '/query-datalake-web-events-data-source-realtime': {
      req: {datalakeElementId: DatalakeElementItemId; timestampSeconds: number};
      res: AnalyticsRealtime;
    };
    // DATALAKE
    '/get-data-lakes': {
      req: None;
      res: {id: DataLakeId; items: DatalakeElementItems}[];
    };
    '/update-data-lake': {
      req: {id: DataLakeId; items: DatalakeElementItems};
      res: None;
    };
    '/create-data-lake-web-event-data-source': {
      req: {id: DataLakeId; layout: DatalakeElementLayout; domainNames: DomainName[]};
      res: None;
    };
    '/create-data-lake-qonto-data-source': {
      req: {
        id: DataLakeId;
        layout: DatalakeElementLayout;
        iban: string;
        login: string;
        secretKey: string;
      };
      res: None;
    };
    '/trigger-data-lake-qonto-data-source': {
      req: {elementId: DatalakeElementItemId};
      res: None;
    };
    '/delete-data-lake-element': {
      req: {id: DataLakeId; elementId: DatalakeElementItemId};
      res: {deleted: boolean};
    };
    '/list-datalake-rest-api-keys': {
      req: {id: DataLakeId; elementId: DatalakeElementItemId};
      res: {keys: DatalakeApiToken[]};
    };
    '/add-datalake-rest-api-key': {
      req: {id: DataLakeId; elementId: DatalakeElementItemId};
      res: None;
    };
    '/delete-datalake-rest-api-key': {
      req: {id: DataLakeId; token: DatalakeApiToken};
      res: None;
    };
    '/get-datalake-rest-api-stats': {
      req: {id: DataLakeId; elementId: DatalakeElementItemId};
      res: {requestPerMinutes: number};
    };
    // DASHBOARD
    '/get-dashboard': {
      req: None;
      res: {id: DashboardId; items: DashboardTileItem[]};
    };
  } & FrontendSharedApi
> = {isFrontend: true, ipv6Compatible: true};
