import {createGlobalStyle, css} from 'styled-components';

import {HomePageColors} from '@src/components/theme_base';

export const GlobalStyle = createGlobalStyle`${css`
  html,
  body {
    background-color: ${HomePageColors.DarkBlue};
  }
`}`;
