import {
  DatalakeElementItem,
  DatalakeElementItemId,
  DatalakeElementOutputs,
  DatalakeElementTypeGroup,
  DatalakePartitionType,
  DatalakeRestApiFormat,
} from '@shared/dynamo_model';
import {S3PrefixPeriod} from '@shared/lib/date_utils';
import {reverseMap} from '@shared/lib/map_utils';
import {neverHappens} from '@shared/lib/type_utils';

export type DatalakeElementItems = Record<DatalakeElementItemId, DatalakeElementItem>;
export type DatalakeElementColumn = DatalakeElementOutputs['columns'][0];

export function getSources(element: DatalakeElementItem): DatalakeElementItemId[] {
  if (element.params.group === DatalakeElementTypeGroup.DataSource) {
    return [];
  }
  return [element.params.source];
}

export function getPartitionType(
  element: DatalakeElementItem,
  elements: DatalakeElementItems
): DatalakePartitionType {
  if (element.params.group === DatalakeElementTypeGroup.DataSource) {
    return element.params.partitionType;
  }
  const parentId = element.params.source;
  const parent = elements[parentId];
  if (!parent) {
    const err = `Failure to get parent element with id "${parentId}" for element "${element.elementId}" of type "${element.type}"`;
    throw new Error(err);
  }
  return getPartitionType(parent, elements);
}

const DATALAKE_PARTITION_TYPE_TO_S3_PREFIX_PERIOD: Record<
  Exclude<DatalakePartitionType, DatalakePartitionType.None>,
  S3PrefixPeriod
> = {
  [DatalakePartitionType.Daily]: 'day',
  [DatalakePartitionType.Hourly]: 'hour',
};

const S3_PREFIX_PERIOD_TO_DATALAKE_PARTITION_TYPE = reverseMap(
  DATALAKE_PARTITION_TYPE_TO_S3_PREFIX_PERIOD
);

export function datalakePartitionTypeToS3PrefixPeriod(
  partitionType: Exclude<DatalakePartitionType, DatalakePartitionType.None>
): S3PrefixPeriod {
  return DATALAKE_PARTITION_TYPE_TO_S3_PREFIX_PERIOD[partitionType];
}

export function s3PrefixPeriodToDatalakePartitionType(
  s3PrefixPeriod: S3PrefixPeriod
): Exclude<DatalakePartitionType, DatalakePartitionType.None> {
  return S3_PREFIX_PERIOD_TO_DATALAKE_PARTITION_TYPE[s3PrefixPeriod];
}

export const DATALAKE_REST_API_FORMAT_TO_STRING: Record<DatalakeRestApiFormat, string> = {
  [DatalakeRestApiFormat.Json]: 'JSON',
  [DatalakeRestApiFormat.Csv]: 'CSV',
};

export function dataLakeElementTypeGroupToString(group: DatalakeElementTypeGroup): string {
  if (group === DatalakeElementTypeGroup.DataSource) {
    return 'sources';
  } else if (group === DatalakeElementTypeGroup.Transformation) {
    return 'transformations';
  } else if (group === DatalakeElementTypeGroup.Aggregation) {
    return 'agrégations';
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (group === DatalakeElementTypeGroup.Output) {
    return 'visualisations';
  }
  neverHappens(group, `Unknown DatalakeElementTypeGroup "${group}"`);
}
