import {useCallback, useEffect, useState} from 'react';

import {DatalakePartitionType, FileDropDataSourceDatalakeElementItem} from '@shared/dynamo_model';

import {Button} from '@shared-frontend/components/core/button';
import {CenteredLine} from '@shared-frontend/components/core/fragments';
import {Select} from '@shared-frontend/components/core/select';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {Custom} from '@shared-frontend/lib/react';

import {Form, FormPropsBase, Label} from '@src/components/demo/datalake/toolbox/forms/common/form';
import {useDeleteElementHandler} from '@src/components/demo/datalake/toolbox/forms/use_delete_element_handler';

interface FileDropDataSourceFormProps extends FormPropsBase {
  update?: FileDropDataSourceDatalakeElementItem;
}

export const FileDropDataSourceForm: Custom<FileDropDataSourceFormProps, 'div'> = props => {
  const {update, onDone, ...rest} = props;

  const [partitionType, setPartitionType] = useState(update?.params.partitionType);

  useEffect(() => {
    setPartitionType(update?.params.partitionType);
  }, [update]);

  const handleClick = useCallback(async () => {
    if (update !== undefined) {
      // eslint-disable-next-line no-console
      console.log('Updating', update);
    } else {
      // eslint-disable-next-line no-console
      console.log('Creating');
    }
    await new Promise(resolve => {
      setTimeout(resolve, 1000);
    });
    onDone();
  }, [onDone, update]);

  const handleDelete = useDeleteElementHandler(update?.elementId);

  return (
    <Form {...rest}>
      <Label>Découpage des fichiers</Label>
      <Select
        placeholder="--- Sélectionnez une partition ---"
        values={[{value: DatalakePartitionType.Daily, label: 'À la journée'}]}
        value={partitionType}
        syncState={setPartitionType}
      />
      <Spacing height={8} />
      <CenteredLine>
        {update === undefined ? (
          <Button onClickAsync={handleClick}>VALIDER</Button>
        ) : (
          <Button onClickAsync={handleDelete}>DELETE</Button>
        )}
      </CenteredLine>
    </Form>
  );
};
FileDropDataSourceForm.displayName = 'FileDropDataSourceForm';
