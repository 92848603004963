import {FC, Fragment, useEffect} from 'react';
import {styled} from 'styled-components';

import {AccountId, FrontendUserDataContentType} from '@shared/dynamo_model';

import {FrontendUserDataFormProps} from '@shared-frontend/components/auth/frontend_user_data_form';
import {Checkbox} from '@shared-frontend/components/core/checkbox';
import {Input} from '@shared-frontend/components/core/input_v2';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {
  useOptionalBooleanQueryString,
  useOptionalStringQueryString,
} from '@shared-frontend/lib/use_query_string';

export const FrontendUserDataOnescaleForm: FC<
  FrontendUserDataFormProps<FrontendUserDataContentType.Onescale>
> = ({onChange}) => {
  const [accountId, setAccountId] = useOptionalStringQueryString<AccountId>('accountId');
  const [isRoot, setIsRoot] = useOptionalBooleanQueryString('isRoot');

  useEffect(() => {
    if (accountId === undefined) {
      onChange(undefined);
      return;
    }
    onChange({
      accountId,
      isRoot: Boolean(isRoot),
      type: FrontendUserDataContentType.Onescale,
    });
  }, [accountId, isRoot, onChange]);

  useEffect(() => {
    return () => {
      setAccountId(undefined);
      setIsRoot(undefined);
    };
  }, [setAccountId, setIsRoot]);

  return (
    <Fragment>
      <FormInput>
        <Input
          width="100%"
          value={accountId}
          syncState={setAccountId}
          placeholder="Enter accountId..."
          label="ACCOUNT ID"
        />
      </FormInput>
      <FormInput>
        <Checkbox checked={isRoot} syncState={setIsRoot}>
          Is Root
        </Checkbox>
      </FormInput>
      <Spacing height={12} />
    </Fragment>
  );
};
FrontendUserDataOnescaleForm.displayName = 'FrontendUserDataOnescaleForm';

const FormInput = styled.div`
  width: 100%;
  text-align: left;
`;
