import {FC} from 'react';
import styled from 'styled-components';

import {Colors} from '@src/components/theme_base';

interface LegendProps {
  desktop: number;
  mobile: number;
}

export const Legend: FC<LegendProps> = ({desktop, mobile}) => {
  return (
    <Container>
      <Separator />
      <Title>Desktop</Title>
      <Value>{desktop}</Value>
      <Separator />
      <Title>Mobile</Title>
      <Value>{mobile}</Value>
      <Separator />
    </Container>
  );
};

Legend.displayName = 'Legend';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-right: 50px;
  height: 200px;
`;

const Title = styled.div`
  color: ${Colors.Gray};
  flex-grow: 1;
  font-size: 14px;
  font-weight: 500;
`;

const Value = styled.div`
  color: ${Colors.White};
  flex-grow: 1;
  font-size: 20px;
  font-weight: 500;
`;

const Separator = styled.div`
  height: 30px;
  flex-grow: 1;
`;
