import {DeepPartial} from '@shared/lib/type_utils';

import {Palette} from '@shared-frontend/ui';

export interface TextOptions {
  padding: number;
  font: string;
  fillStyle: string;
}

export interface LineOptions {
  strokeStyle: string;

  lineCap: CanvasLineCap;
  lineDashOffset: number;
  lineJoin: CanvasLineJoin;
  lineWidth: number;
  miterLimit: number;

  shadowBlur: number;
  shadowColor: string;
  shadowOffsetX: number;
  shadowOffsetY: number;
}

export interface TimePeriodOptions {
  text: TextOptions;
  line: LineOptions;
  gridLine: LineOptions;
  height: number;
}

export interface TimeAxisOptions {
  ticks: TimePeriodOptions;
  group: TimePeriodOptions;
  ticksHeight: number;
  subticksHeight: number;
  utc: boolean;
}

const defaultLineOptions: LineOptions = {
  strokeStyle: Palette.BackText.Normal,
  lineCap: 'butt', // 'square' might be better
  lineDashOffset: 0,
  lineJoin: 'miter',
  lineWidth: 1,
  miterLimit: 10,

  shadowBlur: 0,
  shadowColor: 'rgba(0, 0, 0, 0)',
  shadowOffsetX: 0,
  shadowOffsetY: 0,
};

export const defaultOptions: TimeAxisOptions = {
  ticks: {
    line: defaultLineOptions,
    gridLine: {...defaultLineOptions, strokeStyle: Palette.BackText.Normal},
    text: {font: '11px Roboto', padding: 3, fillStyle: Palette.BackText.Normal},
    height: 18,
  },
  group: {
    line: defaultLineOptions,
    gridLine: defaultLineOptions,
    text: {font: '13px Roboto', padding: 16, fillStyle: Palette.BackText.Normal},
    height: 28,
  },
  ticksHeight: 6,
  subticksHeight: 2,
  utc: false,
};

function mergeTimePeriodOptions(
  options: TimePeriodOptions,
  overrides: DeepPartial<TimePeriodOptions>
): TimePeriodOptions {
  return {
    height: overrides.height ?? options.height,
    line: overrides.line ? {...options.line, ...overrides.line} : options.line,
    gridLine: overrides.gridLine ? {...options.gridLine, ...overrides.gridLine} : options.gridLine,
    text: overrides.text ? {...options.text, ...overrides.text} : options.text,
  };
}

export function mergeOptions(
  options: TimeAxisOptions,
  overrides: DeepPartial<TimeAxisOptions>
): TimeAxisOptions {
  return {
    ticks: mergeTimePeriodOptions(options.ticks, overrides.ticks ?? {}),
    group: mergeTimePeriodOptions(options.group, overrides.group ?? {}),
    ticksHeight: overrides.ticksHeight ?? options.ticksHeight,
    subticksHeight: overrides.subticksHeight ?? options.subticksHeight,
    utc: overrides.utc ?? options.utc,
  };
}
