import {MouseEvent, MouseEventHandler, useCallback} from 'react';
import styled from 'styled-components';

import {Line} from '@shared-frontend/components/core/fragments';
import {SvgIcon, SvgIconName} from '@shared-frontend/components/core/svg_icon';
import {Custom} from '@shared-frontend/lib/react';

import {Colors} from '@src/components/theme_base';

export enum PathMode {
  Square = 'Square',
  CubicFull = 'CubicFull',
  Quadratic = 'Quadratic',
  CubicSoft = 'CubicSoft',
}

interface IconCheckboxProps {
  icon: SvgIconName;
  label: string;
  selected: boolean;
  onToggle: IconCheckboxToggleHandler;
}

export type IconCheckboxToggleHandler = (
  newSelected: boolean,
  evt: MouseEvent<HTMLDivElement>
) => void;

export const IconCheckbox: Custom<IconCheckboxProps, 'div'> = props => {
  const {icon, label, selected, onToggle, ...rest} = props;

  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    evt => onToggle(!selected, evt),
    [onToggle, selected]
  );

  return (
    <Wrapper onClick={handleClick} {...rest}>
      <Icon name={selected ? icon : 'Square'} size={12} color={Colors.Gray} />
      <Right>{label}</Right>
    </Wrapper>
  );
};
IconCheckbox.displayName = 'IconCheckbox';

const Wrapper = styled(Line)`
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #ffffff11;
  }
`;

const Icon = styled(SvgIcon)`
  margin-right: 5px;
`;
const Right = styled.div``;
