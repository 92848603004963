import {DatalakeElementItemId, DomainName} from '@shared/dynamo_model';

export interface AnalyticsWebsiteMetadata {
  datalakeElementId: DatalakeElementItemId;
  domainNames: DomainName[];
}

export interface AnalyticsWebsiteQueryData {
  from: number;
  to: number;
  uniqueUserCount: number;
  pageViews: number;
  topCountries: [string, number][];
  topLocales: [string, number][];
  topReferers: [string, number][];
}

export interface AnalyticsWebsiteQueryResult {
  data: AnalyticsWebsiteQueryData[];
  aggregation?: AnalyticsWebsiteQueryData;
}

export enum AnalyticsBreakdown {
  Hour = 10,
  Day = 20,
}
