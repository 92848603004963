import {useEffect, useState} from 'react';

import {OnescaleApi} from '@shared/api/definitions/public_api/www_api';
import {DatalakeElementItemId} from '@shared/dynamo_model';
import {AnalyticsRealtime} from '@shared/lib/analytics/analytics_realtime';

import {notifyError} from '@shared-frontend/lib/notification';
import {useSession} from '@shared-frontend/lib/session_store';

import {apiCall} from '@src/lib/network';

const REFRESH_INTERVAL = 3000;
const WAIT_BEFORE_RETRY_ON_ERROR = 3000;

export function useAnalyticsRealtime(
  datalakeElementId: DatalakeElementItemId
): AnalyticsRealtime | undefined {
  const session = useSession();
  const [analyticsRealtime, setAnalyticsRealtime] = useState<AnalyticsRealtime | undefined>();

  useEffect(() => {
    if (!session) {
      return;
    }
    let canRefresh = true;

    function refresh(): void {
      const timestampSeconds = Math.floor(Date.now() / 1000);
      apiCall(OnescaleApi, '/query-datalake-web-events-data-source-realtime', {
        datalakeElementId,
        timestampSeconds,
      })
        .then(data => {
          setAnalyticsRealtime(data);
          if (canRefresh) {
            setTimeout(refresh, REFRESH_INTERVAL);
          }
        })
        .catch(err => {
          if (session) {
            notifyError(err);
          }
          if (canRefresh) {
            setTimeout(refresh, WAIT_BEFORE_RETRY_ON_ERROR);
          }
        });
    }

    refresh();

    return () => {
      canRefresh = false;
    };
  }, [datalakeElementId, session]);

  return analyticsRealtime;
}
