import {MouseEvent, MouseEventHandler, useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

import {notifyError} from '@shared-frontend/lib/notification';
import {Custom, NULL_REF} from '@shared-frontend/lib/react';

export interface MouseInfo {
  x: number;
  y: number;
}

export const Canvas: Custom<
  {
    draw: (
      ctx: CanvasRenderingContext2D,
      width: number,
      height: number,
      mouseInfo: MouseInfo | undefined
    ) => void;
    backgroundColor?: string;
  },
  'canvas'
> = props => {
  const {draw, backgroundColor, ...restProps} = props;
  const [canvasSize, setCanvasSize] = useState({width: 1, height: 1});
  const [mouseInfo, setMouseInfo] = useState<MouseInfo | undefined>();
  const canvasRef = useRef<HTMLCanvasElement>(NULL_REF);
  const isRunning = useRef<boolean>(false);

  // Handle syncing the canvas size with its parent
  const resizeCanvas = useCallback(() => {
    // If the canvas has not mounted yet, we can't resize it
    if (!canvasRef.current) {
      return;
    }

    const parent = canvasRef.current.parentElement;
    const {width, height} = canvasRef.current;
    // Should never happen
    if (!parent) {
      notifyError(new Error(`Could not retrieve canvas parent; the canvas will not be resized.`), {
        extra: {
          width,
          height,
        },
      });
      return;
    }

    // Update the canvas size if the parent size has changed
    const {clientWidth, clientHeight} = parent;
    if (clientWidth !== width || clientHeight !== height) {
      setCanvasSize({
        width: clientWidth * window.devicePixelRatio,
        height: clientHeight * window.devicePixelRatio,
      });
    }
  }, []);

  // Trigger `resizeCanvas` once then every time the browser resizes
  useEffect(() => {
    if (IS_BROWSER) {
      resizeCanvas();
      window.addEventListener('resize', resizeCanvas);
      return () => window.removeEventListener('resize', resizeCanvas);
    }
    return () => {};
  }, [resizeCanvas]);

  // Handles the canvas rendering. Only called when
  // - the canvas is resized (`canvasSize` changes)
  // - the canvas data changed fromn the props (`props.canvasData` changes)
  // - a new draw function is provided (`props.draw` changes)
  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) {
      return;
    }
    if (!isRunning.current) {
      const pixelRatio = window.devicePixelRatio;
      const {width, height} = ctx.canvas;
      isRunning.current = true;
      ctx.save();
      ctx.clearRect(0, 0, width, height);
      ctx.scale(pixelRatio, pixelRatio);
      try {
        draw(ctx, width / pixelRatio, height / pixelRatio, mouseInfo);
      } catch (err: unknown) {
        notifyError(err, {extra: {msg: 'Failure to draw canvas', width, height, pixelRatio}});
      }
      ctx.restore();
      isRunning.current = false;
    }
  }, [canvasSize, draw, mouseInfo]);

  function getMouseInfo(event: MouseEvent<HTMLCanvasElement>): MouseInfo | undefined {
    if (!canvasRef.current) {
      return undefined;
    }
    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left / window.devicePixelRatio;
    const y = event.clientY - rect.top / window.devicePixelRatio;
    return {x, y};
  }

  const handleMouseEnter = useCallback<MouseEventHandler<HTMLCanvasElement>>(evt => {
    setMouseInfo(getMouseInfo(evt));
  }, []);
  const handleMouseLeave = useCallback<MouseEventHandler<HTMLCanvasElement>>(() => {
    setMouseInfo(undefined);
  }, []);
  const handleMouseMove = useCallback<MouseEventHandler<HTMLCanvasElement>>(evt => {
    setMouseInfo(getMouseInfo(evt));
  }, []);

  const zoom = Math.round(1000 / (IS_BROWSER ? window.devicePixelRatio : 1)) / 1000;

  return (
    <StyledCanvas
      $bg={backgroundColor}
      // eslint-disable-next-line react/forbid-component-props
      style={{zoom: `${zoom * 100}%`}}
      width={canvasSize.width}
      height={canvasSize.height}
      {...restProps}
      ref={canvasRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    />
  );
};
Canvas.displayName = 'Canvas';

const StyledCanvas = styled.canvas<{$bg?: string}>`
  ${p => (p.$bg === undefined ? undefined : `background-color: ${p.$bg};`)}
`;
