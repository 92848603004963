import {useEffect, useState} from 'react';

import {OnescaleApi} from '@shared/api/definitions/public_api/www_api';
import {DatalakeElementItemId, DataLakeId} from '@shared/dynamo_model';

import {notifyError} from '@shared-frontend/lib/notification';

import {apiCall} from '@src/lib/network';

const REFRESH_INTERVAL = 3000;
const WAIT_BEFORE_RETRY_ON_ERROR = 3000;

export function useRestApiStatsRealtime(
  datalakeId: DataLakeId,
  elementId: DatalakeElementItemId
): number | undefined {
  const [requestPerMinutes, setRequestPerMinutes] = useState<number | undefined>();

  useEffect(() => {
    let canRefresh = true;

    function refresh(): void {
      if (!canRefresh) {
        return;
      }
      apiCall(OnescaleApi, '/get-datalake-rest-api-stats', {
        id: datalakeId,
        elementId,
      })
        .then(res => {
          setRequestPerMinutes(res.requestPerMinutes);
          if (canRefresh) {
            setTimeout(refresh, REFRESH_INTERVAL);
          }
        })
        .catch(err => {
          notifyError(err);
          if (canRefresh) {
            setTimeout(refresh, WAIT_BEFORE_RETRY_ON_ERROR);
          }
        });
    }

    setRequestPerMinutes(undefined);
    refresh();

    return () => {
      canRefresh = false;
    };
  }, [datalakeId, elementId]);

  return requestPerMinutes;
}
