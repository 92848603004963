import styled from 'styled-components';

import {CustomWithStyle} from '@shared-frontend/lib/react';

export enum PathMode {
  Square = 'Square',
  CubicFull = 'CubicFull',
  Quadratic = 'Quadratic',
  CubicSoft = 'CubicSoft',
}

interface TileConnectionProps {
  width: number;
  height: number;
  pathMode: PathMode;
}

export const TileConnection: CustomWithStyle<TileConnectionProps, 'svg'> = props => {
  const {width, height, pathMode, ...rest} = props;

  const path =
    pathMode === PathMode.Square
      ? `M 0 0 H ${width / 2} V ${height} H ${width}`
      : pathMode === PathMode.CubicFull
        ? `M 0 0 C ${width} 0, 0 ${height}, ${width} ${height}`
        : pathMode === PathMode.Quadratic
          ? `M 0 0 Q ${width / 2} 0 ${width / 2} ${height / 2} T ${width} ${height}`
          : // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            `M 0 0 C ${(3 * width) / 4} 0, ${width / 4} ${height}, ${width} ${height}`;

  return (
    <Wrapper width={Math.max(1, Math.abs(width))} height={Math.max(1, Math.abs(height))} {...rest}>
      <Path d={path} />
      <Start r={circleSize / 2} cx={0} cy={0} />
      <End r={circleSize / 2} cx={width} cy={height} />
    </Wrapper>
  );
};
TileConnection.displayName = 'TileConnection';

const Wrapper = styled.svg`
  overflow: visible;
`;

const circleSize = 8;

const Start = styled.circle`
  fill: #b3b5be;
`;

const End = styled.circle`
  fill: #b3b5be;
`;

const Path = styled.path`
  fill: transparent;
  stroke: #0f2145;
  stroke-width: 3px;
  stroke-linejoin: round;
`;
