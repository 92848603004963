import {FC, ReactNode, useState} from 'react';
import styled from 'styled-components';

interface ApiKeyProps {
  children?: ReactNode;
}

export const ApiKey: FC<ApiKeyProps> = ({children}) => {
  const [secured, setSecured] = useState(true);

  return (
    <Content
      // eslint-disable-next-line react/jsx-no-bind
      onMouseEnter={() => setSecured(false)}
      // eslint-disable-next-line react/jsx-no-bind
      onMouseLeave={() => setSecured(true)}
    >
      {secured ? '••••••••••••••••••••' : children}
    </Content>
  );
};
ApiKey.displayName = 'ApiKey';

const Content = styled.pre`
  font-family: monospace;
  background: #ffffff11;
  padding: 8px;
  border-radius: 8px;
  overflow: auto;
  user-select: all;
  height: 32px;
`;
