import styled from 'styled-components';

import {Colors} from '@src/components/theme_base';

export interface FormPropsBase {
  onDone: () => void;
}

export const Option = styled.option``;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

export const SoftLabel = styled(Label)`
  color: ${Colors.Gray};
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: ${Colors.White};
`;
