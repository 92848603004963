import {useCallback, useEffect, useState} from 'react';

import {DatalakeElementItemId, GraphOutputDatalakeElementItem} from '@shared/dynamo_model';

import {Button} from '@shared-frontend/components/core/button';
import {CenteredLine} from '@shared-frontend/components/core/fragments';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {Custom} from '@shared-frontend/lib/react';

import {Form, FormPropsBase} from '@src/components/demo/datalake/toolbox/forms/common/form';
import {
  TableAndFieldSelector,
  TableFieldChangeHandler,
} from '@src/components/demo/datalake/toolbox/forms/common/table_and_field_selector';
import {useDeleteElementHandler} from '@src/components/demo/datalake/toolbox/forms/use_delete_element_handler';

interface GraphOutputFormProps extends FormPropsBase {
  update?: GraphOutputDatalakeElementItem;
}

export const GraphOutputForm: Custom<GraphOutputFormProps, 'div'> = props => {
  const {update, onDone, ...rest} = props;
  const [{tableId, xAxisColumnIndex, yAxisColumnIndex}, setSource] = useState<{
    tableId?: DatalakeElementItemId;
    xAxisColumnIndex?: number;
    yAxisColumnIndex?: number;
  }>({
    tableId: update?.params.source,
    xAxisColumnIndex: update?.params.xAxisColumnIndex,
    yAxisColumnIndex: update?.params.yAxisColumnIndex,
  });

  useEffect(() => {
    setSource({
      tableId: update?.params.source,
      xAxisColumnIndex: update?.params.xAxisColumnIndex,
      yAxisColumnIndex: update?.params.yAxisColumnIndex,
    });
  }, [update]);

  const handleChange = useCallback<TableFieldChangeHandler>(
    (tableId, [xAxisColumnIndex, yAxisColumnIndex]) =>
      setSource({tableId, xAxisColumnIndex, yAxisColumnIndex}),
    []
  );

  const handleClick = useCallback(async () => {
    if (update !== undefined) {
      // eslint-disable-next-line no-console
      console.log('Updating', update);
    } else {
      // eslint-disable-next-line no-console
      console.log('Creating');
    }
    await new Promise(resolve => {
      setTimeout(resolve, 1000);
    });
    onDone();
  }, [onDone, update]);

  const handleDelete = useDeleteElementHandler(update?.elementId);

  return (
    <Form {...rest}>
      <TableAndFieldSelector
        tableId={tableId}
        tableLabel="Table à afficher"
        fields={[
          {label: `Champ pour l'axe des abscisses`, index: xAxisColumnIndex},
          {label: `Champ pour l'axe des ordonnées`, index: yAxisColumnIndex},
        ]}
        onChange={handleChange}
      />
      <Spacing height={8} />
      <CenteredLine>
        {update === undefined ? (
          <Button onClickAsync={handleClick}>VALIDER</Button>
        ) : (
          <Button onClickAsync={handleDelete}>DELETE</Button>
        )}
      </CenteredLine>
    </Form>
  );
};
GraphOutputForm.displayName = 'GraphOutputForm';
