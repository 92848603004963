import {
  CalculationsTransformationDatalakeElementItem,
  DatalakeElementType,
} from '@shared/dynamo_model';

import {Custom} from '@shared-frontend/lib/react';

import {useDataLakeSettings} from '@src/components/demo/datalake/demo_store';
import {ElementTile} from '@src/components/demo/datalake/tiles/common/element_tile';

export const CalculationsTransformationTile: Custom<
  {
    element: CalculationsTransformationDatalakeElementItem;
  },
  'div'
> = props => {
  const {element, ...rest} = props;
  const {elementId, params} = element;
  const selected = useDataLakeSettings().selectedItem === elementId;

  return (
    <ElementTile
      type={DatalakeElementType.CalculationsTransformation}
      selected={selected}
      body={
        <span>
          {`Operator: ${params.operator}`}
          <br />
          {`Table: ${params.source}`}
          <br />
          {`First column: ${params.firstColumnIndex}`}
          <br />
          {`Second column: ${params.secondColumnIndex}`}
        </span>
      }
      {...rest}
    />
  );
};
CalculationsTransformationTile.displayName = 'CalculationsTransformationTile';
