import {JSX} from 'react';

import {DatalakeElementType} from '@shared/dynamo_model';

import {Custom} from '@shared-frontend/lib/react';

import {Tile} from '@src/components/demo/datalake/tiles/common/tile';
import {
  elementTypeToColor,
  elementTypeToIcon,
  elementTypeToLabel,
} from '@src/components/demo/datalake/toolbox/element_type';

interface ElementTileProps {
  type: DatalakeElementType;
  subtitle?: string;
  body: JSX.Element;
  selected: boolean;
}

export const ElementTile: Custom<ElementTileProps, 'div'> = props => {
  const {type, subtitle, body, ...rest} = props;

  return (
    <Tile
      title={elementTypeToLabel(type)}
      subtitle={subtitle}
      icon={elementTypeToIcon(type)}
      color={elementTypeToColor(type)}
      body={body}
      {...rest}
    />
  );
};
ElementTile.displayName = 'ElementTile';
