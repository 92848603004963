import {FC, JSX, useEffect, useState} from 'react';
import styled from 'styled-components';

import {OnescaleApi} from '@shared/api/definitions/public_api/www_api';
import {neverHappens} from '@shared/lib/type_utils';
import {
  DashboardElementItem,
  DashboardElementType,
  DashboardTileItem,
} from '@shared/model/dashoard';

import {notifyError} from '@shared-frontend/lib/notification';

import {AnalyticsWebsiteEventOriginsDashboardElement} from '@src/components/demo/dashboard/dashboard_element/analytics_website_event_origins_dashboard_element';
import {AnalyticsWebsiteRealtimeDashboardElement} from '@src/components/demo/dashboard/dashboard_element/analytics_website_realtime_dashboard_element';
import {apiCall} from '@src/lib/network';

function renderDashboardItem(item: DashboardTileItem): JSX.Element {
  const {
    layout: {x, y, width, height},
    data,
  } = item;
  const styles = {left: x, top: y, width, height};
  const key = `${x}-${y}-${width}-${height}`;
  if (data.type === DashboardElementType.AnalyticsWebsiteRealtime) {
    return <AnalyticsWebsiteRealtimeDashboardElement key={key} element={data} {...styles} />;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (data.type === DashboardElementType.AnalyticsWebsiteEventOrigins) {
    return <AnalyticsWebsiteEventOriginsDashboardElement key={key} element={data} {...styles} />;
  }
  neverHappens(data, `Unknown DashboardElementType "${(data as DashboardElementItem).type}"`);
}

export const Dashboard: FC = () => {
  const [items, setItems] = useState<DashboardTileItem[] | undefined>();
  useEffect(() => {
    apiCall(OnescaleApi, '/get-dashboard', {})
      .then(({items}) => setItems(items))
      .catch(notifyError);
  }, []);

  if (items === undefined) {
    return <div>Loading...</div>;
  }

  const dashboardWidth = items.reduce(
    (maxWidth, item) => Math.max(maxWidth, item.layout.x + item.layout.width),
    0
  );
  const dashboardHeight = items.reduce(
    (maxHeight, item) => Math.max(maxHeight, item.layout.y + item.layout.height),
    0
  );

  return (
    // eslint-disable-next-line react/forbid-component-props
    <Wrapper style={{width: dashboardWidth, height: dashboardHeight}}>
      {items.map(renderDashboardItem)}
    </Wrapper>
  );
};

Dashboard.displayName = 'Dashboard';

const Wrapper = styled.div`
  position: relative;
  margin: auto;
`;
