import {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import {OnescaleApi} from '@shared/api/definitions/public_api/www_api';
import {startOfUtcDay} from '@shared/lib/date_utils';
import {AnalyticsBreakdown, AnalyticsWebsiteQueryData} from '@shared/model/analytics';
import {AnalyticsWebsiteEventOrigins} from '@shared/model/dashoard';

import {notifyError} from '@shared-frontend/lib/notification';
import {Custom} from '@shared-frontend/lib/react';

import {PERIODS} from '@src/components/analytics/analytics_constants';
import {Metric} from '@src/components/demo/dashboard/metric';
import {PercentageList} from '@src/components/demo/dashboard/percentage_list';
import {PeriodSelector} from '@src/components/demo/dashboard/period_selector';
import {Colors} from '@src/components/theme_base';
import {apiCall} from '@src/lib/network';

interface AnalyticsWebsiteEventOriginsDashboardElementProps {
  element: AnalyticsWebsiteEventOrigins;
}

const PERIODS_TO_FETCH = [
  {label: 'HIER', period: PERIODS.Yesterday.value},
  {label: '7 JOURS', period: PERIODS.Last7Days.value},
  {label: '30 JOURS', period: PERIODS.Last30Days.value},
];

export const AnalyticsWebsiteEventOriginsDashboardElement: Custom<
  AnalyticsWebsiteEventOriginsDashboardElementProps,
  'div'
> = props => {
  const {
    element: {datalakeElementId},
    ...rest
  } = props;

  const [period, setPeriod] = useState<number | undefined>();
  const handlePeriodChange = setPeriod;

  const [dataByPeriod, setDataByPeriod] = useState<
    Record<number, AnalyticsWebsiteQueryData | undefined> | undefined
  >();

  const availablePeriods = useMemo(
    () => PERIODS_TO_FETCH.filter(p => dataByPeriod?.[p.period] !== undefined),
    [dataByPeriod]
  );

  useEffect(() => {
    Promise.all(
      PERIODS_TO_FETCH.map(async toFetch =>
        apiCall(OnescaleApi, '/query-datalake-web-events-data-source', {
          datalakeElementId,
          breakdown: AnalyticsBreakdown.Day,
          from: startOfUtcDay(new Date(Date.now() - toFetch.period)).getTime(),
          to: startOfUtcDay(new Date(Date.now())).getTime(),
        }).then(res => [toFetch.period, res.aggregation] as const)
      )
    )
      .then(res => {
        setDataByPeriod(Object.fromEntries(res));
        const [firstRes] = res;
        if (firstRes !== undefined) {
          setPeriod(firstRes[0]);
        }
      })
      .catch(notifyError);
  }, [datalakeElementId]);

  /* eslint-disable @typescript-eslint/no-magic-numbers */
  const countriesData = new Map<string, number>();
  countriesData.set('France', 15);
  countriesData.set('Allemagne', 10);
  countriesData.set('États-Unis', 50);
  countriesData.set('Chine', 40);
  countriesData.set('Japon', 10);

  const referrerData = new Map<string, number>();
  referrerData.set('Google', 40);
  referrerData.set('Accès direct', 45);
  referrerData.set('Twitter', 10);
  referrerData.set('App Store', 9);
  referrerData.set('Google Play', 8);
  /* eslint-enable @typescript-eslint/no-magic-numbers */

  if (dataByPeriod === undefined) {
    return <div>Loading</div>;
  }

  if (period === undefined) {
    return <div>No data</div>;
  }

  const data = dataByPeriod[period];
  if (data === undefined) {
    return <div>No data</div>;
  }

  return (
    <Wrapper {...rest}>
      <Title>
        <OriginTitle>ORIGINES DES PAGES VISITÉES</OriginTitle>
        <PeriodSelector
          selected={period}
          onChange={handlePeriodChange}
          periods={availablePeriods}
        />
      </Title>
      <Row>
        <Child>
          <PercentageList values={new Map(data.topCountries)} color={Colors.Blue2} />
        </Child>
        <Child>
          <PercentageList values={new Map(data.topReferers)} color={Colors.Pink} />
        </Child>
        <ColumnChild>
          <Metric
            value={data.uniqueUserCount.toLocaleString()}
            title="Utilisateurs"
            color={Colors.Pink}
            icon="Users"
          />
        </ColumnChild>
        <ColumnChild>
          <Metric
            value={data.pageViews.toLocaleString()}
            title="Pages vues"
            color={Colors.Blue2}
            icon="Eye"
          />
        </ColumnChild>
      </Row>
    </Wrapper>
  );
};

AnalyticsWebsiteEventOriginsDashboardElement.displayName =
  'AnalyticsWebsiteEventOriginsDashboardElement';

const Wrapper = styled.div`
  position: relative;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Child = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

const ColumnChild = styled(Child)`
  flex-direction: column;
`;

const Title = styled.div`
  color: ${Colors.White};
  margin-bottom: 50px;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
`;

const OriginTitle = styled.div`
  margin-right: 20px;
`;
