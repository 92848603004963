import {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  Dispatch as ReactDispatch,
  JSX,
  Ref,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import {cssPx} from '@shared-frontend/lib/styled_utils';
import {useIsMounted} from '@shared-frontend/lib/use_is_mounted';
import {Palette} from '@shared-frontend/ui';

type Dispatch<T> = ReactDispatch<SetStateAction<T>> | ((val: T) => void);

export interface InputProps<T> {
  ref?: Ref<HTMLInputElement>;
  value: T;
  syncState?: Dispatch<T>;
  theme?: 'demo';
  asString?: (value: T) => string;
  fromString?: (value: string) => T;
}
function InputInternal<T>(
  props: InputProps<T> & Omit<ComponentPropsWithoutRef<'input'>, 'style' | 'value' | 'children'>
  // ref: ForwardedRef<HTMLInputElement>
): JSX.Element {
  const {width, syncState, theme, value, asString, fromString, ...inputProps} = props;
  const stringify = useCallback(
    (val: T) => (asString === undefined ? (val === undefined ? '' : String(val)) : asString(val)),
    [asString]
  );

  const [internalStr, setInternalStr] = useState(stringify(value));
  const isMounted = useIsMounted();
  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    evt => {
      if (!syncState || !isMounted.current) {
        return;
      }
      setInternalStr(evt.currentTarget.value);
      try {
        const val =
          fromString === undefined
            ? (evt.currentTarget.value as unknown as T)
            : fromString(evt.currentTarget.value);
        syncState(val);
      } catch {
        // invalid input, don't call syncState
      }
    },
    [fromString, isMounted, syncState]
  );

  useEffect(() => setInternalStr(stringify(value)), [stringify, value]);

  return (
    <StyledInput
      $borderColor={theme === 'demo' ? Palette.BackText.Normal : Palette.BackText.Normal}
      $activeBorderColor={theme === 'demo' ? '#ffffff' : Palette.Accent.Normal}
      $width={width}
      spellCheck={false}
      onChange={handleInputChange}
      type="text"
      value={internalStr}
      {...inputProps}
    />
  );
}
InputInternal.displayName = 'Input';

export const Input = InputInternal; //forwardRef(InputInternal);

const StyledInput = styled.input<{
  $borderColor: string;
  $activeBorderColor: string;
  $width?: string | number;
}>`
  padding: 4px 8px;
  background: ${Palette.Back.Normal};
  border: solid 2px ${p => p.$borderColor};
  color: ${Palette.BackText.Normal};
  ${p => (p.$width === undefined ? undefined : `width: ${cssPx(p.$width)};`)};
  &:hover {
    background: ${Palette.Back.Brighter};
  }
  &:active,
  &:focus {
    background: ${Palette.Back.Brighter};
    border-color: ${p => p.$activeBorderColor};
    color: ${Palette.BackText.Brighter};
  }
`;
