import {useCallback, useEffect, useMemo} from 'react';
import styled from 'styled-components';

import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {isMac} from '@shared-frontend/lib/platform';
import {Custom} from '@shared-frontend/lib/react';

import {
  goBackElementsSnapshot,
  goForwardElementsSnapshot,
  useElementsHistory,
} from '@src/components/demo/datalake/demo_store';
import {Colors} from '@src/components/theme_base';

export const ToolboxFooter: Custom<{}, 'div'> = props => {
  const {snapshots, currentIndex} = useElementsHistory();

  const canGoBack = useMemo(() => currentIndex > 0, [currentIndex]);
  const canGoForward = useMemo(
    () => currentIndex < snapshots.length - 1,
    [currentIndex, snapshots.length]
  );

  const BackIconWrapper = canGoBack ? EnabledIcon : DisabledIcon;
  const ForwardIconWrapper = canGoForward ? EnabledIcon : DisabledIcon;

  const handleBackClick = useCallback(() => goBackElementsSnapshot(), []);
  const handleForwardClick = useCallback(() => goForwardElementsSnapshot(), []);

  useEffect(() => {
    const keyPressHandler = (evt: KeyboardEvent): void => {
      const hasModifier = (isMac() && evt.metaKey) || (!isMac() && evt.ctrlKey);
      if (hasModifier) {
        if (evt.key === 'y') {
          goForwardElementsSnapshot();
        } else if (evt.key === 'z') {
          if (evt.shiftKey) {
            goForwardElementsSnapshot();
          } else {
            goBackElementsSnapshot();
          }
        }
      }
    };
    window.addEventListener('keydown', keyPressHandler);
    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
  }, []);

  return (
    <Wrapper {...props}>
      <BackIconWrapper onClick={handleBackClick}>
        <SvgIcon name="ChevronLeft" size={13} color={canGoBack ? Colors.White : Colors.Gray} />
      </BackIconWrapper>
      {`${currentIndex}/${snapshots.length}`}
      <ForwardIconWrapper onClick={handleForwardClick}>
        <SvgIcon name="ChevronRight" size={13} color={canGoForward ? Colors.White : Colors.Gray} />
      </ForwardIconWrapper>
    </Wrapper>
  );
};
ToolboxFooter.displayName = 'ToolboxFooter';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 8px;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const EnabledIcon = styled(Icon)`
  cursor: pointer;
  &:hover {
    background-color: #ffffff11;
  }
`;
const DisabledIcon = styled(Icon)``;
