import {FC} from 'react';
import styled from 'styled-components';

interface HomeBottomLinksSectionProps {}

export const HomeBottomLinksSection: FC<HomeBottomLinksSectionProps> = () => {
  return (
    <Wrapper>
      <Separator />
    </Wrapper>
  );
};

HomeBottomLinksSection.displayName = 'HomeBottomLinksSection';

const Wrapper = styled.div`
  display: flex;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ffffff33;
  margin: 128px 0;
`;
