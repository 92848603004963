import {DatalakeElementItem, DatalakeElementType} from '@shared/dynamo_model';

import {SvgIconName} from '@shared-frontend/components/core/svg_icon';

import {Colors} from '@src/components/theme_base';

export function elementTypeToLabel(type: DatalakeElementType): string {
  if (type === DatalakeElementType.WebEventsDataSource) {
    return 'ÉVÈNEMENTS WEB';
  } else if (type === DatalakeElementType.FileDropDataSource) {
    return 'Dépôts de fichiers';
  } else if (type === DatalakeElementType.QontoDataSource) {
    return 'QONTO';
  } else if (type === DatalakeElementType.IpGeolocTransformation) {
    return 'Géolocalisation IP';
  } else if (type === DatalakeElementType.CalculationsTransformation) {
    return 'Calculs numériques';
  } else if (type === DatalakeElementType.ExtractStringTransformation) {
    return 'Extraction de texte';
  } else if (type === DatalakeElementType.LabelTransformation) {
    return 'Labels';
  } else if (type === DatalakeElementType.SumsAggregation) {
    return 'Somme de valeurs';
  } else if (type === DatalakeElementType.GraphOutput) {
    return 'Graphique';
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  else if (type === DatalakeElementType.RestApiOutput) {
    return 'API REST';
  }
  return '';
}

export function elementTypeToIcon(type: DatalakeElementType): SvgIconName {
  if (type === DatalakeElementType.WebEventsDataSource) {
    return 'Funnel';
  } else if (type === DatalakeElementType.FileDropDataSource) {
    return 'Drop';
  } else if (type === DatalakeElementType.QontoDataSource) {
    return 'Dollar';
  } else if (type === DatalakeElementType.IpGeolocTransformation) {
    return 'GeoLoc';
  } else if (type === DatalakeElementType.CalculationsTransformation) {
    return 'Maths';
  } else if (type === DatalakeElementType.ExtractStringTransformation) {
    return 'Read';
  } else if (type === DatalakeElementType.LabelTransformation) {
    return 'Label';
  } else if (type === DatalakeElementType.SumsAggregation) {
    return 'Sum';
  } else if (type === DatalakeElementType.GraphOutput) {
    return 'Graph';
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  else if (type === DatalakeElementType.RestApiOutput) {
    return 'RestApi';
  }
  return 'Empty';
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function elementTypeToColor(type: DatalakeElementType): string {
  return Colors.DarkBlue3;
}

export function elementItemToString(item: DatalakeElementItem): string {
  if (item.type === DatalakeElementType.WebEventsDataSource) {
    return `[${elementTypeToLabel(item.type)}] ${item.params.domainName}`;
  }
  return elementTypeToLabel(item.type);
}
