import {FC, MouseEventHandler} from 'react';
import styled from 'styled-components';

import {DatalakeElementType} from '@shared/dynamo_model';

import {SvgIcon} from '@shared-frontend/components/core/svg_icon';

import {
  elementTypeToIcon,
  elementTypeToLabel,
} from '@src/components/demo/datalake/toolbox/element_type';
import {Colors} from '@src/components/theme_base';

export interface NewElementTileProps {
  type: DatalakeElementType;
  selectedElement?: DatalakeElementType;
  onClick: MouseEventHandler;
}
export const NewElementTile: FC<NewElementTileProps> = props => {
  const {type, selectedElement, onClick: handleElementClick} = props;
  return (
    <Element
      $selected={selectedElement === type}
      data-element-type={type}
      onClick={handleElementClick}
    >
      <ElementName>{elementTypeToLabel(type)}</ElementName>
      <ElementImage>
        <SvgIcon name={elementTypeToIcon(type)} size={40} color={Colors.White} />
      </ElementImage>
    </Element>
  );
};
NewElementTile.displayName = 'NewElementTile';

const ElementImage = styled.div`
  margin-top: 10px;
`;

const Element = styled.div<{$selected?: boolean}>`
  text-align: center;
  border: 1px solid ${Colors.Gray};
  border-radius: 16px;
  padding: 10px;
  cursor: pointer;
  margin: 10px 40px;
  background-color: ${({$selected}) => ($selected ? Colors.DarkBlue3 : 'transparent')};

  &:hover {
    background-color: ${Colors.DarkBlue3};
  }
`;

const ElementName = styled.div``;
