import styled from 'styled-components';

import {Spacing} from '@shared-frontend/components/core/spacing';
import {SvgIcon, SvgIconName} from '@shared-frontend/components/core/svg_icon';
import {Custom} from '@shared-frontend/lib/react';

import {Colors} from '@src/components/theme_base';

interface ToolboxTitleProps {
  label: string;
  iconName?: SvgIconName;
  iconSize?: number;
}

export const ToolboxTitle: Custom<ToolboxTitleProps, 'div'> = props => {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const {label, iconName, iconSize = 18, ...rest} = props;

  return (
    <Wrapper {...rest}>
      {iconName === undefined ? (
        <Spacing width={8} />
      ) : (
        <Icon>
          <SvgIcon name={iconName} size={iconSize} color={Colors.White} />
        </Icon>
      )}
      <Title>{label}</Title>
    </Wrapper>
  );
};
ToolboxTitle.displayName = 'ToolboxTitle';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 28px;
  line-height: 18px;
  padding: 5px 10px 5px 0px;
  cursor: pointer;
  background-color: ${Colors.DarkBlue3};
`;

const Title = styled.div`
  flex-grow: 1;
  line-height: 18px;
`;

const Icon = styled.div`
  flex-shrink: 1;
  width: 24px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
