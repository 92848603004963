import {AnalyticsBreakdown} from '@shared/model/analytics';

const ONE_DAY = 24 * 3600 * 1000;

export interface Period {
  value: number;
  label: string;
}

export interface Breakdown {
  value: AnalyticsBreakdown;
  label: string;
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
export const PERIODS = {
  Yesterday: {label: 'Yesterday', value: ONE_DAY},
  Last7Days: {label: 'Last 7 days', value: 7 * ONE_DAY},
  Last30Days: {label: 'Last 30 days', value: 30 * ONE_DAY},
};
/* eslint-enable @typescript-eslint/no-magic-numbers */
export const BREAKDOWNS = {
  Day: {label: 'Day', value: AnalyticsBreakdown.Day},
  Hour: {label: 'Hour', value: AnalyticsBreakdown.Hour},
};

// These two variables are here only to validate the types of `PERIODS` and `BREAKDOWN`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TYPECHECK_PERIODS: Record<string, Period> = PERIODS;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TYPECHECK_BREAKDOWNS: Record<string, Breakdown> = BREAKDOWNS;
