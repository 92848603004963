import {FC, MouseEventHandler, useCallback, useEffect, useRef, WheelEventHandler} from 'react';
import styled from 'styled-components';

import {DatalakeElementItems} from '@shared/model/datalake';

import {NULL_REF} from '@shared-frontend/lib/react';

import {
  getDataLakeSettings,
  setScale,
  setSelectedElement,
} from '@src/components/demo/datalake/demo_store';
import {DemoTiles} from '@src/components/demo/datalake/demo_tiles';

const ZOOM_COEF = 300;
const MAX_ZOOM_DELTA = 50;
const MIN_ZOOM_DELTA = -50;

interface MovableZoomableAreaProps {
  elements: DatalakeElementItems;
}

export const MovableZoomableArea: FC<MovableZoomableAreaProps> = ({elements}) => {
  const clipperRef = useRef<HTMLDivElement>(NULL_REF);
  const wrapperRef = useRef<HTMLDivElement>(NULL_REF);
  const dragInfo = useRef<
    | {
        dragStartX: number;
        dragStartY: number;
      }
    | undefined
  >(undefined);
  const lastPos = useRef<{x: number; y: number}>({x: 0, y: 0});
  const currentPos = useRef<{x: number; y: number}>({x: 0, y: 0});

  const handleMouseDown = useCallback<MouseEventHandler>(evt => {
    if (evt.button !== 0) {
      return;
    }
    dragInfo.current = {dragStartX: evt.clientX, dragStartY: evt.clientY};
    if (clipperRef.current) {
      clipperRef.current.style.cursor = 'grabbing';
    }
  }, []);

  const handleWheel = useCallback<WheelEventHandler>(evt => {
    if (!wrapperRef.current) {
      return;
    }
    const prevScale = getDataLakeSettings().scale;
    const zoomDelta = Math.max(MIN_ZOOM_DELTA, Math.min(evt.deltaY, MAX_ZOOM_DELTA));
    const zoom = 1 - zoomDelta / ZOOM_COEF;
    const newScale = prevScale * zoom;
    setScale(newScale);

    const rect = wrapperRef.current.getBoundingClientRect();
    const x = evt.clientX - rect.left;
    const y = evt.clientY - rect.top;

    currentPos.current.x -= x * (zoom - 1);
    currentPos.current.y -= y * (zoom - 1);
    lastPos.current = currentPos.current;
    wrapperRef.current.style.transform = `translate(${currentPos.current.x}px, ${
      currentPos.current.y
    }px) scale(${getDataLakeSettings().scale})`;
  }, []);

  const handleClick = useCallback<MouseEventHandler>(() => setSelectedElement(undefined), []);

  useEffect(() => {
    const mouseUpHandler = (): void => {
      lastPos.current = currentPos.current;
      dragInfo.current = undefined;
      if (clipperRef.current) {
        clipperRef.current.style.cursor = 'grab';
      }
    };
    const mouseMoveHandler = (evt: MouseEvent): void => {
      if (dragInfo.current === undefined || !wrapperRef.current) {
        return;
      }
      const currentX = lastPos.current.x + evt.clientX - dragInfo.current.dragStartX;
      const currentY = lastPos.current.y + evt.clientY - dragInfo.current.dragStartY;
      currentPos.current = {x: currentX, y: currentY};
      wrapperRef.current.style.transform = `translate(${currentPos.current.x}px, ${
        currentPos.current.y
      }px) scale(${getDataLakeSettings().scale})`;
    };
    window.addEventListener('mouseup', mouseUpHandler);
    window.addEventListener('mousemove', mouseMoveHandler);
    return () => {
      window.removeEventListener('mouseup', mouseUpHandler);
      window.removeEventListener('mousemove', mouseMoveHandler);
    };
  }, []);

  return (
    <Clipper
      ref={clipperRef}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onWheel={handleWheel}
    >
      <Wrapper
        ref={wrapperRef}
        // eslint-disable-next-line react/forbid-component-props
        style={{
          transform: `translate(${currentPos.current.x}px, ${currentPos.current.y}px) scale(${
            getDataLakeSettings().scale
          })`,
        }}
      >
        <DemoTiles elements={elements} />
      </Wrapper>
    </Clipper>
  );
};
MovableZoomableArea.displayName = 'MovableZoomableArea';

const Clipper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: grab;
  user-select: none;
`;
const Wrapper = styled.div`
  position: absolute;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
  & > * {
    position: absolute;
  }
`;
