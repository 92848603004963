import {DatalakeElementType, FileDropDataSourceDatalakeElementItem} from '@shared/dynamo_model';

import {Custom} from '@shared-frontend/lib/react';

import {useDataLakeSettings} from '@src/components/demo/datalake/demo_store';
import {ElementTile} from '@src/components/demo/datalake/tiles/common/element_tile';

export const FileDropDataSourceTile: Custom<
  {
    element: FileDropDataSourceDatalakeElementItem;
  },
  'div'
> = props => {
  const {element, ...rest} = props;
  const {elementId, params} = element;
  const selected = useDataLakeSettings().selectedItem === elementId;

  return (
    <ElementTile
      type={DatalakeElementType.FileDropDataSource}
      selected={selected}
      body={<span>{`Partition Type: ${params.partitionType}`}</span>}
      {...rest}
    />
  );
};
FileDropDataSourceTile.displayName = 'FileDropDataSourceTile';
