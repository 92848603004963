import {
  DatalakeElementItem,
  DatalakeElementItemByGroup,
  DatalakeElementLayout,
  DatalakeElementTypeGroup,
} from '@shared/dynamo_model';
import {DatalakeElementItems} from '@shared/model/datalake';

const INITIAL_X = 80;
const INITIAL_Y = 80;
const TILE_WIDTH = 300;
const TILE_HEIGHT = 210;

const HORIZONTAL_DISTANCE_BETWEEN_SOURCE_AND_DESTINATION = 130;
const VERTICAL_DISTANCE_BETWEEN_FLOWS = 80;
const VERTICAL_DISTANCE_BETWEEN_CHILDREN_OF_SAME_SOURCE = 40;

function getInitialLayout(): DatalakeElementLayout {
  return {x: INITIAL_X, y: INITIAL_Y, width: TILE_WIDTH, height: TILE_HEIGHT};
}

function findVerticalLowestLayout(elements: DatalakeElementItem[]): DatalakeElementLayout {
  return elements.reduce(
    (lowest, element) =>
      lowest.y + lowest.height > element.layout.y + element.layout.height ? lowest : element.layout,
    getInitialLayout()
  );
}

function findHorizontalLowestLayout(elements: DatalakeElementItem[]): DatalakeElementLayout {
  const [firstElement] = elements;
  if (!firstElement) {
    return getInitialLayout();
  }
  return elements.reduce(
    (lowest, element) =>
      lowest.x + lowest.width < element.layout.x + element.layout.width ? lowest : element.layout,
    firstElement.layout
  );
}

export function generateLayoutForNonDataSource(
  newElementParams: Exclude<
    DatalakeElementItem,
    DatalakeElementItemByGroup[DatalakeElementTypeGroup.DataSource]
  >['params'],
  currentElements: DatalakeElementItems
): DatalakeElementLayout {
  const sourceElement = currentElements[newElementParams.source];
  if (sourceElement) {
    const {elementId, layout} = sourceElement;
    const sourceChildren = Object.values(currentElements).filter(
      element =>
        element.params.group !== DatalakeElementTypeGroup.DataSource &&
        element.params.source === elementId
    );
    if (sourceChildren.length === 0) {
      return {
        x: layout.x + layout.width + HORIZONTAL_DISTANCE_BETWEEN_SOURCE_AND_DESTINATION,
        y: layout.y,
        width: TILE_WIDTH,
        height: TILE_HEIGHT,
      };
    }
    const lowestLayout = findVerticalLowestLayout(sourceChildren);
    return {
      x: layout.x + layout.width + HORIZONTAL_DISTANCE_BETWEEN_SOURCE_AND_DESTINATION,
      y: lowestLayout.y + lowestLayout.height + VERTICAL_DISTANCE_BETWEEN_CHILDREN_OF_SAME_SOURCE,
      width: TILE_WIDTH,
      height: TILE_HEIGHT,
    };
  }
  return generateLayoutForDataSource(currentElements);
}

export function generateLayoutForDataSource(
  currentElements: DatalakeElementItems
): DatalakeElementLayout {
  const elements = Object.values(currentElements);
  if (elements.length === 0) {
    return getInitialLayout();
  }

  const verticalLowest = findVerticalLowestLayout(elements);
  const horizontalLowest = findHorizontalLowestLayout(elements);
  return {
    x: horizontalLowest.x,
    y: verticalLowest.y + verticalLowest.height + VERTICAL_DISTANCE_BETWEEN_FLOWS,
    width: TILE_WIDTH,
    height: TILE_HEIGHT,
  };
}
