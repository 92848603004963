import styled from 'styled-components';

import {Colors} from '@src/components/theme_base';

export const Main = styled.div`
  color: ${Colors.White};
  text-align: center;
  margin-top: 20%;
  font-size: 20px;
`;
