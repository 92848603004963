import {
  DatalakeElementType,
  ExtractStringTransformationDatalakeElementItem,
} from '@shared/dynamo_model';

import {Custom} from '@shared-frontend/lib/react';

import {useDataLakeSettings} from '@src/components/demo/datalake/demo_store';
import {ElementTile} from '@src/components/demo/datalake/tiles/common/element_tile';

export const ExtractStringTransformationTile: Custom<
  {
    element: ExtractStringTransformationDatalakeElementItem;
  },
  'div'
> = props => {
  const {element, ...rest} = props;
  const {elementId, params} = element;
  const selected = useDataLakeSettings().selectedItem === elementId;

  return (
    <ElementTile
      type={DatalakeElementType.ExtractStringTransformation}
      selected={selected}
      body={
        <span>
          {`Output column name: ${params.outputName}`}
          <br />
          {`Starts with: "${params.startsWith}"`}
          <br />
          {`Ends with: "${params.endsWith}"`}
        </span>
      }
      {...rest}
    />
  );
};
ExtractStringTransformationTile.displayName = 'ExtractStringTransformationTile';
