import {
  ChangeEventHandler,
  Fragment,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import {
  DatalakeElementItemId,
  DatalakeElementType,
  DatalakeElementTypeGroup,
  DatalakeOutputColumnType,
  LabelTransformationDatalakeElementItem,
  LabelTransformationDatalakeElementPayload,
} from '@shared/dynamo_model';
import {generateLayoutForNonDataSource} from '@shared/frontends/datalake_element_layout';
import {uidUnsafe} from '@shared/lib/rand';

import {Button} from '@shared-frontend/components/core/button';
import {CenteredLine} from '@shared-frontend/components/core/fragments';
import {Input} from '@shared-frontend/components/core/input';
import {joinWithSpacing, Spacing} from '@shared-frontend/components/core/spacing';
import {notifyError} from '@shared-frontend/lib/notification';
import {Custom, EmptyFragment} from '@shared-frontend/lib/react';

import {
  getElements,
  pushElementsSnaphsot,
  useDatalakeId,
} from '@src/components/demo/datalake/demo_store';
import {
  Form,
  FormPropsBase,
  Label,
  SoftLabel,
} from '@src/components/demo/datalake/toolbox/forms/common/form';
import {
  columnIs,
  TableAndFieldSelector,
  TableFieldChangeHandler,
} from '@src/components/demo/datalake/toolbox/forms/common/table_and_field_selector';
import {useDeleteElementHandler} from '@src/components/demo/datalake/toolbox/forms/use_delete_element_handler';
import {LinkButton} from '@src/components/demo/datalake/toolbox/link_button';
import {Colors} from '@src/components/theme_base';
import {useOnescaleSession} from '@src/lib/stores';

interface LabelTransformationFormProps extends FormPropsBase {
  update?: LabelTransformationDatalakeElementItem;
}

const DEFAULT_LABEL_COLUMN_NAME = 'label';

export const LabelTransformationForm: Custom<LabelTransformationFormProps, 'div'> = props => {
  const {update, onDone, ...rest} = props;
  const datalakeId = useDatalakeId();
  const session = useOnescaleSession();

  const [defaultLabel, setDefaultLabel] = useState(update?.params.defaultLabel ?? '');
  const [outputName, setOutputName] = useState(
    update?.params.outputName ?? DEFAULT_LABEL_COLUMN_NAME
  );
  const [labels, setLabels] = useState(update?.params.labels ?? [{regex: '', label: ''}]);

  const [{tableId, columnIndex}, setSource] = useState<{
    tableId?: DatalakeElementItemId;
    columnIndex?: number;
  }>({
    tableId: update?.params.source,
    columnIndex: update?.params.sourceColumn,
  });

  useEffect(() => {
    setDefaultLabel(update?.params.defaultLabel ?? '');
    setOutputName(update?.params.outputName ?? DEFAULT_LABEL_COLUMN_NAME);
    setLabels(update?.params.labels ?? [{regex: '', label: ''}]);
    setSource({
      tableId: update?.params.source,
      columnIndex: update?.params.sourceColumn,
    });
  }, [update]);

  const handleChange = useCallback<TableFieldChangeHandler>(
    (tableId, [columnIndex]) => setSource({tableId, columnIndex}),
    []
  );

  const handleLabelRegexChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    evt => {
      const {value} = evt.currentTarget;
      const index = parseFloat(evt.currentTarget.getAttribute('data-index') ?? '');
      if (isNaN(index)) {
        return;
      }
      setLabels(labels.map((label, i) => (i === index ? {...label, regex: value} : label)));
    },
    [labels]
  );
  const handleLabelLabelChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    evt => {
      const {value} = evt.currentTarget;
      const index = parseFloat(evt.currentTarget.getAttribute('data-index') ?? '');
      if (isNaN(index)) {
        return;
      }
      setLabels(labels.map((label, i) => (i === index ? {...label, label: value} : label)));
    },
    [labels]
  );

  const handleAddLabel = useCallback(
    () => setLabels([...labels, {regex: '', label: ''}]),
    [labels]
  );
  const handleDeleteLabel = useCallback<MouseEventHandler>(
    evt => {
      const index = parseFloat(evt.currentTarget.getAttribute('data-index') ?? '');
      if (isNaN(index)) {
        return;
      }
      setLabels(labels.filter((l, i) => i !== index));
    },
    [labels]
  );

  const handleClick = useCallback(() => {
    if (
      update !== undefined ||
      defaultLabel === '' ||
      outputName === '' ||
      tableId === undefined ||
      columnIndex === undefined ||
      session === undefined
    ) {
      return;
    }
    const {items} = getElements();
    const table = items[tableId];
    if (table === undefined) {
      return;
    }
    if (table.outputs.columns.find(o => o.name === outputName) !== undefined) {
      return; // Column with this name already exists
    }
    const newElementId = uidUnsafe() as DatalakeElementItemId;
    const params: LabelTransformationDatalakeElementPayload = {
      group: DatalakeElementTypeGroup.Transformation,
      outputName,
      defaultLabel,
      labels,
      source: tableId,
      sourceColumn: columnIndex,
    };
    const labelTransformation: LabelTransformationDatalakeElementItem = {
      elementId: newElementId,
      datalakeId,
      accountId: session.accountId,
      type: DatalakeElementType.LabelTransformation,
      params,
      outputs: {
        columns: [
          ...table.outputs.columns,
          {name: outputName, type: DatalakeOutputColumnType.String},
        ],
      },
      layout: generateLayoutForNonDataSource(params, items),
    };
    pushElementsSnaphsot({...items, [newElementId]: labelTransformation})
      .catch(notifyError)
      .finally(onDone);
  }, [update, defaultLabel, labels, outputName, tableId, columnIndex, session, datalakeId, onDone]);

  const handleDelete = useDeleteElementHandler(update?.elementId);

  return (
    <Form {...rest}>
      <TableAndFieldSelector
        tableId={tableId}
        tableLabel="Table à sélectionner"
        fields={[{label: 'Champ à extraire', index: columnIndex}]}
        filterColumn={columnIs(DatalakeOutputColumnType.String)}
        onChange={handleChange}
      />
      <Spacing height={8} />
      <Separator />
      <Spacing height={16} />
      {labels.length === 0 ? (
        <div>Pas de label</div>
      ) : (
        joinWithSpacing(
          labels.map((label, i) => (
            <Fragment key={i}>
              <LabelTitle data-index={i} onClick={handleDeleteLabel}>
                <Label>{`Label #${i + 1}`}</Label>
                <Spacing width={8} />
                <LinkButton>delete</LinkButton>
              </LabelTitle>
              <Spacing height={8} />
              <SoftLabel>Regex</SoftLabel>
              <Input
                theme="demo"
                // width={80}
                data-index={i}
                value={label.regex}
                onChange={handleLabelRegexChange}
              />
              <Spacing height={8} />
              <SoftLabel>Label</SoftLabel>
              <Input
                theme="demo"
                // width={80}
                data-index={i}
                value={label.label}
                onChange={handleLabelLabelChange}
              />
            </Fragment>
          )),
          {height: 12}
        )
      )}
      {update === undefined ? (
        <Fragment>
          <Spacing height={8} />
          <LinkButton onClick={handleAddLabel}>Ajouter un label</LinkButton>
        </Fragment>
      ) : (
        EmptyFragment
      )}
      <Spacing height={16} />
      <Separator />
      <Spacing height={16} />
      <Label>Label par défaut</Label>
      <Input theme="demo" value={defaultLabel} syncState={setDefaultLabel} />
      <Spacing height={8} />
      <Label>Champs de résultat</Label>
      <Input theme="demo" value={outputName} syncState={setOutputName} />
      <Spacing height={8} />
      <CenteredLine>
        {update === undefined ? (
          <Button onClick={handleClick}>VALIDER</Button>
        ) : (
          <Button onClickAsync={handleDelete}>SUPPRIMER</Button>
        )}
      </CenteredLine>
    </Form>
  );
};
LabelTransformationForm.displayName = 'LabelTransformationForm';

const LabelTitle = styled.div`
  display: flex;
  align-items: baseline;
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${Colors.Gray};
`;
