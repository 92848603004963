import {DatalakeElementType, RestApiOutputDatalakeElementItem} from '@shared/dynamo_model';
import {DATALAKE_REST_API_FORMAT_TO_STRING} from '@shared/model/datalake';

import {Custom} from '@shared-frontend/lib/react';

import {getElements, useDataLakeSettings} from '@src/components/demo/datalake/demo_store';
import {ElementTile} from '@src/components/demo/datalake/tiles/common/element_tile';
import {TileContent} from '@src/components/demo/datalake/tiles/common/tile_content';
import {useRestApiStatsRealtime} from '@src/components/demo/use_rest_api_stats_realtime';

export const RestApiOutputTile: Custom<
  {
    element: RestApiOutputDatalakeElementItem;
  },
  'div'
> = props => {
  const {element, ...rest} = props;
  const {elementId, params} = element;
  const selected = useDataLakeSettings().selectedItem === elementId;
  const reqPerMinutes = useRestApiStatsRealtime(getElements().id, elementId);

  return (
    <ElementTile
      type={DatalakeElementType.RestApiOutput}
      selected={selected}
      subtitle={DATALAKE_REST_API_FORMAT_TO_STRING[params.format]}
      body={<TileContent number={reqPerMinutes} label={'Requêtes par minute'} />}
      {...rest}
    />
  );
};
RestApiOutputTile.displayName = 'RestApiOutputUiTile';
