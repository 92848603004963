import {FC} from 'react';
import styled from 'styled-components';

import {Colors, FontWeight} from '@src/components/theme_base';

const currentYear = new Date().getFullYear();

export const Footer: FC<{left?: boolean}> = ({left}) => (
  <Wrapper $left={left}>{`Copyright © ${currentYear} OneScale SAS`}</Wrapper>
);
Footer.displayName = 'Footer';

const Wrapper = styled.div<{$left?: boolean}>`
  position: absolute;
  bottom: 0;
  left: ${({$left}) => ($left ? 0 : 'auto')};
  text-align: center;
  padding: 10px;
  color: ${({$left}) => ($left ? Colors.Gray : Colors.White)};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({$left}) => ($left ? 'auto' : '100%')};
  font-size: 14px;
  font-weight: ${FontWeight.SemiLight};
  margin-top: -40px;
`;
