import {DatalakeElementType, LabelTransformationDatalakeElementItem} from '@shared/dynamo_model';

import {Custom} from '@shared-frontend/lib/react';

import {useDataLakeSettings} from '@src/components/demo/datalake/demo_store';
import {ElementTile} from '@src/components/demo/datalake/tiles/common/element_tile';

export const LabelTransformationTile: Custom<
  {
    element: LabelTransformationDatalakeElementItem;
  },
  'div'
> = props => {
  const {element, ...rest} = props;
  const {elementId, params} = element;
  const selected = useDataLakeSettings().selectedItem === elementId;

  return (
    <ElementTile
      type={DatalakeElementType.LabelTransformation}
      selected={selected}
      body={
        <span>
          {`Output column name: ${params.outputName}`}
          <br />
          {`Default label: ${params.defaultLabel}`}
          <br />
          {`${params.labels.length} labels defined`}
        </span>
      }
      {...rest}
    />
  );
};
LabelTransformationTile.displayName = 'LabelTransformationTile';
